.BusinessCardPreview {
    --primary-color: '';
    --secondary-color: '';
    --background-color: '';

    box-sizing: border-box;
    position: relative;
    border-radius: 6px;
    background-color: var(--primary-color);
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
}

.BusinessCardPreview__content {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    font-size: 24px;
    font-weight: 500;
    overflow: hidden;
}

@media (max-width: 1100px) {
    .BusinessCardPreview__content {
        font-size: 20px;
    }
}

@media (max-width: 840px) {
    .BusinessCardPreview__content {
        font-size: 18px;
    }
}

@media (max-width: 640px) {
    .BusinessCardPreview__content {
        font-size: 16px;
    }
}

@media (max-width: 640px) {
    .BusinessCardPreview__content {
        font-size: 14px;
    }
}

.BusinessCardPreview__col {
    flex: 0 0 auto;
    flex-basis: calc(50% - 16px);
    display: flex;
    align-items: center;
    padding: 40px;
    box-sizing: border-box;
    overflow: hidden;
    background-color: var(--theme-white-color);
}

@media (max-width: 1080px) {
    .BusinessCardPreview__col {
        flex-basis: calc(50% - 8px);
        padding: 24px;
    }
}

@media (max-width: 640px) {
    .BusinessCardPreview__col {
        padding: 16px;
    }
}

.BusinessCardPreview__col_highlighted {
    background-color: var(--background-color);
}

.BusinessCardPreview__group {
    flex: 1 1 auto;
    max-width: 100%;
}

.BusinessCardPreview__name {
    margin-bottom: 4px;
    line-height: 1;
    font-size: 42px;
    font-weight: 700;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 1080px) {
    .BusinessCardPreview__name {
        font-size: 36px;
    }
}

@media (max-width: 960px) {
    .BusinessCardPreview__name {
        font-size: 32px;
    }
}

@media (max-width: 840px) {
    .BusinessCardPreview__name {
        font-size: 28px;
    }
}

@media (max-width: 640px) {
    .BusinessCardPreview__name {
        font-size: 22px;
    }
}

@media (max-width: 560px) {
    .BusinessCardPreview__name {
        font-size: 18px;
    }
}

@media (max-width: 400px) {
    .BusinessCardPreview__name {
        font-size: 16px;
    }
}

@media (max-width: 380px) {
    .BusinessCardPreview__name {
        font-size: 14px;
    }
}

.BusinessCardPreview__name:last-child {
    margin-bottom: 0;
}

.BusinessCardPreview__name_highlighted {
    color: var(--primary-color);
}

.BusinessCardPreview__separator {
    width: 320px;
    height: 4px;
    margin: 24px 0;
    background-color: #000;
}

@media (max-width: 1100px) {
    .BusinessCardPreview__separator {
        width: 240px;
    }
}

@media (max-width: 640px) {
    .BusinessCardPreview__separator {
        width: 160px;
        height: 2px;
        margin: 16px 0;
    }
}

@media (max-width: 480px) {
    .BusinessCardPreview__separator {
        width: 120px;
        margin: 8px 0;
    }
}

.BusinessCardPreview__designation {
    margin-bottom: 12px;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
}

.BusinessCardPreview__companyName {
    overflow: hidden;
    text-overflow: ellipsis;
}

.BusinessCardPreview__logoPositioner {
    margin: 0 auto;
}

@media (max-width: 480px) {
    .BusinessCardPreview__logoPositioner {
        max-width: 160px;
    }
}

@media (max-width: 436px) {
    .BusinessCardPreview__logoPositioner {
        max-width: 120px;
    }
}

@media (max-width: 380px) {
    .BusinessCardPreview__logoPositioner {
        max-width: 100px;
    }
}

.BusinessCardPreview__logoHolder {
    position: relative;
    display: flex;
    justify-content: center;
    max-width: 320px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 32px;
}

@media (max-width: 840px) {
    .BusinessCardPreview__logoHolder {
        margin-bottom: 24px;
    }
}

@media (max-width: 640px) {
    .BusinessCardPreview__logoHolder {
        margin-bottom: 16px;
    }
}

@media (max-width: 480px) {
    .BusinessCardPreview__logoHolder {
        margin-bottom: 12px;
    }
}

.BusinessCardPreview__logo {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.BusinessCardPreview__contactList {
}

.BusinessCardPreview__contactItem {
    margin-bottom: 12px;
}

@media (max-width: 640px) {
    .BusinessCardPreview__contactItem {
        margin-bottom: 8px;
    }
}

@media (max-width: 560px) {
    .BusinessCardPreview__contactItem {
        margin-bottom: 4px;
    }
}

.BusinessCardPreview__contactItem:last-child {
    margin-bottom: 0;
}

.BusinessCardPreview__contact {
    display: flex;
    align-items: center;
    color: var(--secondary-color);
}

.BusinessCardPreview__contactIcon {
    flex: 0 0 auto;
    margin-right: 16px;
}

@media (max-width: 1100px) {
    .BusinessCardPreview__contactIcon {
        margin-right: 12px;
    }
}

@media (max-width: 840px) {
    .BusinessCardPreview__contactIcon {
        margin-right: 8px;
    }
}

.BusinessCardPreview__contactValue {
    flex: 1 1 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
