.BusinessCardSetupHeader {
    display: flex;
    justify-content: flex-end;

    &__item {
        margin-right: 24px;

        &:last-child {
            margin-right: 0;
        }
    }

    &__fillUpData {
        color: var(--theme-secondary-color);
    }

    &__setUp {
        color: var(--theme-primary-color);
    }

    &.isRtl {
        .BusinessCardSetupHeader__item {
            margin-right: 0;

            &:not(:last-child) {
                margin-left: 24px;
            }
        }
    }
}
