.my-matchmaking {
    padding: 24px;
    position: relative;
    &__loader {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    &__delete-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        [dir='rtl'] & {
            right: unset;
            left: 10px;
        }
    }
    &__answer-container {
        max-width: 526px;
    }

    &__question-wrapper {
        margin-bottom: 24px;

        &:not(:nth-last-child(2)) {
            padding-bottom: 25px;
            border-bottom: 1px solid #cacbd0;
        }
    }

    &__question-count {
        display: none;
    }
    &__question-type,
    &__question-count {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.4px;
        text-align: left;
        color: var(--theme-text-grey-color);
        margin: 0;
        margin-bottom: 5px;
    }
    &__question-title {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: 0.0015em;
        text-align: left;
        color: var(--theme-text-color);
        margin: 0;
        margin-bottom: 16px;
    }
    &__label-wrapper {
        padding: 11px 19px;
        border: 1px solid var(--theme-primary-color);
        border-radius: 3px;
        position: relative;
        display: flex;
        flex-direction: column;
    }
    &__label-title {
        margin: 0;
        background: var(--theme-white-color);
        position: absolute;
        padding: 0 8px;
        top: -8px;
        left: 10px;
        [dir='rtl'] & {
            left: unset;
            right: 10px;
        }

        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.4px;
        color: var(--theme-primary-color);
        max-width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media (max-width: 768px) {
    .my-matchmaking {
        &__question-count {
            display: block;
            margin: 0;
            letter-spacing: 3px;
            margin-right: 5px;
            padding-right: 5px;
            border-right: 1px solid var(--theme-text-grey-color);

            [dir='rtl'] & {
                margin: 0;
                padding: 0;
                border-right: unset;

                margin-left: 5px;
                padding-left: 5px;
                border-left: 1px solid var(--theme-text-grey-color);
            }
        }

        &__question-title {
            font-weight: 600;
        }

        &__question-type {
            display: flex;
        }
        &__delete-btn {
            position: absolute;
            top: 5px;
            right: 0px;
        }
    }
}
