.agenda-page {
    height: calc(100vh - 69px);
    position: relative;
    width: 100%;
    background-color: var(--theme-background-default-color);

    &.smallContainer.fullscreen {
        position: static;
    }

    &.dropdown-component {
        max-width: 800px;
        width: 800px;
        overflow: initial;
        background-color: var(--theme-background-default-color);
        height: auto;
        transform: translateX(-50%);

        .auditorium-name {
            display: block;
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            color: var(--theme-text-primary-color);
            text-align: left;
            padding: 16px 0 16px 10px;
        }

        .tabs-wrapper {
            box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);

            &__tab {
                font-family: inherit;
            }
        }

        .add-new-task {
            display: flex;
            justify-content: center;
            &-button {
                font-size: 16px;
                margin-bottom: -30px;
            }
        }

        .page-title {
            font-size: 0;
            height: 40px;
            line-height: 40px;
            padding: 0;
            position: relative;

            .arrow {
                position: absolute;
                z-index: 2;
                cursor: pointer;
                display: flex;
                align-items: center;
                height: 40px;
                justify-content: center;
                padding: 0 10px;
                font-size: 12px;
                color: var(--theme-white-color);
                background-color: var(--theme-primary-color);
            }

            .arrow span {
                padding: 0 10px;
            }

            .arrow svg {
                width: 10px;
                height: auto;
            }

            .arrow svg path {
                stroke: --theme-white-color;
            }

            .arrow.previous {
                left: 0;
                top: 0;
            }

            .arrow.next {
                right: 0;
                top: 0;
            }

            .program-arrow h3 {
                font-weight: 100;
            }

            .arrow.next svg {
                transform: rotate(180deg);
            }

            p {
                margin: 0;
                color: var(--theme-white-color);
                display: inline-block;
                font-weight: 500;
                font-size: 10px;
                padding-top: 3px;
            }
        }

        .auditoriums-program-slider {
            &.hide {
                display: none;
            }
            .program-slider-wrapper {
                padding: 0 40px;
            }
        }

        .auditorium-program-arrow {
            position: absolute;
            top: 16px;
            cursor: pointer;
            background: #cacbd0;
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
            width: 30px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
                background: var(--theme-primary-color);
            }

            &.prev {
                left: -40px;
                border-radius: 0 60px 60px 0;
            }

            &.next {
                right: -40px;
                border-radius: 60px 0 0 60px;
                svg {
                    transform: rotate(180deg);
                }
            }

            svg {
                width: 15px;
                path {
                    stroke: var(--theme-white-color);
                }
            }
        }

        .auditorium-program {
            margin: 0 auto;
        }

        .auditorium-day-program-container {
            width: 100%;
            max-width: 270px;
            margin: auto;
        }
    }

    .program-entry {
        padding: 0;
        margin: 0;
        border: 0;
    }

    .program-entry ul {
        padding: 0;
    }

    .program-wrapper {
        padding-right: 10px;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0 20px;
        position: relative;
    }
    ul li {
        display: flex;
        align-items: center;
        padding: 5px 0;
    }
    ul > span {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 94%;
        text-align: right;
    }
}

.program-wrapper .program-time {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 13px;
    line-height: 13px;
    color: var(--theme-text-primary-color);
    text-align: right;
    flex-basis: 45px;
}
.program-entry .program-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    padding-bottom: 10px;
    border-bottom: 0.5px solid #cacbd0;
    margin-bottom: 10px;
}
.program-entry .program-container.is-empty {
    border-bottom: none;
}
.program-entry .program-container.is-empty .empty-program {
    width: 100%;
}
.program-entry:last-child .program-container {
    padding-bottom: 25px;
}
.program-container .topic {
    color: var(--theme-secondary-color);
    word-break: break-word;
    font-weight: bold;
}
.program-container .topic.is-link {
    cursor: pointer;
}
.next-video {
    position: absolute;
    width: calc((100vh - 70px) * 1.10804);
    height: calc((100vh - 70px) * 0.62516);
    top: calc((-100vh + 70px) * 0.36929);
    left: calc((-100vh + 70px) * 0.55394);
}
.next-video .next-video-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
}
.next-video .next-video-container .timeslot-background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.next-video .next-video-container .timeslot-background-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.next-video .next-video-container .timeslot-background-image .overlay {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1;
}
.next-video p {
    font-weight: 600;
    font-size: 46px;
    color: rgb(53, 62, 64);
    margin-top: 0;
    position: relative;
    z-index: 2;
    margin-bottom: 0;
}
.next-video p.auditorium-program-ended-text {
    font-size: 24px;
    line-height: 48px;
}
.next-video span {
    font-size: 24px;
}
.next-video .next-video-container.restricted svg {
    display: block;
    margin: 20px auto 0;
    font-size: 70px;
}
.videoWrapper {
    position: absolute;
    width: calc((100vh - 70px) * 1.10804);
    height: calc((100vh - 70px) * 0.62516);
    top: calc((-100vh + 70px) * 0.36929);
    left: calc((-100vh + 70px) * 0.55394);
    display: flex;
    align-items: center;
    justify-content: center;
}
.videoWrapper .video-container {
    width: 100%;
    height: 100%;
    position: relative;
}
.videoWrapper .video-actions-container {
    position: absolute;
    bottom: -5.5vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 11;
    height: 50px;
    top: calc(((100vh - 70px) * 0.62516) + 10px);
    overflow: hidden;
}

.auditoriumOverlayPlayer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: transparent;
}
.auditoriumOverlayPlayer.audio-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 60px;
    background-color: rgba(0, 0, 0, 0.65);
    color: var(--theme-white-color);
}

.auditoriums-navigation {
    position: absolute;
    z-index: 9;
    height: 0;
    bottom: 25vh;
    display: flex;
    justify-content: space-between;
    left: calc(-50vw - 90px);
    width: calc(100vw + 180px);
}
.auditoriums-navigation.has-booth-wall {
    width: calc(100vw - 143px);
    min-width: calc(75vw + 180px);
}
.auditoriums-navigation .button-container .details {
    width: 180px;
    height: 180px;
    padding: 30px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}
.auditoriums-navigation .button-container:hover .details {
    background-color: var(--theme-white-color);
}
.auditoriums-navigation .previous .button-container .details {
    transform: rotate(180deg);
}
.auditoriums-navigation .button-container {
    position: relative;
    transform: scale(0.5);
    opacity: 0.5;
}
.auditoriums-navigation .button-container:hover {
    transform: scale(1);
    opacity: 1;
}

.auditoriums-navigation .button-container .auditorium-name {
    font-size: 16px;
    text-transform: uppercase;
    left: 90px;
    position: absolute;
    padding-left: 90px;
    top: 50%;
    height: 140px;
    transform: translateY(-50%);
    justify-content: center;
    align-items: center;
    background: var(--theme-background-default-color);
    box-shadow: 0 14px 24px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    z-index: -1;
    display: none;
}
.auditoriums-navigation .button-container:hover .auditorium-name {
    display: flex;
}
.auditoriums-navigation .next .button-container .auditorium-name {
    right: 90px;
    padding-left: 0;
    padding-right: 90px;
    left: initial;
}
.auditoriums-navigation .button-container .auditorium-name p {
    margin: 0;
    padding: 0 14px 0 26px;
    min-width: 130px;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    font-weight: 600;
    color: var(--theme-text-primary-color);
}
.auditoriums-navigation .next .button-container .auditorium-name p {
    text-align: right;
    padding: 0 26px 0 14px;
}
.video-actions-container button {
    padding: 0;
    background-color: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    cursor: pointer;
    font-size: 30px;
}

.auditorium-archive-page .smallContainer {
    max-width: 1160px;
}
.auditorium-archive-page .auditoriums-archive-container {
    height: calc(100vh - 190px);
}
.auditorium-archive-page .wall-and-networking-container.close {
    position: fixed;
}
.auditorium-archive-page .wall-and-networking-container.close + .program-dashboard {
    overflow: visible;
    height: auto;
}
.auditorium-archive-page .program-dashboard {
    background-color: var(--theme-background-default-color);
    overflow: auto;
    height: calc(100vh - 70px);
}
.auditorium-archive-page .program-section {
    padding-top: 16px;
    margin-bottom: 0;
}
.auditorium-archive-page .program-section .page-title {
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    padding: 0 18px;
}
.auditorium-archive-page .program-section .page-title .name {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: var(--theme-text-secondary-color);
    padding-right: 26px;
    text-transform: uppercase;
}
.auditorium-archive-page .program-section .page-title input {
    background: var(--theme-background-paper-color);
    border: 1px solid #cacbd0;
    border-radius: 8px;
    padding: 8px 60px 8px 16px;
    color: rgba(43, 43, 43, 0.5);
    outline: none;
    font-size: 14px;
    line-height: 30px;
    width: 400px;
}
.auditorium-archive-page .program-section .page-title .search-container svg {
    position: absolute;
    right: 16px;
    top: 11px;
    cursor: pointer;
}
.auditorium-archive-page .program-dashboard .program-entry {
    padding: 0 8px 32px;
    border-bottom: none;
}
.auditorium-archive-page .single-auditorium-archive-container {
    margin-bottom: 32px;
    border-bottom: 1px solid rgba(202, 203, 208, 0.5);
}
.auditorium-archive-page .single-auditorium-archive-container:last-of-type {
    margin-bottom: 0;
}
.auditorium-archive-page .single-auditorium-archive-container h4 {
    margin-top: 0;
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    color: var(--theme-secondary-color);
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    padding-left: 8px;
    text-transform: capitalize;
}
.auditorium-archive-page .single-auditorium-archive-container h4 .break,
.auditorium-archive-page .single-auditorium-archive-container h4 .dates {
    font-size: 16px;
    line-height: 20px;
    color: var(--theme-text-secondary-color);
}
.auditorium-archive-page .single-auditorium-archive-container h4 .break {
    padding: 0 16px;
}

.auditorium-archive-page .image-preview-video {
    position: relative;
    height: 100%;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border: 0.5px solid --theme-white-color;
    border-radius: 8px;
}
.auditorium-archive-page .play-button {
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: 12%;
}
.auditorium-archive-page .video-duration {
    position: absolute;
    bottom: 8px;
    right: 0;
    color: var(--theme-white-color);
    background-color: var(--theme-secondary-color);
    width: 56px;
    line-height: 18px;
    text-align: center;
    font-size: 14px;
}
.video-error-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    font-size: 24px;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
}
.video-error-container p {
    cursor: pointer;
}

.video-error-container button {
    background-color: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    cursor: pointer;
    padding: 0;
    font-size: 60px;
}
.auditorium-archive-page .image-preview-video img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}
.auditorium-archive-page .video-details-container .speakers-container {
    padding-top: 10px;
}
.auditorium-archive-page .video-details-container .speakers-container .speaker {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.04em;
    color: var(--theme-secondary-color);
    padding: 0;
    display: inline;
}
.auditorium-archive-page .video-details-container .program-date-hash .date {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.04em;
    color: var(--theme-text-secondary-color);
}
.auditorium-archive-page .video-details-container .program-date-hash .hashtag {
    padding-left: 5px;
    letter-spacing: -0.04em;
    color: var(--theme-secondary-color);
    word-break: break-word;
}
.auditorium-archive-page .video-details-container a {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--theme-text-primary-color);
    letter-spacing: -0.04em;
    word-break: break-word;
    display: block;
    padding: 5px 0;
}
.agenda-dropdown {
    box-shadow: -6px 6px 14px rgba(16, 16, 16, 0.14);
}
.auditorium-banner-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
#bannerLeft,
#bannerRight {
    mix-blend-mode: multiply;
}
#bannerLeft {
    width: calc((100vh - 70px) * 0.1776239);
    height: calc((100vh - 70px) * 0.340253);
    top: calc((-100vh + 70px) * 0.0668973);
    left: calc((-100vh + 70px) * 0.9423298);
}
#bannerRight {
    width: calc((100vh - 70px) * 0.1776239);
    height: calc((100vh - 70px) * 0.340253);
    top: calc((-100vh + 70px) * 0.0668973);
    left: calc((100vh - 70px) * 0.9423298);
}
@media all and (max-height: 859px) {
    .auditoriums-navigation.has-booth-wall {
        min-width: calc(78vw + 180px);
    }
}
/* Program mobile version */
.agenda-mobile {
    max-width: initial !important;
    min-width: initial !important;
    width: 100% !important;
    transform: translateX(0) !important;
    top: 56px !important;
    height: calc(calc(var(--vh, 1vh) * 100) - 112px) !important;

    .page-title {
        height: 50px;
        line-height: 50px;
        margin-top: -20px;
    }
    .page-title .arrow {
        padding-left: 5px;
        padding-right: 5px;
        height: 50px;
    }

    .page-title .arrow span {
        padding-left: 5px;
        padding-right: 5px;
    }

    .program-dropdown-day h3 span.day-position {
        font-size: 16px;
    }
    .program-dropdown-day h3 span.date {
        font-size: 14px;
    }
    .auditorium-day-program-container {
        max-width: initial;
    }
    .auditoriums-program-slider .program-slider-wrapper {
        padding: 0;
        position: relative;
    }
    .program-wrapper {
        padding: 0;
    }

    .auditorium-name {
        height: 53px;
        padding: 0 40px;
        background: var(--theme-background-paper-color);
        position: absolute;
        width: 100vw;
        bottom: 44px;
        z-index: 1;
    }

    .auditorium-name p {
        margin-top: 0;
        margin-bottom: 0;
        display: block;
        display: -webkit-box;
        max-width: 100%;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        text-align: left;
        white-space: normal;
        padding-top: 10px;
    }

    .auditorium-name span {
        font-size: 14px;
        text-transform: uppercase;
        display: block;
        text-align: right;
        font-weight: 500;
        color: #777081;
    }

    .auditorium-program-arrow {
        height: 52px;
        border-radius: 0 52px 52px 0;
        top: initial;
        bottom: 44px;
        z-index: 9999999;

        &:hover {
            background-color: var(--theme-secondary-color);
        }

        &.prev {
            left: 0;
        }

        &.next {
            right: 0;
            border-radius: 52px 0 0 52px;
        }
    }

    .program-entry:last-child .program-container {
        padding-bottom: 52px;
        border: 0;
    }
    .program-wrapper {
        padding: 16px 0 0;
        min-height: calc(100vh - 149px);
    }
    .program-wrapper .program-time {
        font-size: 14px;
    }
}

@media screen and (max-width: 1024px) {
    .auditorium-archive-page .program-section .page-title .search-container {
        margin-top: 16px;
        width: 100%;
        max-width: 500px;
    }
    .auditorium-archive-page .program-section .page-title input {
        width: 100%;
    }
    .auditorium-archive-page .program-section .page-title {
        padding: 0 16px;
        display: flex;
        flex-flow: column;
    }
    .agenda-page {
        display: flex;
        flex-flow: column;
    }
    .agenda-page .centerOfPage {
        flex: 1;
    }
    .agenda-page .centerOfPage.hide {
        position: absolute;
        z-index: -1;
        transform: translateX(-100%);
    }
    .agenda-page .relativeCenter {
        height: 100%;
        display: flex;
        flex-flow: column;
    }
    .agenda-page .page-title {
        color: var(--theme-white-color);
        text-align: left;
        padding: 0 16px;
        font-size: 16px;
    }
    .agenda-page .tabs-container {
        background-color: var(--theme-background-paper-color);
        min-height: initial;
        height: 48px;
        display: flex;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    }
    .agenda-page .tab {
        font-size: 14px;
        font-weight: 600;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
    .agenda-page .tab.active {
        font-weight: 600;
        font-size: 14px;
        color: var(--theme-secondary-color);
        border-bottom: 2px solid var(--theme-secondary-color);
    }
    .videoWrapper {
        position: relative;
        width: 100%;
        height: auto;
        top: initial;
        left: initial;
        flex-flow: column;
    }
    .videoWrapper .video-container {
        height: calc(100vw * 9 / 16);
    }

    .videoWrapper .video-actions-container {
        position: initial;
        left: initial;
        bottom: initial;
        top: initial;
        background-color: var(--theme-primary-color);
        height: 50px;
        padding: 16px;
    }

    .video-actions-container button {
        color: var(--theme-white-color);
    }

    .slot-mobile-details {
        display: flex;
        flex-flow: column;
        flex: 1;
    }
    .slot-title {
        position: initial;
        padding: 8px 16px;
        background-color: var(--theme-background-default-color);
        left: initial;
        top: initial;
        flex: 1;
    }
    .agenda-page .slot-title p {
        padding: 0;
        color: #2b2b2b;
    }
    .next-video {
        position: initial;
        width: 100%;
        height: calc(100vw * 9 / 16);
        top: initial;
        left: initial;
    }

    .agenda-mobile.agenda-page .program-wrapper {
        min-height: calc(calc(var(--vh, 1vh) * 100) - 149px);
    }
}
