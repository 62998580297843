.empty-program {
    &__program-entry {
        padding: 0;
        margin: 0;
        border: 0;
    }

    &__program-container {
        margin: 0 auto;
        &--is-empty {
            border-bottom: none;
        }
        &:last-child {
            padding-bottom: 25px;
        }
    }

    &__empty-program {
        min-height: 250px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 30px;
        background-color: var(--theme-background-default-color);
    }

    &__info-text {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: var(--theme-text-secondary-color);
        margin-top: 0;
        text-align: center;
    }
    &__icon {
        height: 48px;
        width: 48px;
    }
}

@media screen and (max-width: 700px) {
    &__program-container {
        &:last-child {
            padding-bottom: 52px;
        }
    }
}
