.event-listed-card-profile {
    margin: 0;
    width: 100%;
    background: var(--theme-background-default-color);

    &__date {
        color: var(--theme-text-secondary-color);
        margin: 0;
        font-weight: 500;
        font-size: 14px;
        margin-right: 10px;
        direction: ltr !important;
        &.isRtl {
            margin-right: 0px;
            margin-left: 10px;
        }
    }
    &__details {
        padding: 8px 16px 5px;
    }
    &__details-text {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: var(--theme-text-color-black);
        letter-spacing: -0.4px;
        margin: 0;
    }
    &__featured-flag {
        position: relative;
        font-weight: 500;
        font-size: 14px;
        color: var(--theme-primary-color);
        margin: 0;
        padding-left: 15px;

        &::before {
            content: '';
            display: block;
            position: absolute;
            width: 5px;
            height: 5px;
            background-color: var(--theme-primary-color);
            border-radius: 50%;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
        }

        &.isRtl {
            &.isMobile {
                height: 12px; //is font-size -2px, it's fix for mobile and "Tajawal" fornt on rtl ;
            }
        }
    }
    &__summary-info-wrapper {
        display: flex;
    }

    &__summary {
        min-height: 75px;
        &--content {
            position: relative;
            margin: 12px 0 !important;
        }
    }
    &__title {
        margin: 0;
        width: 220px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: var(--theme-text-color-black);
        letter-spacing: 0.0015em;
        font-size: 20px;
        font-weight: 500;
    }

    &__unsafe-icon {
        position: absolute;
        top: -60%;
        transform: translateY(50%);
        right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.isRtl {
            right: unset;
            top: -65%;
            left: 5px;
        }
    }
}
