.moderation-action {
    &__action-wrapper {
        &.isRtl {
            direction: rtl;
        }

        display: flex;
        padding: 16px 0;
        flex-direction: column;
        width: 100%;
        align-items: center;

        & .Button {
            color: var(--theme-primary-color) !important;
            border: 1px solid var(--theme-primary-color) !important;
            text-align: center !important;
            transition: 0.4s;
            padding: 4px 12px !important;
            width: max-content !important;

            &:hover {
                border: 1px solid transparent !important;
                color: var(--theme-text-dark-grey-color) !important;
            }

            &:active {
                background-color: var(--theme-primary-color) !important;
                color: var(--theme-white-color) !important;
            }
        }
    }

    &__pending-comment-msg {
        color: var(--theme-secondary-color);
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: flex-end;
    }

    &__pending-comment-msg-text {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 95.9%;
        margin: 0;
        margin-right: 14px;

        [dir='rtl'] & {
            height: 15px;
            margin: 0;
            margin-left: 14px;
            line-height: 18px;
        }
    }
}
