@import './mixins';

.homepage .event-container,
.login-page .event-container {
    background-color: var(--theme-secondary-color);
    color: var(--theme-white-color);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    height: 100vh;
    position: relative;
}
.homepage .event-container .single-event-container,
.login-page .event-container .single-event-container {
    position: relative;
    padding-right: 550px;
    width: 100%;
}
.register-participant .single-event-container {
    padding-right: 0 !important;
}
.header.homepage {
    width: 100%;
    position: absolute;
    background-color: rgba(256, 256, 256, 0.5);
    z-index: 1;
}
.header.homepage button {
    background-color: transparent;
    border: 0;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: var(--theme-white-color);
    text-transform: uppercase;
    margin-right: 33px !important;
    outline: 0;
    cursor: pointer;
}
.homepage {
    h1 {
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        margin: 0;
        line-height: 1.3;
    }
    .single-event-container p {
        font-weight: 500;
        font-size: 22px;
        line-height: 27px;
        margin-top: 23px;
    }
    .single-event-container p.event-description {
        font-size: 16px;
        line-height: 20px;
        min-height: 350px;
    }
    .single-event-container .organizer-name {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        margin: 0;
    }
}
.homepage-overlay {
    height: 100%;
    background-color: rgba(29, 29, 27, 0.5);
    position: absolute;
    width: 100%;
    top: 0;
}
.homepage .smallContainer,
.login-page .smallContainer {
    max-width: 1587px;
}
.login-page {
    height: 100vh;

    .login-body {
        padding: 30px;
    }
    .login-header {
        background-color: var(--theme-white-color);
        padding: 40px 24px 24px;
        border-radius: 8px 8px 0 0;
        text-align: left;
    }
    .event-container {
        height: 100%;
    }

    &.isRtl {
        .page-title,
        .to-register,
        .login-description {
            text-align: right;
        }
    }
}
.homepage .event-container .smallContainer,
.login-page .event-container .smallContainer {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0 10px;
    position: relative;
    padding-top: 70px !important;
    height: calc(100% - 70px);
}
.video-overlay .play-button {
    background-repeat: no-repeat;
    background-size: 25%;
    background-position: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
}
#random-banner {
    position: absolute;
    right: 0;
    width: 273px;
    height: 210px;
    bottom: 0;
}
#random-banner a {
    height: 100%;
}
#random-banner > div {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
#random-banner > div img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: right;
}
.homepage .event-container .actions-container,
.login-page .event-container .actions-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 35px;
}
.homepage .event-container .actions-container p {
    font-weight: 800;
    font-size: 28px;
    line-height: 34px;
    margin: 0;
}
.homepage .event-container .actions-container button {
    margin: 0;
    background-color: var(--theme-primary-color);
    border: 0;
    padding: 14px 100px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: var(--theme-white-color);
    text-transform: uppercase;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.13);
    border-radius: 8px;
    outline: 0;
    cursor: pointer;
}
.homepage .auditoriums-program {
    padding-top: 50px;
}
.homepage .auditoriums-program h2,
.guests-container h3 {
    font-weight: 800;
    font-size: 38px;
    line-height: 46px;
    color: var(--theme-secondary-color);
    max-width: 495px;
    text-align: right;
    margin-left: auto;
}
.homepage .auditoriums-program ul {
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.homepage .auditoriums-program ul li {
    text-align: center;
    padding: 20px 0;
    width: 33.3%;
}
.homepage .auditoriums-program ul li p {
    margin: 0;
}
.homepage .speaker-avatar {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 148px;
    height: 148px;
    border: 1px solid --theme-white-color;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    margin: 0 auto;
}
.homepage .speaker-information {
    padding-top: 15px;
}
.homepage .speaker-information > div:first-child {
    max-width: 300px;
    margin: 0 auto;
}
.homepage .speaker-information > div p {
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;
    color: var(--theme-text-primary-color);
}
.homepage .speaker > div p:last-of-type {
    font-size: 18px;
    color: var(--theme-text-primary-color);
}
.homepage .speaker > div p[data-empty='true'] {
    padding: 0;
}
.homepage .social-media-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
}
.homepage .social-media-wrapper > div {
    display: inline-flex;
    font-size: 0;
    margin-left: 4px;
}
.homepage .speaker .social-media-wrapper > p {
    // font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px !important;
    line-height: 24px;
    color: var(--theme-text-secondary-color) !important;
}
.homepage .social-media-wrapper .social-button {
    margin-left: 6px;
}
.speakers-section-details {
    position: relative;
    padding-top: 60px;
}
.speakers-section-details p {
    // font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: var(--theme-text-primary-color);
    max-width: 900px;
    text-align: center;
    margin: 0 auto;
    padding: 0 15px;
}
.speakers-section-details img {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0;
}
.program-wrapper .image-wrapper-top {
    min-height: 336px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.program-wrapper .image-wrapper-bottom {
    height: 300px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.homepage .program-container {
    background-color: var(--theme-secondary-color);
}
.homepage .program-list-container {
    background-color: var(--theme-white-color);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 40px 30px 0;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
}
.program-list-container .program-entry {
    padding: 0;
}
.program-list-container .program-entry h4 {
    margin: 0;
    padding-bottom: 10px;
    color: var(--theme-text-secondary-color);
    padding-top: 2px;
    font-weight: 600;
}
.program-list-container p {
    margin: 0;
    overflow-wrap: break-word;
}
.program-list-container .note {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--theme-text-secondary-color);
}
.program-list-container .program-item {
    padding-top: 30px;
}
.program-list-container .left-program-container {
    width: 45%;
    float: left;
}
.program-list-container .right-program-container {
    width: 45%;
    float: right;
}
.clearfix {
    clear: both;
}
.homepage .auditorium-program {
    position: relative;
}
.homepage .auditorium-program:after {
    content: '';
    position: absolute;
    height: 80px;
    background-color: var(--theme-white-color);
    width: 100%;
}
.homepage .auditorium-program h3,
.homepage .event-name {
    margin-top: 0;
    font-weight: 800;
    font-size: 38px;
    line-height: 46px;
    text-align: right;
    color: var(--theme-white-color);
}
.homepage .auditorium-program .auditorium-name {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    color: var(--theme-secondary-color);
    margin-top: 0;
    margin-bottom: 10px;
    text-transform: uppercase;
}
.homepage .program-entry {
    border: 0;
    margin: 0;
    padding-bottom: 30px;
}
.homepage .program-entry ul {
    margin: 0;
}
.homepage .program-entry ul li {
    text-align: left;
    width: 50%;
    padding: 0;
}
.homepage .program-entry .program-time {
    font-weight: bold;
    color: var(--theme-secondary-color);
    text-align: left;
    &.isRtl {
        text-align: right;
    }
    font-size: 18px;
}
.guests-container {
    padding-top: 180px;
}
.guests-container .slick-arrow {
    display: none !important;
}
.guests-container .slider-carousel {
    text-align: center;
    margin-top: 80px;
    margin-bottom: 113px;
}
.guests-container h3 {
    margin-bottom: 16px;
}
.guests-container p {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    color: var(--theme-text-primary-color);
}
.guests-container .react-tabs__tab-list {
    border: 0;
    margin-bottom: 80px;
}
.guests-container .react-tabs__tab {
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;
    padding: 7px 27px;
    text-transform: uppercase;
    border: 0;
    color: var(--theme-text-secondary-color);
}
.guests-container .react-tabs__tab:focus {
    outline: 0;
    box-shadow: 0 0 5px transparent;
    border-color: transparent;
}
.guests-container .react-tabs__tab--selected {
    color: var(--theme-white-color);
    background: var(--theme-secondary-color);
    border-radius: 0;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    cursor: initial;
}
.slider1 .testimonials-container {
    padding-bottom: 165px;
    text-align: left;
    padding-top: 125px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.slider1 .testimonials-container img {
    float: right;
}
.slider1 .testimonials-container h2 {
    font-weight: 900;
    font-size: 40px;
    line-height: 47px;
    color: var(--theme-text-primary-color);
    margin: 0;
    text-transform: uppercase;
    padding-bottom: 50px;
}
.slider1 .testimonials-container h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--theme-text-secondary-color);
    margin: 0;
    padding-bottom: 25px;
}
.slider1 .testimonials-container .testimonial-description {
    max-width: 900px;
    padding-left: 28px;
    border-left: 2px solid var(--theme-secondary-color);
}
.slider1 .testimonial-description p {
    line-height: 24px;
    margin-bottom: 16px;
}
.slider1 .slider-image-container {
    width: 100% !important;
}
.slider2 img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}
.slider2 img:hover {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
}
.slider2 img.slick-arrow {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
    width: 30.5px;
    height: 61px;
}
.slick-active.slick-current img {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
}
.partner-logo-container {
    width: 300px;
    height: 250px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 auto;
}
.partners-social-buttons {
    display: flex;
    justify-content: center;
    padding-top: 50px;
    flex-wrap: wrap;
}
.partners-social-buttons > div {
    flex: 1 0 100%;
    text-align: center;
    margin-top: 20px;
}
.partners-social-buttons > div a {
    color: var(--theme-text-secondary-color);
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
}
.partners-social-buttons a {
    margin: 0 15px;
}
footer {
    background-color: var(--theme-text-color);
    padding: 50px 0 100px;
}
.footer-container {
    max-width: 1224px;
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 30px;
}
.footer-container .links-container {
    display: flex;
    justify-content: space-between;
}
.footer-container .links-container ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.footer-container .links-container .social-buttons-wrapper p {
    margin-top: 0;
    color: #cacbd0;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 15px;
}
.footer-container .links-container .social-buttons-wrapper ul {
    display: flex;
    align-items: center;
}
.footer-container .links-container .social-buttons-wrapper ul li {
    padding-left: 40px;
}
.footer-container .links-container .social-buttons-wrapper ul li:first-child {
    padding-left: 0;
}
.footer-container .links-container .social-buttons-wrapper.isRtl ul li {
    padding: 0 40px 0 0;
}
.footer-container .links-container .social-buttons-wrapper.isRtl ul li:first-child {
    padding-right: 0;
}
.footer-container .links-container .social-buttons-wrapper ul li svg {
    width: 36px;
    height: auto;
}
.footer-container .links-container .social-buttons-wrapper ul li:hover svg {
    stroke: var(--theme-primary-color);
}
.footer-container .links-container .inner-links li {
    margin-bottom: 20px;
}
.footer-container .links-container .inner-links li:last-child {
    margin-bottom: 0;
}
.footer-container .links-container .inner-links a {
    color: var(--theme-white-color);
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.0125em;
    text-transform: uppercase;
}
.footer-container .links-container .inner-links a:hover {
    color: var(--theme-primary-color);
    padding-bottom: 5px;
    border-bottom: 2px solid var(--theme-primary-color);
}
footer .logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--theme-white-color);
    margin-top: 100px;
    direction: ltr;
}
footer .logo-wrapper span {
    font-weight: 600;
    padding-left: 20px;
}
.login-page.ansa .single-event-container > p {
    font-size: 24px;
    line-height: 30px;
    font-weight: 300;
}
.login-page.register-participant h1 {
    margin-bottom: 20px;
}

.login-popup {
    background: rgba(#f5f5f5, 0.7);
    right: 0;
    color: var(--theme-text-secondary-color);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}
.login-page .page-title {
    color: var(--theme-secondary-color);
    font-weight: bold;
    font-size: 34px;
    margin: 0 0 34px;
}
.login-page .single-event-container > p {
    font-size: 24px;
    line-height: 30px;
    font-weight: 300;
    max-width: 600px;
}
.login-page .event-container .smallContainer {
    position: relative;
    justify-content: center;
}
.social-btn {
    display: flex;
    align-items: center;
    background-color: var(--theme-white-color);
    color: var(--theme-text-secondary-color);
    border: 0.5px solid rgba(137, 137, 137, 0.5);
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.13);
    border-radius: 8px;
    width: 100%;
    margin-top: 10px;
    outline: 0;
    height: 53px;
    position: relative;
    padding: 0 16px;
    justify-content: space-around;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    cursor: pointer;
}
.social-btn.facebook-button {
    background-color: #3c5a99;
    color: var(--theme-white-color);
}
.social-btn img {
    width: 26px;
    left: 16px;
}
.social-btn.twitter-button {
    background-color: #1da1f2;
    color: var(--theme-white-color);
}
.login-page .or-text {
    margin: 0;
    text-align: center;
    position: relative;
    // font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    padding: 4px 0;
}
.login-page .or-text:before,
.login-page .or-text:after {
    content: '';
    position: absolute;
    height: 1px;
    background-color: var(--theme-text-secondary-color);
    width: 123px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}
.login-page .or-text:after {
    right: 0;
    left: initial;
}
.login-page form {
    padding: 0;
}
.login-page form > p {
    color: #eb5757 !important;
    font-size: 12px !important;
    font-weight: bold !important;
    display: block !important;
}
.login-page form > p.invitation-code-information {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--theme-text-secondary-color) !important;
    margin-top: 10px;
    margin-bottom: 16px;
}
.login-page .email-wrapper,
.login-page .password-wrapper {
    position: relative;
    margin-bottom: 10px;
}
.login-page .email-wrapper .icon,
.login-page .password-wrapper .icon {
    position: absolute;
    right: 10px;
    top: 17px;
}
.login-page label,
.register-form-wrapper label,
.edit-user-information label {
    // font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    display: inline-flex;
    align-items: center;
    margin: 0;
}

.register-form-wrapper {
    [dir='rtl'] & {
        legend {
            text-align: right;
        }
    }
}
.login-page .email-wrapper > div > p,
.login-page .password-wrapper > div > p,
.register-participant .register-form-wrapper form > div > p {
    // font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: var(--theme-secondary-color);
    text-align: left;
    margin-left: 0;
}
.recover-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 5px;
    font-size: 0;
    flex-wrap: wrap;
}
.recover-wrapper button {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    background-color: transparent;
    border: none;
    outline: none !important;
    cursor: pointer;
    font-size: 16px;
    text-transform: uppercase;
    color: var(--theme-secondary-color);
}
.recover-wrapper button:disabled {
    cursor: initial;
}
.recover-wrapper span {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    padding-right: 5px;
}
.recover-wrapper > p.server-message {
    flex: 0 0 100%;
    text-align: left;
    font-size: 12px;
    color: var(--theme-primary-color);
    font-weight: bold;
}
.recover-wrapper .remember-me:checked,
.register-form-wrapper .agree-terms input:checked {
    background-color: var(--theme-primary-color);
    position: relative;
}

.agree-terms label span a:hover {
    color: var(--theme-secondary-color) !important;
}
.agree-terms label input {
    cursor: pointer;
}
.recover-wrapper .remember-me:checked:before,
.register-form-wrapper .agree-terms input:checked:before {
    content: '';
    left: 3px;
    top: 0;
    width: 3px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
}
.login-submit-button,
.register-button {
    color: var(--theme-white-color);
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    border: 0;
    background-color: var(--theme-primary-color);
    background-color: #646464;
    text-transform: uppercase;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.13);
    border-radius: 8px;
    max-width: 100%;
    width: 300px;
    padding: 14px 0;
    margin-top: 30px;
    outline: 0;
    cursor: pointer;
}
.login-submit-button.disabled,
.register-button.disabled {
    cursor: initial;
}
.register-form-wrapper.step-2 .buttons-wrapper button {
    width: 100%;
    font-size: 16px;
}
.login-popup p {
    margin-bottom: 0;
    font-weight: 600;
    color: var(--theme-text-grey-color);
    font-size: 16px;
}
.login-popup .to-register {
    color: var(--theme-text-primary-color);
    text-align: center;
}
.login-popup p span > a {
    font-weight: 600;
    color: var(--theme-secondary-color);
    margin-left: 8px;
    [dir='rtl'] & {
        margin-left: 0;
        margin-right: 8px;
    }
}
.register-participant .smallContainer {
    justify-content: flex-end;
}
.register-participant .single-event-container {
    width: 80%;
    max-width: 80%;
    display: flex;
    flex-direction: row;
    background: rgba(245, 245, 245, 0.9);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding-right: 0 !important;
}
.left-form h1 {
    text-align: center !important;
}
.single-event-container .left-form {
    padding: 20px;
    width: 318px;
    background-color: var(--theme-white-color);
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}
.single-event-container .left-form h1 {
    color: #7e57aa;
    font-weight: 800;
    line-height: 1;
    margin-bottom: 50px;
    font-size: 50px;
}
.single-event-container .left-form p {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--theme-text-secondary-color);
}
.single-event-container .right-form {
    flex: 1;
    padding-left: 15px;
    padding-right: 15px;
}
.single-event-container .right-form > p {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--theme-secondary-color);
    text-align: center;
    margin-top: 37px;
}
.register-participant .only-login {
    display: none;
}
.register-participant .or-text {
    display: none;
}
.register-form-wrapper form > div {
    margin-bottom: 10px;
}
.register-form-wrapper form > div.agree-terms {
    margin: 10px 0;
}
/*.register-form-wrapper fieldset,*/
/*.edit-user-information fieldset {*/
/*border: 0.5px solid #898989;*/
/*!*border-color: #898989 !important;*!*/
/*border-radius: 8px;*/
/*background-color: var(--theme-white-color);*/
/*}*/
.register-form-wrapper label + div:hover,
.edit-user-information label + div:hover {
    border: 0 !important;
}
/*.register-form-wrapper input,*/
/*.edit-user-information input{*/
/*position: relative;*/
/*color: #847878 !important;*/
/*z-index: 1;*/
/*}*/
.edit-user-information .single-option-container input {
    position: absolute;
}
.register-participant .register-form-wrapper label.error-label {
    position: relative;
}
.register-participant .register-form-wrapper label > p {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--theme-text-secondary-color);
    margin: 0;
}
.register-participant .register-form-wrapper label > p.error-text,
.register-participant .register-form-wrapper p.error-text {
    position: absolute;
    bottom: -15px;
    font-size: 12px;
    color: var(--theme-secondary-color);
    font-weight: 400;
}
.register-participant .register-form-wrapper p.error-text {
    position: initial;
    margin: 0;
    padding-bottom: 15px;
    font-size: 12px;
}
.register-form-wrapper form > label,
.register-form-wrapper form .form-footer > label {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
}
.register-form-wrapper form > label {
    color: var(--theme-secondary-color) !important;
    justify-content: unset;
    margin-bottom: 10px;
}
.register-form-wrapper form > label .upload-image,
.register-form-wrapper form .form-footer > label .upload-image {
    width: 53px;
    height: 53px;
    background-color: var(--theme-white-color);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    transform: rotate(180deg);
}
.register-form-wrapper .agree-terms span {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
}
.register-form-wrapper .agree-terms span:last-of-type > span {
    color: var(--theme-text-secondary-color) !important;
}
.register-form-wrapper .agree-terms span a {
    margin: 0 5px;
    color: var(--theme-text-primary-color);
}
.register-form-wrapper .agree-terms span a:last-of-type {
    margin-right: 0;
}
.register-button {
    background-color: var(--theme-secondary-color);
    background-color: #646464;
    margin-top: 0;
    text-transform: uppercase;
    text-align: center;
}
.register-participant .register-form-wrapper > p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: center !important;
    padding-bottom: 20px;
}
.register-participant .register-form-wrapper > p a {
    color: var(--theme-primary-color);
    margin-left: 8px;
    font-weight: 600;
}
.register-participant .register-form-wrapper form > div > p {
    margin: 0;
    line-height: 1;
    margin-top: 5px;
}
.register-form-wrapper .imgPreview {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
}
.register-form-wrapper .imgPreview > div {
    order: 2;
    display: inline-flex;
    font-size: 0;
}
.register-form-wrapper .imgPreview > div span {
    max-width: 165px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
}
.register-form-wrapper .imgPreview > div button {
    background-color: transparent;
    border: 0;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #eb5757;
    outline: 0;
    cursor: pointer;
}
.register-form-wrapper .imgPreview > img {
    order: 1;
}
.register-form-wrapper.step-2 {
    padding-top: 32px;
    max-width: 320px;
}
.register-form-wrapper.step-2 .error-input fieldset {
    border-color: #eb5757 !important;
}
.buttons-wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
}
// .buttons-wrapper button {
//     width: 35%;
//     cursor: pointer;
// }
.buttons-wrapper .skip-button {
    background-color: var(--theme-secondary-color);
}
.right-form form .form-footer .register-button {
    opacity: 1;
    max-width: 376px;
    margin: 30px auto 22px auto;
    display: block;
}
.right-form form .form-footer .register-button:disabled {
    opacity: 0.7;
    cursor: initial;
}
.form-footer .imgPreview[data-empty='false'] {
    display: none;
}
.form-footer .imgPreview {
    padding-bottom: 20px;
}
.form-footer .agree-terms {
    text-align: left;
    font-size: 0;
    cursor: pointer;
}
.smallContainer .register-section {
    padding-top: 110px;
}
.register-section .actions-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 620px;
    align-items: center;
    margin: 0 auto;
}
.register-section .actions-container p {
    font-weight: 800;
    font-size: 28px;
    line-height: 34px;
    color: var(--theme-text-secondary-color);
}
.register-section .register-button {
    color: var(--theme-white-color);
    text-transform: uppercase;
    background-color: var(--theme-primary-color);
    min-width: 307.65px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.13);
}
.slider-image-container {
    max-width: 200px;
    width: 100%;
    height: 150px;
    cursor: pointer;
}
.slider2 .slick-slide {
    padding: 0 20px;
    box-sizing: border-box;
}
.slider-image-container img {
    object-fit: contain;
    object-position: center;
    height: 100%;
    width: 100%;
}
.guests-container .slick-initialized .slick-slide {
    text-align: center;
}
.register-participant .register-form-wrapper label > p.error-text {
    padding: 0;
    margin: 0;
    bottom: -3px;
    line-height: 1;
}
.register-form-wrapper .agree-terms + .error-text {
    text-align: center;
}
.homepage.password-recovery-page .smallContainer {
    justify-content: center;
}
.homepage.password-recovery-page .single-event-container {
    max-width: 575px;
    margin: 0;
}
.register-form-wrapper form > label.invitation-code-container {
    justify-content: flex-start;
}
.register-form-wrapper form .invitation-message,
.register-form-wrapper .agree-terms .agree-terms-message {
    padding-left: 10px;
    display: block;
    [dir='rtl'] & {
        padding: 0;
        padding-right: 10px;
    }
}
.form-wrapper {
    justify-content: flex-end;
    position: relative;
    .form-logo {
        width: 300px;
        height: 120px;
        position: absolute;
        top: 0;
        left: 10vw;
        // display: flex;
        display: none;
        justify-content: center;
        align-items: center;
        img {
            max-width: 100%;
            max-height: 100%;
        }
        @include sm {
            position: relative;
            left: 0;
            margin-top: 40px;
        }
        @include xs {
            display: none;
        }
    }
    @include sm {
        flex-direction: column;
    }
}
.logo-placeholder {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 24px !important;
}
.create-new-event-page {
    .select-event-layout-container {
        text-align: left;
        .section-title {
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            text-transform: uppercase;
            padding-bottom: 16px;
        }
        .options-container {
            display: flex;
            .single-option-container {
                &.event-type-option {
                    margin-right: 16px;
                    flex: 0 0 130px;
                }
                &.buildings-number-option {
                    flex: 0 0 150px;
                }
            }
        }
    }
}

@media all and (max-width: 1650px) {
    .homepage .smallContainer,
    .login-page .smallContainer {
        max-width: 1400px;
    }
    .homepage .guests-container .smallContainer,
    .homepage .program-container .smallContainer {
        max-width: 1300px;
    }
    .slider1 .testimonials-container .testimonial-description {
        max-width: 700px;
    }
}
@media all and (max-width: 1466px) {
    .guests-container .smallContainer,
    .footer-container .smallContainer,
    .homepage .program-container .smallContainer {
        max-width: 1300px;
    }
}
@media all and (max-width: 1450px) {
    .homepage .smallContainer {
        max-width: initial;
        padding: 0;
        width: 100%;
        margin: 0;
    }
    .login-page .single-event-container > p {
        font-size: 20px;
        line-height: 24px;
    }
    .login-page .event-container .single-event-container {
        padding-right: 450px;
    }
    .homepage .event-container .single-event-container {
        padding-right: 300px;
    }
    .homepage .single-event-container p.event-description {
        min-height: 250px;
    }
    .homepage .single-event-container p.event-description {
        font-size: 15px;
        line-height: 18px;
    }
    .homepage .event-container .actions-container {
        padding-top: 0;
    }
    .homepage.register-participant .single-event-container {
        padding: 0;
        margin-right: 15px;
    }
    .homepage .guests-container .smallContainer,
    .footer-container .smallContainer,
    .homepage .program-container .smallContainer {
        max-width: 1300px;
        margin: 0 auto;
    }
    .homepage .single-event-container,
    .login-page .single-event-container {
        padding-left: 25px;
    }
    .homepage .event-container .smallContainer,
    .login-page .event-container .smallContainer {
        padding: 0;
    }
    #random-banner {
        width: 240px;
        height: 120px;
        right: 25px;
        bottom: 65px;
    }
    .homepage .auditoriums-program {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.register-form-wrapper.isRtl {
    .agree-terms-message {
        text-align: right !important;
        padding-right: 8px;
        padding-left: 0;
    }

    .file-label {
        margin-right: 8px;
        padding-right: 8px;
    }
}

@media all and (max-width: 1370px) {
    .homepage .single-event-container h1,
    .login-page .single-event-container h1 {
        font-size: 40px;
    }
    .homepage .guests-container .smallContainer,
    .footer-container .smallContainer,
    .homepage .program-container .smallContainer {
        max-width: 1100px;
        margin: 0 auto;
    }
}
@media screen and (max-height: 800px) {
    .login-submit-button,
    .register-button,
    .buttons-wrapper button {
        margin-top: 10px;
        padding: 10px 0;
    }
    .social-btn {
        padding: 0;
        height: 40px;
        font-size: 15px;
        justify-content: space-between;
    }
    .single-event-container .right-form > p {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .register-participant .register-form-wrapper > p {
        padding-bottom: 0;
        margin: 10px 0;
    }
    .register-form-wrapper form > div {
        margin-bottom: 5px;
    }
    .register-form-wrapper form > label .upload-image,
    .register-form-wrapper form .form-footer > label .upload-image {
        width: 40px;
        height: 40px;
    }
    .right-form form .form-footer .register-button {
        margin: 15px 0;
        text-align: center !important;
    }
}

@media all and (max-width: 1200px) {
    .homepage.register-participant .single-event-container {
        padding: 0;
        margin: auto;
        left: initial;
        right: initial;
    }
}

@media all and (max-width: 800px) {
    body,
    .login-page {
        height: auto !important;
    }

    .homepage-overlay {
        position: static !important;
    }

    .social-btn {
        padding: 0;
        height: 40px;
        font-size: 15px;
        justify-content: space-between;
    }

    .homepage.register-participant .single-event-container {
        flex-flow: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin: 0;
        margin-top: 0 !important;
    }
    .register-participant .single-event-container {
        max-width: 500px;
        position: static;
        margin: 100px auto !important;
    }
    .main-menu-container .actions-container button {
        display: none;
    }
    .single-event-container .left-form {
        border-radius: 8px;
        max-width: 318px;
        margin-top: 30px;
        width: auto;
    }
    .single-event-container .right-form > p {
        margin-top: 0;
    }
    .single-event-container .right-form {
        margin-top: 30px;
    }
}

@media all and (max-height: 700px) {
    .homepage .single-event-container h1 {
        font-size: 32px;
    }
    .homepage .single-event-container p.event-description {
        font-size: 14px;
        line-height: 16px;
    }
    .homepage .event-container .actions-container {
        padding-top: 0;
    }
}

@media all and (max-width: 1283.9px) {
    .footer-container {
        max-width: 600px;
    }
}
@media all and (max-width: 767.9px) {
    footer {
        padding-bottom: 150px;
    }
    .footer-container .links-container {
        flex-flow: column;
    }
    .footer-container .links-container .inner-links li {
        margin-bottom: 16px;
    }

    .footer-container .links-container .social-buttons-wrapper,
    .footer-container .links-container .social-buttons-wrapper.isRtl {
        p {
            margin-top: 60px;
        }
        ul {
            display: flex;
            justify-content: space-between;
            li {
                padding: 0 !important;
            }
        }
    }
    footer .logo-wrapper {
        margin-top: 50px;
    }
    .social-btn {
        padding: 0;
        height: 40px;
        font-size: 15px;
        justify-content: center;
    }
}
