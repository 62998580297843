$closeButtonWidth: 40px;

.wall-and-networking-container {
    height: calc(100vh - 70px);
    width: 363px - $closeButtonWidth;
    display: none;
    top: 0;

    &.open {
        display: block;
        filter: alpha(opacity=100);
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
        .close-dropdown {
            left: -$closeButtonWidth;
        }
    }
    .tabs-container {
        margin-left: auto;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
        position: relative;
        z-index: 2;
        background-color: var(--theme-white-color);
        width: 100%;
        min-height: 44px;
        display: flex;
        align-items: stretch;
    }

    & > div {
        position: relative;
        height: 100%;
    }

    .tab {
        font-family: inherit;
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 22px;
        color: var(--theme-text-primary-color);
        width: 50%;
    }
    .tab.networking-tab span:first-child > span {
        padding: 0;
    }
    .live-wall-tab,
    .networking-tab {
        &.active {
            color: var(--theme-primary-color);
            font-weight: 800;
            font-size: 18px;
            line-height: 22px;
            border-bottom: 2px solid var(--theme-primary-color);
        }
    }
}

.side-menu-container {
    position: absolute;
    display: flex;
    right: 0;
    top: 0;
    z-index: 200;
    height: calc(100vh - 70px);

    .secondary-background {
        background-color: var(--theme-secondary-color);
        color: var(--theme-white-color);
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
    }
    .primary-background {
        background-color: var(--theme-primary-color);
        color: var(--theme-white-color);
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
    }

    .event-actions-container.swipeable-drawer {
        padding-top: 150px;
        max-width: 38px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .buttons-wrapper {
            transform: rotate(-90deg) translate(-100%);
            transform-origin: right bottom;
            padding: 0;
            display: flex;
        }
    }
}
.event-actions-container.swipeable-drawer .side-menu-button {
    &--disabled {
        color: gray; // TODO: check design and change color
        background-color: var(--theme-background-default-color);
    }
}
