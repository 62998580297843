.my-events-mobile {
    position: fixed;
    top: 56px;
    bottom: 56px;
    display: flex;
    flex-direction: column;

    max-width: initial;
    width: 100% !important;
    top: 56px;
    z-index: 2;
    background-color: var(--theme-background-paper-color);

    &__title {
        height: 40px;
        line-height: 40px;
        display: flex;
        text-align: center;
        justify-content: space-between;
        align-items: center;
        background-color: var(--theme-primary-color);
        padding: 0 16px;
        color: #fff;

        h3 {
            font-weight: 600;
            font-size: 16px;
        }
    }

    &__create {
        display: flex;
        align-items: center;

        span {
            color: var(--theme-white-color);
            font-size: 16px;
            text-transform: uppercase;
            font-weight: bold;
            margin-left: 10px;
        }
    }

    &__list-wrapper {
        padding-left: 16px;
        padding-right: 16px;
        padding: 20px 10px;
        background-color: var(--theme-white-color);
        height: calc(calc(var(--vh, 1vh) * 100) - 112px);
        overflow-y: auto;
    }
}
