.showfloor-page {
    position: relative;

    .all-exhibitors-button {
        position: absolute;
        bottom: 100%;
        right: 60px;
        color: var(--theme-white-color);
        background-color: var(--theme-primary-color);
        border-radius: 10px 10px 0 0;
        text-transform: uppercase;
        cursor: pointer;

        .text {
            margin: 0;
            position: relative;
            height: 40px;
            width: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;

            span {
                position: absolute;
                top: -9.5px;
                right: 0;
                background-color: var(--theme-white-color);
                color: #2b2b2b;
                border-radius: 10px;
                border: 1px solid var(--theme-primary-color); // TODO: color
                height: 16px;
                width: 22px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
            }
        }

        .text-container {
            position: relative;
        }

        .info-screen-container {
            position: absolute;
            top: -80px;
            right: -50px;
            border: 1px solid #2b2b2b;
            border-radius: 3px;
            background-color: var(--theme-white-color);
            color: #2b2b2b;
            font-size: 12px;
            cursor: initial;
            text-transform: initial;
            max-width: 151px;
            z-index: 100;

            p {
                margin: 0;
                padding: 10px;
                position: relative;

                .close-icon {
                    position: absolute;
                    display: block;
                    top: -10px;
                    right: 0;
                    width: 16px;
                    height: 16px;
                    background-color: var(--theme-white-color);
                    border-radius: 50%;
                    border: 1px solid #2b2b2b;
                    font-size: 16px;
                    cursor: pointer;
                }
            }
        }
    }

    .booth {
        height: calc(100vh - 70px);
        width: calc(100% - 323px);
        min-width: 75%;
        overflow: hidden;
        position: relative;
        // TODO: refactor styles
        &--fullwidth {
            width: 100%;
        }
    }
}

@media screen and (max-width: 1024px) {
    .showfloor-page {
        display: flex;
        height: calc(100vh - 112px);
        flex-direction: column;
        background-color: var(--theme-background-default-color);

        .booth {
            width: 100%;
        }

        .exhibitor-assets {
            margin: 0;
            list-style-type: none;
            padding: 10px 0 0;
            li {
                border-bottom: 1px solid rgba(137, 137, 137, 0.5);
                padding: 10px;
                a {
                    display: flex;
                    justify-content: space-between;
                    color: var(--theme-secondary-color);
                    font-weight: 600;
                }
                .label {
                    color: #2b2b2b;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }

        .all-exhibitors-button {
            position: initial;
            border-radius: 0;
        }

        .tabs-container {
            background-color: var(--theme-background-paper-color);
            min-height: initial;
            height: 48px;
            display: flex;
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
        }
        .tab {
            font-size: 14px;
            font-weight: 600;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            &.active {
                font-weight: 600;
                font-size: 14px;
                color: var(--theme-secondary-color);
                border-bottom: 2px solid var(--theme-secondary-color);
            }
        }
    }
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
    .showfloor-page .tabs-container {
        justify-content: center;
    }
}
