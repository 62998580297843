.matching-calculation-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75% !important;
    width: 100%;
    padding: 10px;
    flex-direction: column;
    & > img {
        width: 100%;
    }
    &__text {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: -0.04em;
        text-align: center;
        color: var(--theme-second-grey-color);
    }
}
