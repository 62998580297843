.breadcrumbs {
    position: absolute;
    z-index: 2;
    width: 100%;
    left: 0;
    top: 0;
    padding: 8px 43px;
    background-color: rgba(255, 255, 255, 0.5);
    nav {
        font-weight: 500;
        font-size: 14px;
        color: var(--theme-secondary-color);

        li {
            p {
                margin: 0;
            }
            a {
                color: inherit;
                font-family: 'Montserrat', sans-serif;
                font-weight: 500;
            }
            span {
                cursor: default;
                color: var(--theme-text-primary-color);
                font-family: 'Montserrat', sans-serif;
                font-weight: 500;
            }
        }
    }
    &.white {
        background-color: rgba(43, 43, 43, 0.7);
        nav {
            color: var(--theme-white-color);
            a {
                color: var(--theme-white-color);
            }
            span {
                color: var(--theme-primary-color);
            }
        }
    }

    &.isRtl {
        padding-right: 366px;
        .breadcrumbs__separator {
            transform: rotate(180deg);
        }
        nav {
            li {
                a,
                span {
                    font-family: 'Tajawal', sans-serif;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    #breadcrumbs {
        display: none;
    }
}
