.account-navigation-menu-mobile {
    position: relative;
    z-index: 9999;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    user-select: none;
    &.hide {
        display: none;
    }

    &__select-tab {
        padding: 14px 16px;
        background-color: var(--theme-secondary-color);
        height: 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        &--back {
            background-color: var(--theme-background-paper-color);
            border-left: 4px solid var(--theme-secondary-color);
            justify-content: flex-start;
        }

        &--active {
            background-color: var(--theme-primary-color);
        }
    }

    &__select-tab-title {
        margin: 0;
        font-weight: 600;
        font-size: 16px;
        color: var(--theme-white-color);
        text-transform: uppercase;
        user-select: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &--back {
            color: var(--theme-text-color);
        }
    }

    &__arrow-back {
        fill: var(--theme-text-color-black);
        margin-right: 12px;
        &.isRtl {
            margin: 0;
            margin-left: 12px;
        }
    }

    &__down-arrow {
        fill: var(--theme-white-color);
        width: 20px;
        height: 12px;

        &--active {
            transform: rotate(180deg);
        }
    }

    &__dropdown {
        position: absolute;
        background-color: #2b2b2b40;
        width: 100%;
        height: calc(100vh - 104px);
    }

    &__dropdown-item {
        width: 100%;
        height: 60px;
        font-weight: 600;
        font-size: 16px;
        color: var(--theme-text-primary-color);
        background: var(--theme-background-paper-color);
        padding: 16px 20px;
        display: flex;
        text-transform: uppercase;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #cacbd0;

        &:last-child {
            box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12),
                0px 8px 10px rgba(0, 0, 0, 0.2);
        }
        &--active {
            background-color: var(--theme-hover-color);
        }
    }

    &__dropdown-arrow {
        &--active {
            fill: var(--theme-secondary-color);
        }
    }
}
