.wallet-container {
    padding: 8px 10px 20px;
}

.BusinessCard {
    width: 100%;
    padding-bottom: 57%;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    margin-bottom: 15px;
    overflow: hidden;
    position: relative;
    &-container {
        background: var(--theme-white-color);
        display: flex;
        width: 100%;
        height: 100%;
        position: absolute;
    }
    &-section-divider {
        background-color: #000;
        width: 5%;
        height: 100%;
    }
    &-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 4%;
        overflow: hidden;
        width: 50%;
        @media (max-width: 640px) {
            padding: 4% 2%;
        }
    }
    &-title-divider {
        margin-top: 15px;
        width: 100%;
        height: 2px;
        background-color: #000;
        @media (max-width: 640px) {
            margin-top: 10px;
            height: 1px;
        }
    }
    &-title {
        font-weight: bold;
        font-size: 27px;
        text-transform: uppercase;
        color: #000000;
        position: relative;
        @media (max-width: 640px) {
            font-size: 18px;
        }
    }
    &-subtitle {
        margin-top: 15px;
        font-size: 16px;
        text-transform: uppercase;
        color: #000000;
        @media (max-width: 640px) {
            margin-top: 8px;
            font-size: 12px;
        }
    }
    &-logo {
        display: block;
        margin: 0 auto 25px;
        width: 100%;
    }
    &_contact {
        display: flex;
        font-weight: 600;
        max-width: 260px;
        font-size: 16px;
        color: var(--theme-white-color);
        word-break: break-word;
        align-items: center;
        a {
            color: inherit;
        }
        @media (max-width: 640px) {
            font-weight: 400;
            font-size: 12px;
            line-height: 1.8;
        }
        &-icon {
            margin-right: 25px;
            @media (max-width: 640px) {
                height: 0.7em;
                weight: 0.7em;
                margin-right: 8px;
            }
        }
    }
}

.wallet-button {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: 0.0125em;
    text-transform: uppercase;
    cursor: pointer;
    background: unset;
    border: unset;
    outline: unset;
    color: var(--theme-text-primary-color);
    fill: currentColor;
    padding: 8px 0;
    &-icon {
        fill: inherit;
        margin-right: 10px;
    }
    &.-colored {
        color: var(--theme-primary-color);
    }
}

.notes {
    margin-top: 40px;
    &-title-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        svg {
            height: auto;
            padding: 0;
        }
    }
    &-title {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 20px;
        letter-spacing: 0.0015em;
        color: var(--theme-secondary-color);
        margin: 0 0 30px;
    }
}
.note {
    &:not(:last-of-type) {
        margin-bottom: 20px;
    }
    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
    }
    &-header-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: var(--theme-text-primary-color);
    }
    &-field {
        background-color: transparent;
        border-radius: 3px;
        color: var(--theme-text-primary-color);
        .MuiFilledInput-root.Mui-disabled {
            border: 1px solid rgba(0, 0, 0, 0.12);
            color: var(--theme-text-primary-color);
            padding: 16px 20px;
            background-color: transparent;
        }
        .MuiFilledInput-underline:before {
            display: none;
        }
    }
}

.business-wallet-popup {
    height: 100%;
}
