.recover-password {
    &__wrapper {
        max-width: 530px !important; //TODO: remove !important after implementation of styleprovider
    }

    &__text {
        margin-bottom: 20px;
    }
    &__form {
        padding: 0;
    }
}
