.auditoriums-popup {
    position: absolute;
    bottom: 0;
    z-index: 12;
    margin: auto;
    left: 50%;
    transform: translateX(-50%);

    .popup-wrapper {
        position: relative;
        max-width: none !important;
    }

    .toggle-auditoriums-button {
        position: absolute;
        bottom: 100%;
        right: 0;
        cursor: pointer;

        &.close {
            right: 20px;

            p {
                background: var(--theme-secondary-color);
                padding: 8.5px 7px;
            }
        }

        p {
            background: var(--theme-background-paper-color);
            box-shadow: 0 1px 6px rgba(0, 0, 0, 0.13);
            border-radius: 8px 8px 0 0;
            font-weight: 800;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            color: var(--theme-secondary-color);
            margin: 0;
            padding: 10px 45px;
            position: relative;
            text-transform: uppercase;

            span {
                position: absolute;
                top: 0;
                right: 0;
                background-color: var(--theme-white-color);
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                border-radius: 50%;
                transform: translateY(-50%);
                border: 1px solid var(--theme-secondary-color);
                display: flex;
                align-items: center;
                justify-content: center;
                width: 18px;
                height: 18px;
            }
        }
    }

    .all-auditoriums-button p {
        margin: 0;
    }

    .slider-container {
        font-size: 20px;
        background-color: var(--theme-white-color);
        padding: 32px 26px 40px;
        background: rgba(255, 255, 255, 0.8);
        box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.35);
        border-radius: 8px 8px 0 0;

        &.has-slider {
            width: 100%;
            padding: 32px 80px 40px;

            .slick-dots {
                display: flex !important;
                justify-content: center;
                align-items: center;
                position: absolute;
                bottom: -35px;
                padding: 0;

                li {
                    padding: 0;

                    button:before {
                        font-size: 8px;
                        color: #cacbd0;
                        opacity: 1;
                    }

                    &.slick-active button:before {
                        color: var(--theme-secondary-color);
                    }
                }
            }

            .slick-arrow {
                width: auto;
                height: auto;

                svg path {
                    stroke: var(--theme-text-secondary-color);
                    opacity: 1;
                }

                &.slick-disabled svg path {
                    opacity: 0.5;
                }
            }

            .slick-prev {
                left: -53px;

                &:before {
                    display: none !important;
                }
            }

            .slick-next {
                right: -53px;

                svg {
                    transform: rotate(180deg);
                }

                &:before {
                    display: none !important;
                }
            }
        }

        .auditorium-details-container {
            position: relative;
            padding: 10px 20px;
            text-align: center;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
            border-radius: 8px;
            width: 150px;
            min-height: 90px;
        }

        .restricted .auditorium-details-container {
            cursor: initial;
        }

        .slick-slide > div,
        .auditoriums-list > div {
            padding: 0 8px;
        }

        .auditorium-details-container {
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 0;
                border-radius: 8px;
            }

            .image-filter {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.7);
                border-radius: 8px;
            }

            &.active .image-filter {
                background-color: var(--theme-secondary-color);
            }

            .auditorium-name {
                font-size: 18px;
                line-height: 22px;
                color: var(--theme-white-color);
                opacity: 1;
                margin: 0;
                position: relative;
                z-index: 2;
            }

            svg {
                color: var(--theme-white-color);
                position: absolute;
                top: 2px;
                right: 2px;
                font-size: 16px;
                z-index: 2;
            }

            .auditorium-description {
                font-size: 14px;
                line-height: 16px;
                color: var(--theme-white-color);
                margin: 0;
                opacity: 0.8;
                max-width: 160px;
                word-break: break-word;
            }
        }

        .auditoriums-list {
            display: flex;
        }
    }
}

@media all and (max-width: 1440px) {
    .auditoriums-popup {
        .toggle-auditoriums-button {
            p {
                padding: 8px 30px;
                font-size: 16px;
                line-height: 20px;
            }

            &.close p {
                padding: 6.5px 6px;

                svg {
                    width: 20px;
                    height: auto;
                }
            }
        }

        .slider-container {
            &.has-slider {
                padding: 15px 40px 15px;

                .slick-arrow svg {
                    width: 25px;
                    height: auto;
                }

                .slick-next {
                    right: -30px;
                }

                .slick-prev {
                    left: -30px;
                }
            }

            .auditorium-details-container {
                width: 120px;
                min-height: 80px;

                .auditorium-name {
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    #bannerLeft,
    #bannerRight,
    .auditoriums-popup {
        display: none;
    }
}
