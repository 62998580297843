.chat-messages-list {
    list-style: none;
    max-height: 300px;
    overflow: auto;
    padding-left: 0;
    width: 500px;

    li {
        display: flex;
        align-items: center;
        padding-bottom: 0;
        margin-bottom: 10px;

        p {
            margin: 0;
            line-height: 16px;
        }

        img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 30px;
        }
    }
}
.message {
    align-items: initial;
    .time-wrapper {
        display: flex;
        align-items: flex-end;
    }
}
.time-wrapper .message-text {
    padding: 0;
    line-height: 14px;
    color: var(--theme-text-secondary-color);
    font-size: 13px;
}

.expanded .message.me .time-wrapper {
    order: 1;
}
