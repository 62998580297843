.outside-event-header {
    position: absolute;
    z-index: 99;
    width: 100%;
}
.outside-event-header .menu-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 40px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transition: all 0.2s;
}

.outside-event-header.white .menu-wrapper {
    background: var(--theme-background-paper-color);
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
}
.outside-event-header .menu-wrapper.scrolled {
    background: var(--theme-background-paper-color);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    transition: all 0.2s;
    padding-bottom: 15px;
    padding-top: 15px;
}
.outside-event-header .platform.menu-wrapper.scrolled {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    background: var(--theme-background-paper-color);
    padding-top: 15px;
    padding-bottom: 15px;
}

.outside-event-header .platform.scrolled .menu-links a,
.outside-event-header .menu-wrapper.scrolled .menu-links a,
.outside-event-header.white .menu-wrapper.scrolled .menu-links a {
    color: var(--theme-text-primary-color);
}
.outside-event-header .platform.scrolled .menu-links a.active,
.outside-event-header .menu-wrapper.scrolled .menu-links a.active {
    color: var(--theme-white-color);
}
.outside-event-header .menu-wrapper .brand-logo a {
    position: static;
}
.outside-event-header .menu-links a {
    color: var(--theme-white-color);
    letter-spacing: 0.0125em;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    display: inline-block;
    padding: 10px 0;
    position: relative;
    transition: all 0.2s ease-out;

    &.colorRed {
        color: var(--theme-primary-color);
    }
}
.outside-event-header.white .menu-links a {
    color: var(--theme-text-primary-color);
}
.outside-event-header.white .scrolled .menu-links a,
.outside-event-header.white .menu-links a.active {
    color: var(--theme-white-color);
}
.outside-event-header .platform.scrolled .menu-links a.active:hover,
.outside-event-header .menu-wrapper.scrolled .menu-links a.active:hover {
    color: var(--theme-primary-color);
}

.outside-event-header .menu-links a.active {
    background-color: var(--theme-primary-color);
    border-radius: 8px 8px 8px 0;
    border: 2px solid transparent;
    padding: 10px 21px;
}

.outside-event-header .scrolled .menu-links a:hover {
    color: var(--theme-primary-color);
}
.outside-event-header .menu-links a:hover:after {
    display: none !important;
}

.menu-wrapper .brand-logo {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;

    a {
        img {
            max-width: 118px;
            height: 65px;

            &.customLogo {
                max-height: 65px;
            }
        }
    }
}
.menu-wrapper .select-language-container {
    margin-right: 24px;
    color: var(--theme-white-color);
    position: relative;
    cursor: pointer;
    &--primary-text {
        color: var(--theme-text-primary-color) !important;
    }
}
.menu-wrapper .select-language-container .languages-list-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 90px;
    border: 1px solid #cacbd0;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0 14px 24px rgba(32, 15, 54, 0.25);
    background: #2b2b2b;
    color: var(--theme-white-color);
}
.menu-wrapper .select-language-container .languages-list-container ul {
    margin: 0;
    list-style: none;
    padding: 0;
    text-transform: uppercase;
}
.menu-wrapper .select-language-container .languages-list-container li {
    cursor: pointer;
    padding: 10px 10px 10px 20px;
    text-align: center;
}
.menu-wrapper .select-language-container .languages-list-container li:hover {
    background: var(--theme-secondary-color);
}
.menu-wrapper .select-language-container .languages-list-container li.active {
    background: var(--theme-secondary-color);
}
.menu-wrapper .select-language-container .selected-language {
    display: flex;
    align-items: center;
    border: 1px solid #cacbd0;
    border-radius: 6px;
    text-transform: uppercase;
    justify-content: space-between;
    padding: 10px 20px;
    width: 90px;
}
.menu-wrapper.scrolled .select-language-container {
    color: #2b2b2b;
}

.menu-links a {
    display: inline-block;
    margin-right: 16px;
}
.menu-links a.black {
    color: var(--theme-text-color-black);
}

.menu-links a:last-child {
    margin-right: 0;
    [dir='rtl'] & {
        margin-right: 16px;
        margin-left: 0;
    }
}

.mobile-menu-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 16px 40px;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    transition: all 0.2s ease-in-out;
    flex-flow: column;
    display: none;
    background: rgba(43, 43, 43, 0.8);
    backdrop-filter: blur(4px);
    padding-top: 10px;
    overflow: hidden;
}
.mobile-menu-wrapper.hide {
    display: none;
}
.mobile-menu-wrapper .bottom-links-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.mobile-menu-wrapper .top-links-container {
    justify-content: flex-end;
    display: flex;
    width: 100%;
    height: 0;
    overflow: hidden;
    padding: 0;
    transition: all 0.2s ease-in-out;
    align-items: flex-end;
    flex-flow: column;
}
.mobile-menu-wrapper .top-links-container .announcement-text {
    width: 100%;
    text-align: center;
}
.mobile-menu-wrapper .top-links-container .announcement-text p {
    margin: 0;
    color: var(--theme-background-default-color);
    font-size: 12px;
}
.mobile-menu-wrapper.opened .top-links-container {
    transition: all 0.2s ease-in-out;
    height: 40px;
}
.mobile-menu-wrapper .top-links-container a {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #cacbd0;
    padding: 16px 0;
    color: var(--theme-white-color) !important;
}
.mobile-menu-wrapper .top-links-container a:last-child {
    border-bottom: none;
}
.mobile-menu-wrapper .top-links-container .menu-links {
    display: flex;
    flex-flow: column;
    width: 130px;
    align-items: center;
}
.mobile-menu-wrapper .bottom-links-container .menu-links {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.mobile-menu-wrapper .button-container {
    padding: 8px;
    width: 40px;
    height: 40px;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    transform: rotate(0deg);
    transition: transform 0.2s ease-in-out;
}
.mobile-menu-wrapper.opened .button-container {
    transform: rotate(-90deg);
    transition: transform 0.2s ease-in-out;
}
.mobile-menu-wrapper .button-container .menu-bar {
    height: 2px;
    border-radius: 2px;
    background-color: var(--theme-primary-color);
    width: 100%;
}

@media all and (max-width: 767.9px) {
    .mobile-menu-wrapper {
        padding: 16px;
        display: flex;
    }
}

@media all and (max-width: 767.9px) {
    .outside-event-header .menu-wrapper.scrolled {
        position: static;
        background: none;
        box-shadow: none;
        padding-bottom: 15px;
        padding-top: 50px;
    }
    .outside-event-header.white .menu-wrapper {
        box-shadow: none;
    }
    .outside-event-header .menu-links a.active {
        padding: 8px 20px;
    }
    .outside-event-header.white .menu-wrapper {
        box-shadow: none;
    }
    .outside-event-header .menu-links a.active {
        padding: 8px 20px;
    }
    .outside-event-header .menu-wrapper.scrolled {
        position: static;
        background: none;
        box-shadow: none;
        padding-bottom: 15px;
        padding-top: 50px;
    }
    .menu-wrapper .brand-logo {
        a {
            img {
                max-width: 118px;
                height: 36px;

                &.customLogo {
                    max-height: 36px;
                }
            }
        }
    }
}

@media (hover: hover) {
    .outside-event-header .menu-links {
        a:hover {
            color: var(--theme-primary-color);
            cursor: pointer;
        }
    }

    .outside-event-header .menu-links a:hover:after {
        content: '';
        background-color: var(--theme-primary-color);
        height: 4px;
        width: 100%;
        left: 0;
        bottom: -14px;
        position: absolute;
    }
    .outside-event-header .menu-links a.active:hover {
        background-color: var(--theme-white-color);
        border-color: var(--theme-primary-color);
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.13);
        color: var(--theme-primary-color);
    }
    .outside-event-header .menu-links a.active:hover:after {
        display: none;
    }
}

// Rtl Languages
.menu-wrapper {
    &.isRtl {
        .select-language-container {
            margin: 0 0 0 24px;
        }
    }
}
