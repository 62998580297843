.lobby {
    &__page {
        height: calc(100vh - 69px);
        position: relative;
        width: 100%;
        z-index: 1;
    }

    &__slider-logos {
        position: absolute;
        transform: translate(-50%, -50%);
        font-size: 0;
        padding: 0;
        margin: 0;
        background: transparent;
        transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

        &:hover {
            box-shadow: none;
            -webkit-transition: none;
            transition: none;
            cursor: initial;
        }

        mix-blend-mode: multiply;

        .slick-slider,
        .slick-list {
            height: 100%;
            width: 100%;
        }

        .slick-track {
            height: 100%;
        }

        .slick-slide div {
            height: 100%;
        }
    }

    &__slider-logos-img-wrapper {
        display: flex !important;
        align-items: center;
        justify-content: center;
        background-color: var(--theme-white-color);
    }

    &__slider-logos-img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }

    &__banner {
        mix-blend-mode: multiply;
    }

    &__banner-image {
        width: 100%;
        height: 100%;
        object-fit: contain;
        transform: perspective(250px) rotateY(3.6deg);
        transform-origin: top;
    }

    &__info-booth {
        background-repeat: no-repeat;
        background-size: cover;
        background-color: transparent;
        position: absolute;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        font-size: 0;
        padding: 0;
        margin: 0;
        background: transparent;
        cursor: pointer;
        transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

        &:hover {
            -webkit-filter: drop-shadow(0 0 2px var(--theme-text-secondary-color));
            filter: drop-shadow(0 0 2px var(--theme-text-secondary-color));
            box-shadow: none;
            -webkit-transition: none;
            transition: none;
        }
    }

    &__room {
    }
}

.lobby-room {
    &:hover {
        box-shadow: none;
        -webkit-transition: none;
        transition: none;
    }

    &__show-info-dialog-button {
        width: 100%;
        height: 100%;
    }

    &__link {
        display: block;
        height: 100%;
    }

    &__room-wrapper {
        height: 100%;

        &:hover {
            -webkit-transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            box-shadow: 0 -20px 30px var(--theme-active-color);
        }
        &.isSca:hover {
            box-shadow: 0 -20px 30px rgba(0, 88, 80, 0.35);
        }
    }
}

.show-lobby-video {
    cursor: pointer;

    &__image-preview-video {
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        &--no-video {
            cursor: initial !important;
        }
    }

    &__play-button {
        background-repeat: no-repeat;
        background-position: center;
        background-color: rgba(0, 0, 0, 0.2);
        background-size: 15%;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        left: 0;
    }
}

@media all and (max-width: 768px) {
    .lobby {
        &__page {
            display: none;
        }
    }
}
