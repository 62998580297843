.chat-module {
    color: var(--theme-white-color);
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .module-container {
        flex-grow: 1;
    }

    .module-body {
        padding: 0 0 8px;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: flex-end;
    }
    .chat-container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding-bottom: 10px;
    }
    .message-input {
        height: initial;
        background: var(--theme-background-paper-color);
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        position: relative;
        height: auto;
        min-height: 43px;
        display: flex;
        max-width: 100%;
        margin: 0;
        padding: 0;
        margin: 0 8px;
        align-items: center;
        input {
            height: 24px;
            line-height: 24px;
            display: block;
            flex: 1 1;
            border: none;
            outline: none;
            padding-left: 15px;
            color: var(--theme-text-primary-color);
            font-size: 12px;
        }
        button {
            font-family: 'Montserrat', sans-serif;
            height: 47px;
            background-color: transparent;
            border: 0;
            outline: 0;
            padding: 0 16px 0 10px;
            font-weight: bold;
            font-size: 12px;
            line-height: 15px;
            color: var(--theme-primary-color);
            text-transform: uppercase;
            cursor: pointer;
        }
    }
    .message {
        display: flex;
        margin-bottom: 15px;

        &-container {
            padding: 8px 8px 0;
        }
        &-avatar {
            width: 40px;
            min-width: 40px;
            height: 40px;
            margin-right: 10px;
            border-radius: 50%;
            overflow: hidden;

            border-radius: 50%;
            position: relative;
            width: 40px;
            min-width: 40px;
            height: 40px;
            color: var(--theme-white-color);
            font-weight: 600;
            font-size: 16px;
            text-transform: uppercase;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            background-color: var(--theme-secondary-color);
        }

        &-text {
            font-weight: 500;
            font-size: 12px;
            flex-grow: 1;
            line-height: 1.2;
            letter-spacing: -0.4px;
            color: var(--theme-text-dark-grey-color);
            word-break: break-word;

            .title {
                font-size: 16px;
                margin: 0 0 5px;

                .role {
                    font-size: 12px;
                    font-weight: normal;
                    text-transform: capitalize;
                }
            }
        }
        &-time {
            min-width: 55px;
            margin-left: 10px;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.4px;
            color: var(--theme-text-light-grey-color);
        }
    }
}
