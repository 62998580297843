@import './src/styles/mixins.scss';

.single-booth-participant-view {
    [dir='rtl'] & {
        direction: ltr;
    }
    height: calc(100vh - 315px);
    position: relative;

    &__booth-preview-container {
        width: 100%;
        position: absolute;
        height: 100%;
        text-align: center;
        font-size: 0;
        img {
            height: 100%;
            position: absolute;
            width: auto;
            top: 0;
            left: 50%;
            transform: translate(-50%);

            border-radius: 0;
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
        }
    }

    &__links-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        position: relative;
    }

    &__show-links {
        @include clickableElement;
        .slick-slider {
            height: 100%;
            min-width: 100%;
            display: flex;
            align-items: center;
            outline: 0;
        }
        .slick-track {
            outline: 0;
        }
    }

    &__show-links-img {
        margin: 0 auto;
        outline: 0;
    }

    &__show-links-img-wrapper {
        outline: 0;
    }

    &__company-name-wrapper {
        font-size: 39px;
        font-weight: 500;
        letter-spacing: -0.04em;
        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        transform: translate(-50%, -50%);
        padding: 0;
        margin: 0;
        background: transparent;
        transition: box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    &__company-name {
        margin: 0;
        padding: 0 8px;
        line-height: 1.2;
        font-size: inherit;
        font-weight: inherit;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        @include clickableElement;
        cursor: inherit;
    }

    &__logo-img {
        height: 100%;
        width: 100%;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    &__show-documents {
        @include clickableElement;
    }
}

.exhibitor-booth-button-marketplace {
    position: absolute;
    top: 0;
    right: -1px;
    display: flex;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.0125em;
    text-transform: uppercase;
    z-index: 1;
    border: unset;
    color: var(--theme-white-color);
    fill: var(--theme-white-color);
    transform: rotate(-90deg) translate3d(0, 0, 0);
    background: var(--theme-primary-color);
    border: 1px solid --theme-white-color;
    box-sizing: border-box;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px 8px 0 0;
    transform-origin: 90% 50%;
    align-items: center;
    padding: 11px 17px;
    transition: all 0.3s ease-out;
    outline: unset;
    cursor: pointer;

    &:hover {
        color: var(--theme-primary-color);
        fill: var(--theme-primary-color);
        background: var(--theme-white-color);
    }

    &__icon {
        transform: rotate(90deg) translate3d(0, 0, 0);
    }

    &__title {
        margin-right: 15px;
    }

    @media screen and (max-height: 900px) {
        transform: unset;
        border-radius: 0 0 0 8px;
    }
}

.exhibitor-booth-navigation {
    position: absolute;
    top: 50%;
    left: 0;
    width: 90px;
    transform: translateY(-50%);
    z-index: 101;
    cursor: pointer;

    &--previous {
        left: -90px;
    }

    &--next {
        right: 0;
        left: auto;
    }

    &__wrapper {
        position: relative;
    }

    &:hover & {
        &__details {
            background-color: var(--theme-white-color);
        }
        &__logo-container {
            display: block;
        }
    }

    &__details {
        box-shadow: 0 14px 24px rgba(0, 0, 0, 0.25);
        width: 180px;
        height: 180px;
        padding: 10px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.5);
        display: flex;
        justify-content: flex-end;
        align-items: center;

        &--next {
            justify-content: flex-start;
        }

        &--previous {
            margin-right: 0;
            margin-left: auto;
        }
    }

    &__arrow {
        &--next {
            margin-left: 28px;
        }

        &--previous {
            transform: rotate(180deg);
            margin-right: 28px;
        }
    }

    &__logo-container {
        position: absolute;
        left: 90px;
        width: 240px;
        height: 130px;
        bottom: 100%;
        padding: 20px;
        background-color: var(--theme-background-default-color);
        box-shadow: 0 14px 24px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        display: none;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;
        &--next {
            right: 0;
            left: auto;
            padding-right: 110px;
        }

        &--previous {
            padding-left: 110px;
        }
    }

    &__logo-container-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.exhibitor-booth-show-video {
    @include clickableElement;

    &__image-preview-video {
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
    &__play-button {
        background-repeat: no-repeat;
        background-position: center;
        background-color: rgba(0, 0, 0, 0.2);
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        left: 0;
        background-size: 15%;
    }
}

.exhibitor-booth-desk-representative {
    @include clickableElement(false);
    cursor: pointer;

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @keyframes glowShadow {
        to {
            box-shadow: 0 0 10px 5px var(--theme-primary-color);
        }
    }

    &:hover & {
        &__hovering-container {
            display: block;
        }
    }

    &--no-hover {
        cursor: inherit;
    }
    &--no-hover & {
        &__hovering-container {
            display: none !important;
        }
    }

    &__hovering-container {
        border-radius: 50%;
        box-shadow: 0 0 4px 2px var(--theme-primary-color);
        animation: glowShadow 1s linear infinite alternate;
        -webkit-transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        width: 100%;
        display: none;
    }
}

.exhibitor-booth-medalion-representatives {
    @include clickableElement();
    &:hover {
        box-shadow: none;
    }
    &__wrapper {
        &:last-child {
            margin: 0;
        }
    }

    &__image-container {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
        border: 0.5px solid --theme-white-color;
        cursor: pointer;

        &:hover {
            box-shadow: 0 0 3vh 1px var(--theme-primary-color);
            -webkit-transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }

        &--no-hover {
            &:hover {
                box-shadow: none;
                cursor: initial;
            }
        }
    }
}

@media all and (max-height: 700px) {
    .single-booth-participant-view {
        height: calc(calc(var(--vh, 1vh) * 100) - 210px);
        position: relative;
    }
}
