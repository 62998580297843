.messages-wrapper {
    flex: 1;
    margin-right: 14px;
    text-align: left;
    display: flex;
    flex-flow: column;

    &__user-details {
        font-weight: 600;
        color: var(--theme-text-primary-color);
        font-size: 16px;
        padding-bottom: 5px;
    }

    &__conference-message {
        color: var(--theme-primary-color);
        cursor: pointer;
    }
    &__text {
        color: var(--theme-text-primary-color);
        font-weight: 500;

        &:last-child {
            line-height: 16px;
            word-break: break-word;
            word-wrap: break-word;
            max-width: 292px;
            text-align: left;
            font-size: 13px;
        }
    }
}

.avatar-wrapper {
    min-width: 46px;
}

.message {
    &:not(.me) {
        .messages-wrapper__user-details {
            text-align: left;
        }
    }
}

.message.me .avatar-wrapper {
    font-size: 0;
}

.expanded .message.me .messages-wrapper p:last-child {
    text-align: right;
}

.expanded .message.me .messages-wrapper {
    order: 2;
    text-align: right;
    margin-right: 0;
    margin-left: 14px;
}

.expanded #chat-messages-container .messages-wrapper p:last-child {
    max-width: 320px;
}

.expanded .message.me .avatar-wrapper {
    order: 3;
}

.expanded .message.me .avatar-wrapper .user-avatar {
    margin-right: 0;
    margin-left: 10px;
}

.expanded #chat-messages-container .messages-wrapper p:last-child {
    max-width: initial;
}
