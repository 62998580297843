.FormGroup {
    display: flex;
    align-items: center;
    margin: 0 -12px;
}

.FormGroup_verticalAlignment {
    &-top {
        align-items: flex-start;
    }
}

.FormGroup_horizontalAlignmemnt {
    &-left {
    }

    &-right {
        justify-content: flex-end;
    }
}

.FormGroup__halfItem {
    flex: 0 0 50%;
    padding: 0 12px;
}

.FormGroup__narrowItem {
    flex: 0 0 auto;
    padding: 0 12px;
}

.FormGroup__wideItem {
    flex: 1 1 auto;
    padding: 0 12px;
}
