.AppliedJobs {
    flex: 1 1 auto;
    max-width: calc(100% - 240px);
    padding: 24px;
    box-sizing: border-box;

    &__titleRow {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
    }

    &__title {
        flex: 1 1 auto;
        margin: 0;
        margin-right: 16px;
        line-height: 1.2;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-transform: uppercase;
    }

    &__downloadButton {
        flex: 0 0 auto;
    }
}
