.DesktopAgendaMeetingItem {
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px;
    padding-left: 16px;
    font-weight: 500;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 8px;
        background-color: #5ab453;
    }

    &_status {
        &-confirmed::before {
        }

        &-pending::before {
            background-color: #898989;
        }

        &-requested::before {
            background-color: var(--theme-primary-color);
        }
    }

    &__titleCol {
        flex: 1 1 auto;
        margin-right: 24px;
    }

    &__title {
        margin-bottom: 4px;
        font-weight: 600;
    }

    &__description {
        margin-bottom: 4px;
        font-size: 12px;
    }

    &__status {
        font-size: 12px;
        color: #898989;
    }

    &__metaCol {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
    }

    &__dateGroup {
        flex: 0 0 auto;
        margin-right: 16px;
        font-size: 12px;
        color: #898989;
    }

    &__timeGroup {
        flex: 0 0 auto;
        margin-right: 24px;
        font-weight: 700;
        text-align: right;
    }

    &__actionGroup {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
    }

    &__actionItem {
        margin-right: 16px;

        &:last-child {
            color: var(--theme-primary-color);
            margin-right: 0;
        }

        &_hidden {
            visibility: hidden;
            pointer-events: none;
        }
    }

    &.isRtl {
        .DesktopAgendaMeetingItem__titleCol {
            margin-left: 24px;
            margin-right: 0;
        }

        .DesktopAgendaMeetingItem__actionItem:not(:first-child) {
            margin-right: 8px;

            .Button__icon {
                transform: rotate(180deg);
            }
        }
    }
}
