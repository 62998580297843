@import './src/styles/mixins.scss';

.auditorium-wall-container {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 12;
    height: 100vh;
    transition: width 1s ease-in;
    visibility: visible;
    opacity: 1;
    filter: alpha(opacity=100);
    width: 25%;
    max-width: 323px;
    display: flex;
    flex-flow: column;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);

    &.close {
        transition: width 1s ease-in;
        right: -323px;
    }

    .close-auditorium-wall {
        position: absolute;
        width: 40px;
        height: 40px;
        background: var(--theme-text-secondary-color);
        border-radius: 8px 0 0 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        right: 100%;
    }

    .auditorium-wall-header {
        margin-left: auto;
        background-color: var(--theme-white-color);
        width: 100%;
        min-height: 48px;
        display: flex;
        align-items: center;
        color: var(--theme-secondary-color);
        font-weight: 800;
        font-family: inherit;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);

        &__side-buttons-group {
            transform: rotate(-90deg) translateZ(0);
            transform-origin: 100% 100%;
            display: flex;
            white-space: nowrap;
            flex-flow: row-reverse;
            position: absolute;
            top: 0;
            right: 100%;
        }

        span.walls-tab {
            border-bottom-width: 0;
            width: 50%;
            display: block;
            text-align: center;
            display: flex;
            align-items: center;
            letter-spacing: 0.0125em;
            font-size: 16px;
            height: 100%;
            justify-content: center;
            line-height: 20px;
            text-transform: uppercase;
            position: relative;
            transition: color 0.2s ease-out;
            color: #2b2b2b;
            cursor: pointer;

            &:after {
                content: '';
                width: 100%;
                height: 2px;
                position: absolute;
                left: 0;
                transition: opacity 0.2s ease-out;
                bottom: 0;
                opacity: 0;
                background-color: var(--theme-primary-color);
            }

            &.-is-active {
                color: var(--theme-primary-color);

                &:after {
                    opacity: 1;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .auditorium-wall-container {
        width: 100%;
        max-width: 100vw;
        position: unset;

        @include calculateVHeight(152px);
        .auditorium-wall-header {
            display: none;
        }

        &__wall-custom-scroll-container {
            @include calculateVHeight(235px, true);
        }
    }
}

@media screen and (max-width: 1024px) {
}
