.booth-products-dialog-container {
    .booth-dialog {
        .booth-dialog-content .content-container {
            padding: 16px;
        }

        &_section {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            width: 600px;
            max-width: 100%;
            max-height: 100%;
            height: 290px;
            // height: 100%;
            &.-with-image {
                align-items: center;
                background-size: cover;
                background-position: center;
                overflow: hidden;
                // width: 300px;
            }
            &.-text-section {
                justify-content: space-between;
            }
            &:nth-of-type(2) {
                padding-left: 10px;
            }
        }
        &_image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            // padding-bottom: 100%;
            // background-size: cover;
            // background-position: center;
        }
        &_navigation-wrapper {
            display: flex;
            justify-content: flex-end;
        }
        &_navigation {
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-width: 282px;
            width: 100%;
            // align-self: flex-end;
        }
        &_title {
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
            color: #000000;
            margin-bottom: 15px;
        }
        &_text {
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: -0.04em;
            color: rgba(0, 0, 0, 0.87);
        }
        &_pagination {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 95.9%;
            text-align: right;
            color: #2b2b2b;
            letter-spacing: 1px;
        }
        &_pagination-wrapper {
            display: flex;
            align-items: center;
            padding: 6px 0px;
            width: 70px;
        }
        &_button {
            padding: 8px 12px;
            border-radius: 6px;
            transition: all 0.3s ease-out;
            font-weight: bold;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            text-align: right;
            letter-spacing: 0.0125em;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            text-align: right;
            letter-spacing: 0.0125em;
            text-transform: uppercase;
            color: var(--theme-secondary-color);
            &:hover {
                color: #2b2b2b;
                background: var(--theme-hover-color);
            }
        }
    }
}

.link-icon {
    width: 24px;
    margin-right: 8px;
    &--disabled {
        path {
            fill: var(--theme-text-primary-color-light-grey) !important;
        }
    }
    &--custom {
        width: 16px;
    }
}
