.matching-dialog-empty-page {
    height: 100%;
    width: 100%;
    &__icon {
        margin-bottom: 16px;
        width: 66px;
        height: 66px;
    }
    &__info {
        width: 323px;
        border-radius: 8px;
        border: 1px dashed var(--theme-disabled-color);
        box-sizing: border-box;
        border-radius: 8px;
    }
    &__info-text {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0em;
        margin: 0;
        padding: 20px 12px;
        text-align: center;
        color: var(--theme-second-grey-color);
    }
    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
    }
}
