.upload-image {
    width: 100%;
    height: 100%;

    &__btn-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 10px;
        margin-bottom: 7px;

        &__upload-btn {
            width: 192px;
            height: 40px;
            display: flex;
            flex-direction: row;
            align-items: center;

            &__file {
                display: none;
            }

            &__icon {
                padding: 7px 7px 10px 0;
            }

            &__text {
                color: var(--theme-primary-color);
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                letter-spacing: 0.0125em;
                text-transform: uppercase;
            }
        }

        &__remove-btn {
            width: 131px;
            height: 40px;
            border: none !important;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            letter-spacing: 0.0125em;
            text-transform: uppercase;
            color: var(--theme-text-primary-color);

            &__icon {
                width: 20px;
                height: 20px;
            }
        }
    }

    &__preview {
        width: 100%;
        height: 284px;
        background: var(--theme-background-default-color);
        border: 1px solid var(--theme-border-grey-color);
        box-sizing: border-box;
        border-radius: 8px;
        overflow: hidden;

        &__img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        &__previewEmpty {
            width: 100%;
            height: 100%;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background: var(--theme-light-grey-color);

            &__text {
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.0125em;
                text-transform: uppercase;
                color: var(--theme-second-grey-color);
            }

            &__arrow-icon {
                position: absolute;
                height: 80px;
                width: 80px;
                left: 10%;
                top: 10%;
                z-index: 2;

                path {
                    stroke: var(--theme-text-grey-color);
                }
            }
        }

        &__error {
            margin: 15px 0;
        }
    }
}
