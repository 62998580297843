.permissions-module {
    margin-top: 20px;
    display: flex;
    flex-grow: 1;

    &.withTopMargin {
        margin-top: 40px;
    }

    .module-container {
        flex-grow: 1;
    }

    .tab {
        font-weight: bold;
        font-size: 16px;
        line-height: 1.2;
        display: flex;
        align-items: center;
        letter-spacing: 0.0125em;
        text-transform: uppercase;
        cursor: pointer;
        margin: 0;
        height: 100%;
        padding: 0 35px;
        position: relative;
        color: var(--theme-text-light-grey-color);
        transition: all 0.3s ease-out;
        &:after {
            transition: all 0.3s ease-out;
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            color: inherit;
            background-color: currentColor;
            opacity: 0;
        }
        &.-active {
            color: var(--theme-secondary-color);
            &:after {
                opacity: 1;
            }
        }
    }
    .module-body {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
    .module-header {
        justify-content: center;
    }
    .module-row {
        display: flex;
        align-items: center;
        padding: 10px;
        justify-content: space-between;
        font-weight: 500;
        font-size: 12px;
        line-height: 1.2;
        overflow: hidden;
        letter-spacing: -0.4px;
        color: var(--theme-text-grey-secondary-color);
        &.-head {
            background-color: var(--theme-background-default-color);
            border-bottom: 1px solid var(--theme-border-grey-color);
            text-transform: uppercase;
            padding: 15px;
        }
        &:not(:last-child) {
            border-bottom: 1px solid var(--theme-border-grey-color);
        }
        .avatar-name {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: pre;
            max-width: 150px;
        }
        .avatar {
            border-radius: 50%;
            position: relative;
            width: 40px;
            min-width: 40px;
            height: 40px;
            color: var(--theme-white-color);
            font-weight: 600;
            font-size: 16px;
            text-transform: uppercase;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            background-color: var(--theme-secondary-color);
        }
    }
    .module-action_column {
        width: 92px;
        min-width: 92px;
        text-align: center;

        .kick-user-icon {
            cursor: pointer;
        }
    }
    .d-flex {
        align-items: center;
    }
}
