.MobileBusinessCardMenu {
    flex: 1 1 auto;
    position: relative;
    height: 100%;
}

.MobileBusinessCardMenu__linkList {
}

.MobileBusinessCardMenu__linkItem {
    border-bottom: 1px solid #cacbd0;
}

.MobileBusinessCardMenu__link {
    display: inline-flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 16px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 700;
    color: #000;
    text-transform: uppercase;
    text-decoration: none;
}

.MobileBusinessCardMenu__linkTitle {
    flex: 1 1 auto;
    margin-right: 12px;
}

.MobileBusinessCardMenu__linkIcon {
    flex: 0 0 auto;
}

.MobileBusinessCardMenu__message {
    padding: 16px;
    font-size: 12px;
    font-weight: 500;
    color: #898989;
}

.MobileBusinessCardMenu__button {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 16px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    color: #d62e2f;
    text-align: right;
}
