.fullscreen .booth-video-dialog-container .booth-video-container {
    width: 100%;
    height: calc(100vh - 125px);
    padding: 0;
}

.booth-video-dialog-container #form-dialog-title {
    padding-top: 0;
}

.booth-action-button {
    font-family: inherit;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: var(--theme-primary-color);
}

.change-password-form > div {
    margin-bottom: 20px;
}
.change-password-form label {
    font-family: inherit;
}
