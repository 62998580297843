.ProgramDropdownList {
    height: 320px;
    margin-bottom: 12px;

    &_notLargeScreen {
        height: auto;
        margin: 0;
        overflow: auto;
    }
}

.ProgramDropdownList__element {
    padding-right: 16px;
}

.ProgramDropdownList_notLargeScreen .ProgramDropdownList__element {
    padding-right: 0;
}

.ProgramDropdownList__item {
    border-bottom: 1px solid #cacbd0;

    &:last-child {
        border-bottom: none;
    }
}
