.my-account-component.dropdown-component {
    max-width: 323px;
    overflow: initial;
}
.my-account-component.dropdown-component a {
    cursor: pointer;
}
.my-account-component .myaccount-container {
    padding: 20px 10px;
    background-color: var(--theme-white-color);
}
.my-account-component.dropdown-component .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ccc;

    &__link {
        display: block;
    }
}
.my-account-component.dropdown-component .row a {
    color: var(--theme-secondary-color);
    padding-top: 5px;
    font-weight: 600;
}
.my-account-component.dropdown-component .row button {
    padding: 0;
}
.my-account-component.dropdown-component .row span {
    cursor: pointer;
}

.my-account-component.dropdown-component .row:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
}
.my-account-component.dropdown-component .row > div:last-of-type {
    flex: 1;
    text-align: left;
}
.my-account-component .myaccount-container {
    &.reversed {
        .row > div {
            text-align: right;
            margin-left: 0;
        }

        .profile-image-container {
            img {
                margin: 0 0 0 30px;
            }
        }
    }
}

/* My account dropdown - mobile */
.my-account-component.dropdown-component.my-account-mobile {
    max-width: initial;
    width: 100%;
    top: 56px;
    filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2));
}
