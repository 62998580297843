.exhibitors-overlay {
    $transTime: 0.8s;
    position: absolute;
    bottom: 0;
    background-color: var(--theme-white-color);
    border-radius: 10px 10px 0 0;
    z-index: 100;
    box-shadow: 0px 0px 24px rgba(32, 15, 54, 0.25);
    height: 0;
    transition: height $transTime ease-in-out;
    margin: 0;
    width: calc(100% - 210px);
    transform: translateX(-50%);
    left: 50%;

    &.show {
        height: calc(100vh - 193px);
        transition: height $transTime ease-in-out;

        .exhibitors-container .close {
            opacity: 1;
        }
    }

    .title {
        margin: 0;
        color: var(--theme-primary-color);
        padding: 16px 0;
        text-transform: uppercase;
    }

    .exhibitors-list-container {
        height: calc(100% - 79px);
        padding-bottom: 30px;

        .scrollbar > div:nth-child(3) {
            right: 2px !important;
            left: initial;
        }
    }

    .exhibitors-list {
        display: flex;
        flex-wrap: wrap;
        margin: auto;
        padding-top: 2px;

        .single-exhibitor {
            width: 20%;
            cursor: pointer;
            text-align: center;
            transition: box-shadow 0.3s ease-out;
            border-radius: 8px;
            margin: 7px;
            overflow: hidden;
            width: calc(20% - 14px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            white-space: nowrap;
            text-overflow: ellipsis;

            .logo-container {
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 110px;
                max-width: 190px;
                margin: auto;
                padding: 15px;
                margin-bottom: 16px;
                box-sizing: border-box;

                img {
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                    display: block;
                }
            }

            &.active {
                box-shadow: 0 0 0 2px var(--theme-primary-color) !important;
            }

            &:hover {
                box-shadow: 0px 14px 24px rgba(32, 15, 54, 0.25);
            }
        }
    }

    .exhibitors-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 10px 0 10px;

        &__filter {
            width: 280px;
        }
    }

    .exhibitors-container {
        position: relative;
        margin: auto;
        height: 100%;
        border-radius: 10px 10px 0 0;

        .close {
            position: absolute;
            right: 10px;
            bottom: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--theme-primary-color);
            border-radius: 8px 8px 0 0;
            cursor: pointer;
            box-shadow: 0 1px 6px rgba(0, 0, 0, 0.13);
            width: 36px;
            height: 39px;
            opacity: 0;
            transition: all 0.6s ease-in;

            svg {
                transform: rotate(-90deg);

                path {
                    fill: var(--theme-white-color);
                }
            }
        }
    }
}

@media all and (max-height: 700px) {
    .exhibitors-overlay {
        .exhibitors-list .single-exhibitor {
            width: 25%;

            .logo-container {
                padding: 10px;
                width: 100px;
                height: 70px;
            }
        }
    }
}
