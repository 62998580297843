.UserPreviewPlate {
    padding: 8px 16px;
    text-align: left;
    position: relative;

    &__overlay {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 4;
    }

    --statuc-indicator-color: var(--user-offline-mark-color);

    &_satus {
        &-online {
            --statuc-indicator-color: var(--user-online-mark-color);
        }

        &-busy {
            --statuc-indicator-color: var(--user-busy-mark-color);
        }
    }

    &__contentRow {
        display: flex;
    }

    &__interactionItem {
        width: 86px;
        margin-right: 8px;

        button {
            width: 100%;
        }

        &:last-child {
            margin-right: 0;
        }
    }
    &__matching-row {
        display: flex;
        margin-top: 8px;
        width: 50px;
        height: 25px;
    }
    &__matching-row-title {
        font-style: normal;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 14px;
        line-height: 95.9%;
        text-align: center !important;
        margin: 0;
        color: var(--theme-secondary-color);

        &--high {
            color: var(--theme-primary-color);
        }
    }

    &__mediaCol {
        position: relative;
        flex: 0 0 48px;
        height: 48px;
        margin-right: 12px;

        &::before {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            width: 12px;
            height: 12px;
            background-color: var(--statuc-indicator-color);
            border: 2px solid var(--theme-white-color);
            border-radius: 50%;
            box-shadow: 0 1px 6px rgba(0, 0, 0, 0.13);
        }

        &.isRtl {
            margin: 0 0 0 12px;
        }
    }

    &__mediaCol.online {
        &::before {
            background-color: var(--user-online-mark-color);
        }
    }

    &__socialList {
        flex: 1 1 auto;
        display: flex;
        margin-right: 5px;
    }

    &__socialItem {
        flex: 0 0 auto;
        margin-right: 12px;

        &:last-child {
            margin-right: 0;
        }
    }

    &__mediaHolder {
        height: 100%;
        border-radius: 50%;
        overflow: hidden;
    }

    &__infoCol {
        flex: 1 1 auto;
        overflow: hidden;
    }

    &__fullName {
        font-size: 16px;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__designation {
        font-size: 12px;
        font-weight: 500;
        color: #777081;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__chatCol {
        flex: 0 0 auto;
        align-self: center;
        margin-left: 4px;
    }

    &__actionsRow {
        display: flex;
        align-items: center;
    }

    &__interactionGroup {
        display: flex;
        align-items: center;
    }

    &.isRtl {
        direction: rtl;
        text-align: right;

        .UserPreviewPlate__interactionItem {
            margin-right: 8px;

            &:first-child {
                margin-right: 0;
            }
        }

        .UserPreviewPlate__socialItem {
            margin-right: 12px;

            &:first-child {
                margin-right: 0;
            }
        }
    }
}
