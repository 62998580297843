.program-arrow {
    &__arrow {
        position: absolute;
        z-index: 2;
        cursor: pointer;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        height: 40px;
        justify-content: center;
        padding: 0 10px;
        &--next {
            right: 0;
            top: 0;
        }
    }

    &__icon {
        width: 10px;
        height: auto;
        &--next {
            transform: rotate(180deg);
        }
    }
}

@media screen and (max-width: 700px) {
    .program-arrow {
        &__arrow {
            height: 60px;
        }
        &__date {
            font-size: 16px;
        }
    }
}
