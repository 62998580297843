.program-dropdown-day {
    cursor: pointer;
    &__date-wrapper {
        display: flex;
        flex-flow: column;
        text-transform: uppercase;
        color: var(--theme-white-color);
        font-weight: 100;
        text-align: center !important;
        &--bold {
            font-weight: 800;
        }
        & span {
            padding: 0 10px;
        }
    }
    &__date {
        font-size: 18px;
        line-height: 22px;
        .isRtl {
            direction: rtl;
        }
    }

    &__week-day {
        font-size: 12px;
        line-height: 11px;
        color: var(--theme-white-color);
    }
}

@media screen and (max-width: 700px) {
    .program-dropdown-day {
        &__date {
            font-size: 16px;
        }
        height: 50px;
    }
}
