.UserPreviewPlateSocial {
    display: inline-block;
    color: #898989;
    text-decoration: none;
    transition: opacity 0.3s ease-in-out;

    &:hover {
        opacity: 0.8;
    }

    &:active {
        opacity: 1;
    }
}
