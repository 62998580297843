$closeButtonWidth: 40px;

.wall-and-networking-container {
    height: calc(100vh - 70px);
    width: 363px - $closeButtonWidth;
    display: none;
    top: 0;
    position: relative;

    &.open {
        display: block;
        filter: alpha(opacity=100);
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);

        .close-dropdown {
            left: -$closeButtonWidth;
        }
    }

    .tabs-container {
        margin-left: auto;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
        position: relative;
        z-index: 2;
        background-color: var(--theme-white-color);
        width: 100%;
        min-height: 44px;
        display: flex;
        align-items: stretch;
    }

    & > div {
        position: relative;
        height: 100%;
    }

    .tab {
        font-family: inherit;
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 22px;
        color: var(--theme-text-primary-color);
        width: 50%;
    }

    .tab.networking-tab span:first-child > span {
        padding: 0;
    }

    .live-wall-tab,
    .networking-tab {
        &.active {
            color: var(--theme-primary-color);
            font-weight: 800;
            font-size: 18px;
            line-height: 22px;
            border-bottom: 2px solid var(--theme-primary-color);
        }
    }
}

.side-menu-container {
    position: absolute;
    display: flex;
    right: 0;
    top: 0;
    z-index: 200;
    height: calc(100vh - 70px);

    .secondary-background {
        background-color: var(--theme-secondary-color);
        color: var(--theme-white-color);
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;

        &:hover {
            background-color: var(--theme-secondary-color);
            opacity: 0.7;
        }
    }

    .primary-background {
        background-color: var(--theme-primary-color);
        color: var(--theme-white-color);
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;

        &:hover {
            background-color: var(--theme-primary-color);
            opacity: 0.7;
        }
    }

    .event-actions-container.swipeable-drawer {
        padding-top: 400px;
        max-width: 5px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        @media screen and (max-height: 750px) {
            padding-top: 300px;
        }

        .buttons-wrapper {
            transform: rotate(-90deg) translate(-100%);
            transform-origin: right bottom;
            padding: 0;
            display: flex;
        }
    }
}

.side-menu-container.close .event-actions-container.swipeable-drawer {
    max-width: 38px;
}

.event-actions-container.swipeable-drawer .side-menu-button {
    &--disabled {
        color: gray;
        background-color: var(--theme-background-default-color);
    }
}

.close-dropdown,
.close-component {
    position: absolute;
    width: 40px;
    height: 40px;
    background: var(--theme-text-secondary-color);
    border-radius: 8px 0 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    right: 0;
}

.scrollbar > div:nth-child(3) {
    width: 4px !important;
}

.grey-scroll {
    border-radius: 10px;
}

.custom-scroll-container {
    padding-top: 3px;
}

.wall {
    padding-top: 10px;
}

.wall-container .custom-scroll-container {
    height: calc(100vh - 192px);
    position: relative;
}

.scrollbar .box {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 100%;
    overflow-x: hidden !important;
}

.lang-rtl .scrollbar .box.isMacOs {
    margin-right: 0 !important;
}

.button-tab {
    &.tab {
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        width: 50%;
        text-align: center;
        padding: 10px 0;
        cursor: pointer;
    }

    &.networking-tab .restricted-side-tab {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .disabled {
        color: gray;
        background-color: var(--theme-background-default-color);
    }
}

.booth-wall-container {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 101;
    height: 100vh;
    transition: width 1s ease-in;
    visibility: visible;
    opacity: 1;
    filter: alpha(opacity=100);
    width: 25%;
    max-width: 323px;
    display: flex;
    flex-flow: column;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);

    .close-booth-wall {
        position: absolute;
        width: 40px;
        height: 40px;
        background: var(--theme-text-secondary-color);
        border-radius: 8px 0 0 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        right: 100%;
    }
}
.booth-wall-container.close {
    transition: width 1s ease-in;
    right: -323px;
}

.booth-wall-container .booth-wall-header {
    margin-left: auto;
    background-color: var(--theme-white-color);
    width: 100%;
    min-height: 48px;
    display: flex;
    align-items: center;
    color: var(--theme-secondary-color);
    font-weight: 800;
    font-family: inherit;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    &-side_buttons_group {
        transform: rotate(-90deg) translateZ(0);
        transform-origin: 100% 100%;
        display: flex;
        white-space: nowrap;
        flex-flow: row-reverse;
        position: absolute;
        top: 0;
        right: 100%;
    }

    &-side_button {
        .button-text {
            color: var(--theme-white-color);
            -webkit-padding-end: 15px;
            padding-inline-end: 15px;
            font-size: 16px;
            line-height: 20px;
            overflow: hidden;
            white-space: nowrap;
            font-weight: bold;
            width: auto;
        }
    }
}

.booth-wall-container .booth-wall-header span.walls-tab {
    border-bottom-width: 0;
    width: 50%;
    display: block;
    text-align: center;
    display: flex;
    align-items: center;
    letter-spacing: 0.0125em;
    font-size: 16px;
    height: 100%;
    justify-content: center;
    line-height: 20px;
    text-transform: uppercase;
    position: relative;
    transition: color 0.2s ease-out;
    color: #2b2b2b;
    cursor: pointer;

    &:after {
        content: '';
        width: 100%;
        height: 2px;
        position: absolute;
        left: 0;
        transition: opacity 0.2s ease-out;
        bottom: 0;
        opacity: 0;
        background-color: var(--theme-primary-color);
    }
}

.booth-wall-container .booth-wall-header span.walls-tab.-is-active {
    color: var(--theme-primary-color);

    &:after {
        opacity: 1;
    }
}

@media all and (max-width: 1440px) {
    .wall-and-networking-container .tab {
        font-size: 15px !important;
    }
}

@media all and (max-height: 859px) {
    .booth-wall-container {
        width: 22%;
    }

    .showfloor-page .booth {
        min-width: 78% !important;
    }
}

@media screen and (max-width: 1024px) {
    .booth-wall-container {
        flex: 1;
        height: auto;
        position: relative;
    }

    .booth-wall-container .booth-wall-header {
        display: none;
    }

    .wall-and-networking-container,
    .booth-wall-container {
        width: 100% !important;
        max-width: initial !important;
        position: relative !important;
    }

    .wall-and-networking-container {
        height: calc(calc(var(--vh, 1vh) * 100) - 112px) !important;

        & > div {
            padding: 0;
        }

        .tabs-container {
            background-color: var(--theme-background-paper-color);
            min-height: initial;
            height: 48px;
            display: flex;
            box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
        }

        .tab {
            font-size: 14px;
            font-weight: 600;
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }

        .live-wall-tab,
        .networking-tab,
        .info-tab {
            &.active {
                font-weight: 600;
                font-size: 14px;
                color: var(--theme-secondary-color);
                border-bottom: 2px solid var(--theme-secondary-color);
            }
        }
    }

    .search-user-container {
        background-color: var(--theme-background-default-color);
    }

    .networking-container .custom-scroll-container {
        height: calc(calc(var(--vh, 1vh) * 100) - 199px);
    }

    .wall-container {
        position: relative;

        .custom-scroll-container {
            height: calc(calc(var(--vh, 1vh) * 100) - 199px);
        }
    }

    .booth-wall-container .wall-container .custom-scroll-container {
        height: calc(calc(var(--vh, 1vh) * 100) - 239px);
    }

    .showfloor-page .booth-wall-container .wall-container .custom-scroll-container {
        height: calc(calc(var(--vh, 1vh) * 100) - 247px);
    }

    .participant-avatar {
        width: 40px;
        height: 40px;
        flex: 0 0 40px !important;
    }

    .user-container .user-name p:last-child {
        font-size: 12px;
        margin-bottom: 25px;
        line-height: initial;
    }

    .user-details {
        .social-button {
            margin-right: 24px;

            &:last-of-type {
                margin-right: 0;
            }

            & > div img {
                width: 24px;
                height: 24px;
            }
        }
    }
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
    .wall-and-networking-container .tabs-container,
    .agenda-page .tabs-container,
    .auditorium-page .tabs-container {
        justify-content: center;
    }

    .button-tab.tab {
        width: auto;
        padding-left: 16px;
        padding-right: 16px;
    }

    .wall-container .sort-container,
    .wall-container > div:last-of-type {
        max-width: 600px;
        margin: 0 auto;
    }

    .add-new-post-container {
        max-width: 610px;
    }
}
