.resources-container {
    font-size: 14px;
    background-color: var(--theme-background-default-color);
    flex: 1 1;
    padding: 8px 0;
    text-align: left;
    .title {
        margin: 0;
        color: #2b2b2b;
        font-size: 16px;
        line-height: 20px;
        padding: 0 16px;
        white-space: pre-line;
        color: var(--theme-primary-color);
        font-weight: 600;
        text-transform: uppercase;
        padding: 24px 16px 8px;
    }
    .resources-list {
        list-style-type: none;
        margin: 0;
        position: relative;
        padding: 0;
        font-size: 16px;
        line-height: 20px;
        width: 100%;
    }
    .resources-list-item {
        padding: 0;
        display: flex;
        align-items: center;
        .link {
            // box-shadow: 0 1px 10px rgba(0, 0, 0, 0.08), 0 2px 4px rgba(0, 0, 0, 0.2);
            border-bottom: 1px solid var(--theme-primary-color);
            color: var(--theme-primary-color);
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 20px 16px;
            border-bottom: 1px solid #cacbd0;
            word-break: break-all;
            span {
                color: #2b2b2b;
                padding-right: 16px;
            }
            .icon {
                color: var(--theme-primary-color);
            }
        }
    }
    .poll {
        &.-vote-availible .poll_option {
            cursor: pointer;
        }
        &:not(:last-child) {
            margin-bottom: 15px;
        }
        &_header {
            padding: 16px;
        }

        &_title {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: var(--theme-primary-color);
            margin: 0 0 12px;
        }
        &_status {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.4px;
            display: flex;
            align-items: center;
            &-icon {
                margin-right: 5px;
            }
        }
        &_button {
            background: var(--theme-primary-color);
            margin: 5px auto;
            width: calc(100% - 10px);
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.0125em;
            text-transform: uppercase;
            font-weight: bold;
            color: var(--theme-white-color);
            height: 44px;
            border-radius: 8px 8px 8px 8px;
        }
        &_option {
            padding: 4px 16px;
            border-bottom: 1px solid #cacbd0;
            min-height: 60px;
            position: relative;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: center;
            &.-is-checked {
                background: var(--theme-hover-color);
                @at-root & .poll_option-title {
                    color: var(--theme-secondary-color);
                }
                @at-root & .poll_option-progress {
                    background-color: var(--theme-primary-color);
                }
            }
            &:hover {
                .poll_option-title {
                    color: var(--theme-secondary-color);
                }
            }
            &-title {
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                transition: color 0.3s ease-out;
                color: #2b2b2b;
                margin: 0;
                padding-right: 10px;
            }
            &-text {
                margin: 0;
            }
            &-icon {
                &.-colored {
                    color: var(--theme-secondary-color);
                }
            }
            &-container {
                overflow: hidden;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: -0.4px;
            }
            &-progress-wrapper {
                width: 100%;
                height: 6px;
                margin-top: 6px;
                border-radius: 6px;
                overflow: hidden;
            }
            &-progress {
                border-radius: 6px;
                width: 100%;
                height: 100%;
                background-color: var(--theme-secondary-color);
                transition: transform 0.3s ease-out;
                transform: translateX(-100%);
            }
        }
    }
}
