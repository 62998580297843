.platform-not-available {
    width: 100vw;
    height: 100vh;
    font-size: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    &__text-container {
        width: 300px;
        height: 300px;
        background-color: rgb(255, 255, 255);
        font-size: 24px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        padding: 15px;
        border-radius: 20px;

        span {
            display: block;
            margin-bottom: 15px;
        }
    }
}
