@import './src/styles/mixins.scss';

.exhibitor-representatives {
    &__participant-avatar {
        $sz: 40px;
        width: $sz;
        min-width: $sz;
        height: $sz;
        border-radius: 50%;
        margin-right: 8px;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            width: 12px;
            height: 12px;
            background-color: #898989;
            border: 2px solid var(--theme-white-color);
            border-radius: 50%;
            box-shadow: 0 1px 6px rgba(0, 0, 0, 0.13);
        }
    }
    &__user-container {
        display: flex;
        align-items: center;
        padding: 10px;
    }

    &__user-details {
        display: flex;
        justify-content: space-between;

        &:not(:last-child) {
            margin-bottom: 17px;
        }

        .connection {
            display: flex;
            align-items: center;
        }

        .user-name {
            max-width: 80%;
        }
    }

    &__user-wrapper-separator {
        color: var(--theme-text-primary-color);
        letter-spacing: 0.5px;

        &::after {
            position: absolute;
            left: 0;
            content: ' ';
            display: block;
            border-bottom: 1px solid rgba(137, 137, 137, 0.5);
            width: 100%;
        }
    }
}

@media screen and (max-width: 1024px) {
    .exhibitor-representatives {
        li {
            background-color: var(--theme-background-paper-color);
        }
        margin: 0;
        list-style-type: none;
        padding: 10px 0 0;
    }
    .exhibitor-representatives .user-details > div:last-of-type {
        min-height: initial;
    }
    .exhibitor-representatives .participant-button.open-chat {
        top: 2px;
    }
}
