.organizer-dashboard-page {
    background-color: var(--theme-background-default-color);
    padding: 116px 32px 0;
    color: #2b2b2b;
    min-height: 100vh;
}
.organizer-dashboard-page.has-side-nav {
    padding-left: 0;
}
.organizer-dashboard-page form {
    max-width: initial;
    width: 100%;
    padding: 0;
    margin: 0;
}

.organizer-dashboard-page .advanced-options-title {
    margin: 16px 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.rightAligned {
        justify-content: flex-end;
    }
    &__options-container {
        display: flex;
    }
    &__options-container-item {
        display: flex;
        margin-right: 10px;
    }
}
.organizer-dashboard-page .advanced-options-container {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 6px !important;
    background-color: var(--theme-white-color);
    margin-bottom: 24px;
    padding: 24px 16px 16px;

    .options-container {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        &--column {
            align-items: start;
        }
    }
}
.organizer-dashboard-page .advanced-options-title,
.organizer-dashboard-page .advanced-options-container,
.organizer-dashboard-page .actions-container {
    max-width: 1112px;
}
.options-container {
    &.block {
        display: block;
    }
}

.organizer-dashboard-page .advanced-options-container .options-container .single-option-container {
    flex: 0 0 520px;

    &.fluid {
        flex: 1 1;
        flex-grow: 1;
    }
}
.single-option-container__description {
    display: inline-block;
}

.organizer-dashboard-page .advanced-options-container .options-container .single-option-container .setting-input {
    margin: 0;
}
.organizer-dashboard-page .advanced-options-container .options-container .single-option-container .setting-input label {
    background-color: var(--theme-white-color);
    padding: 0 5px 0 3px;
}
.organizer-dashboard-page .advanced-options-container .options-container .input-description {
    font-size: 16px;
    line-height: 24px;
    color: #777081;
    padding-left: 25px;
    width: 100%;
}
.organizer-dashboard-page .advanced-options-container .options-container .input-description p {
    margin: 0;
}

.organizer-dashboard-page .advanced-options-container .options-container .single-option-container.event-dates {
    display: flex;
    justify-content: space-between;
}
.organizer-dashboard-page
    .advanced-options-container
    .options-container
    .single-option-container.event-dates
    .date-input {
    width: calc(50% - 12.5px);
}
.organizer-dashboard-page .advanced-options-container .options-container.event-url input {
    color: var(--theme-secondary-color);
}
.organizer-dashboard-page .advanced-options-container .options-container.event-url p {
    cursor: pointer;
    color: #2b2b2b;
    display: flex;
    align-items: center;
}
.organizer-dashboard-page .advanced-options-container .options-container.event-url p span {
    padding-left: 10px;
}
.organizer-dashboard-page .advanced-options-container .inner-options-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #777081;
    margin: 0;
}

.organizer-dashboard-page .advanced-options-container .options-container .single-option-container.switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 11px 0 30px;
    position: relative;
}

/* Setup page*/

.dashboard-with-side-nav {
    display: flex;

    .side-navigation {
        flex: 0 0 256px;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        min-height: calc(100vh - 116px);
        border-right: 1px solid #cacbd0;

        p {
            margin: 0;
            padding-left: 12px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &.disabled .label {
                opacity: 0.5;
            }
        }

        .select-language-container {
            padding: 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .MuiSelect-outlined {
                padding: 8px 32px 8px 8px;
                font-size: 14px;
            }

            span {
                padding-right: 10px;
            }
        }

        .accordion {
            background-color: var(--theme-background-default-color);

            &.active {
                background-color: var(--theme-white-color);
                margin: 0;

                .summary {
                    border-left: 4px solid var(--theme-primary-color);
                }

                p {
                    color: var(--theme-primary-color);
                    margin-left: -4px;
                }

                svg path {
                    fill: var(--theme-primary-color);
                }

                .add-new-button svg path {
                    fill: #2b2b2b;
                }
            }
        }
    }
}

.aside-dashboard-container {
    flex: 1;
    margin: 0 0 34px 24px;
    position: relative;
    background-color: var(--theme-background-default-color);

    .exhibitors-container {
        .page-bottom-boundary {
            margin-bottom: 100px;
            p {
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
            }
        }
    }

    .list-wrapper h3 {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .participants-dashboard .search-container {
        width: 50%;
        background-color: var(--theme-white-color);
    }

    .participants-list-wrapper {
        background-color: var(--theme-white-color);
    }
}
.organizer-dashboard-page ul.subtabs {
    list-style: none;
    padding: 0 0 0 32px;
    margin: 0;
}
.organizer-dashboard-page ul.subtabs.accessManagement {
    width: 100%;
}
.organizer-dashboard-page ul.subtabs li {
    padding: 16px 0;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.organizer-dashboard-page ul.subtabs li.active {
    color: var(--theme-primary-color);
}
.organizer-dashboard-page li .bullet {
    flex: 0 0 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--theme-primary-color);
    margin-right: 8px;
}

.organizer-dashboard-page .branding-homepage-description form {
    margin-top: 30px;
}

.branding-background-image-container {
    .options-container {
        align-items: flex-start;

        .sublabel {
            display: block;
            margin-top: 20px;
        }

        .input-description {
            padding-top: 60px;
        }
    }

    .actions-container {
        margin-top: 20px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .cover-img-container {
        max-width: 100%;
        height: 280px;

        & > img {
            width: 100% !important;
            height: 100% !important;
            object-fit: cover !important;
            border-radius: 8px;
        }
    }

    .image-status-container {
        margin-top: 20px !important;
        color: #2b2b2b;
    }

    .uploaded-file .file-name {
        color: var(--theme-secondary-color);
    }
}

.cover-img-container {
    max-width: 297px;
    height: 196px;
    position: relative;
    background-color: #eee;
    border-radius: 8px;
    border: 0.5px solid var(--theme-text-secondary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 40px;

    & > img:not(.upload-icon) {
        width: 70%;
        height: 90%;
        object-fit: contain;
        object-position: center;
    }

    .spinner {
        left: -0.5px;
        bottom: -0.5px;
    }

    .edit-label {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }

    .upload-label {
        cursor: pointer;
    }

    span {
        display: block;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        color: var(--theme-secondary-color);
        padding-top: 5px;
    }
}

.organizer-dashboard-page .actions-container {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 50px;
}

.organizer-dashboard-page .program-entry {
    padding: 0;
    margin: 0;
    border: none;
    width: 100%;
}

.timeslot-accordion {
    padding: 18px 16px !important;

    .summary {
        padding: 0;
    }
}

.organizer-dashboard-page .timeslot-wrapper {
    flex: 1;
}
.organizer-dashboard-page .advanced-options-container .speakers-container .inner-options-title {
    padding: 50px 0 30px 0;
}
.organizer-dashboard-page button.remove-speaker {
    display: flex;
    align-items: center;
    color: #2b2b2b !important;
    text-transform: uppercase;
}
.organizer-dashboard-page.statistics-dashboard .statistics {
    padding-bottom: 24px;
    position: relative;
    box-shadow: none;
    background: transparent;
}
.organizer-dashboard-page .statistics .statistics-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    margin: 0 0 16px 0;
    text-transform: capitalize;
}
.organizer-dashboard-page .statistics .statistics-part {
    display: flex;
    justify-content: space-between;
}
.statistics-part .statistics-table {
    flex: 0 0 50%;
    padding: 0 12px;
}
.statistics-part .statistics-table .statistics-interactive-column-wrapper {
    display: flex;
    align-items: center;
}
.statistics-part .statistics-table .statistics-interactive-column-item {
    width: 100px;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.statistics-part .statistics-table .hidden {
    width: 0;
}
.statistics-part .statistics-table .statistics-interactive-column-item-name {
    display: flex;
    align-items: center;
    text-transform: capitalize;
}
.statistics-part .statistics-table .statistics-interactive-column-item-name .text-tip {
    font-weight: bold;
    font-size: 14px;
    color: var(--theme-primary-color);
    margin-left: 12px;
}
.statistics-part {
    .statistics-table {
        ul {
            background-color: var(--theme-white-color);
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
            border-radius: 6px;
            list-style: none;
        }

        .statistics-interactive-column-item .statistics-interactive-column-item__empty {
            width: 100%;
        }
    }

    span.value-counter {
        font-weight: 600;
        padding-right: 0;
        width: 80px;
        text-align: right;
    }
}
.organizer-dashboard-page .statistics .statistics-part {
    .statistics-header {
        background-color: #efeef1;
        font-size: 12px;
        color: var(--theme-text-primary-color-light-grey);
        display: flex;
        align-items: center;
        justify-content: space-around;

        span {
            font-size: 12px;
        }
        .statistics_header-item {
            display: inline-block;
            margin-right: 16px;
            width: 100px;
            text-align: center;
        }
    }

    li {
        padding: 12px 16px;
    }
}
.organizer-dashboard-page .statistics .statistics-part .-clickable {
    cursor: pointer;
}

.organizer-dashboard-page .smallContainer {
    max-width: 100%;
    min-height: 100vh !important;
}
.organizer-dashboard-page .smallContainer[data-empty='false'] .program-section,
.organizer-dashboard-page .smallContainer[data-empty='true'] .program-section {
    padding-bottom: 30px;
    margin: 0;
}
.organizer-dashboard-page .advanced-options-container .action-container {
    margin-top: 20px;
    text-align: right;
}
.speakers-list ul li.speaker div > p {
    margin: 0;
}
.organizer-dashboard-page .statistics .navigation-button {
    padding: 7px;
    font-size: 0;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 10px;
    outline: none;
}
.organizer-dashboard-page .statistics .navigation-button.next {
    transform: rotate(180deg);
}
.organizer-dashboard-page .statistic-title {
    margin-top: 30px;
    padding-left: 15px;
}
.organizer-dashboard-page .statistics-part ul {
    width: 100%;
    margin: 0;
    padding-left: 0;
}
.participants-list-wrapper {
    height: 730px;
    position: relative;
    border-radius: 0 0 6px 6px;

    .single-participant-row {
        &:hover {
            background-color: #eee;
        }
        display: flex;
        font-size: 14px;
        align-items: center;
        border-bottom: 1px solid #cacbd0;
    }
}
.participants-dashboard .participants-list-header {
    display: flex;
    font-size: 14px;
    align-items: center;
    border-bottom: 1px solid #cacbd0;
}
.dashboard-wrapper.participants-dashboard {
    max-width: 1600px;
}
.participants-dashboard {
    .filters-list {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 10px;
        flex-flow: wrap;

        label {
            display: flex;
            align-items: center;

            input {
                margin-left: 0;
            }
        }
    }

    .single-participant-row {
        & > div {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 12px;
            line-height: 45px;
            font-weight: 400;
            margin: 0 10px;
            padding: 0;
            min-height: 19px;
            display: flex;
            align-items: center;

            &.delete {
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .actions-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 10px;
        flex-flow: wrap;
    }

    .switch-container {
        margin: 30px 0;
    }

    .search-container {
        width: 400px;
        margin-right: 30px;
    }
}
.participants-dashboard .participants-list-header {
    background-color: #efeef1;
    border-radius: 6px 6px 0 0;
}
.participants-list-header > div {
    margin: 0 10px;
    padding: 20px 0;
    text-transform: uppercase;
    font-size: 12px;
    color: #777081;
}
.participants-list-wrapper .single-participant-row .name,
.participants-dashboard .participants-list-header .name {
    width: 28%;
}
.participants-list-wrapper .single-participant-row .company,
.participants-dashboard .participants-list-header .company {
    width: 18%;
}
.participants-list-wrapper .single-participant-row .email,
.participants-dashboard .participants-list-header .email {
    width: 28%;
}
.participants-list-wrapper .single-participant-row .phone,
.participants-dashboard .participants-list-header .phone {
    width: 8%;
}
.participants-list-wrapper .single-participant-row .registration-date,
.participants-dashboard .participants-list-header .registration-date {
    width: 12%;
}
.participants-list-wrapper .single-participant-row .registration-type,
.participants-dashboard .participants-list-header .registration-type {
    width: 12%;
}
.participants-list-wrapper .single-participant-row .access-package,
.participants-dashboard .participants-list-header .access-package {
    width: 8%;
}
.participants-list-wrapper .single-participant-row .invitation-code,
.participants-dashboard .participants-list-header .invitation-code {
    width: 8%;
}
.participants-list-wrapper .single-participant-row .role,
.participants-dashboard .participants-list-header .role {
    width: 17%;
}
.participants-list-wrapper .single-participant-row .delete,
.participants-dashboard .participants-list-header .delete {
    width: 5%;
}
.participants-list-wrapper .single-participant-row .access-package .select-user-package {
    font-size: 12px;
}
.participants-list-wrapper .single-participant-row .access-package .MuiInput-underline:before,
.participants-list-wrapper .single-participant-row .access-package .MuiInput-underline:after {
    display: none !important;
}
.participants-list-wrapper .single-participant-row .access-package .MuiSelect-select:focus {
    background-color: transparent !important;
}
.snackbar div {
    margin-left: auto;
    margin-right: auto;
}
