.AccountLeftSideTabs {
    display: flex;
    height: 100%;
    padding-right: 24px;
}

@media (max-width: 960px) {
    .AccountLeftSideTabs {
        padding-right: 0;
    }
}

.AccountLeftSideTabs__switchGroup {
    flex: 0 0 240px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: 1px solid #cacbd0;
}

@media (max-width: 960px) {
    .AccountLeftSideTabs__switchGroup {
        flex: 0 0 200px;
    }
}

.AccountLeftSideTabs__switchList {
    flex: 0 0 auto;
}

.AccountLeftSideTabs__switchComment {
    flex: 0 0 auto;
    padding: 16px;
    padding-bottom: 24px;
    line-height: 1.5;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.4px;
    color: #898989;
}

.AccountLeftSideTabs__panel {
}

.AccountLeftSideTabs__button {
    padding-left: 32px !important;
    opacity: 1 !important;
    text-align: left !important;
}

@media (max-width: 960px) {
    .AccountLeftSideTabs__button {
        padding-left: 16px !important;
    }
}

.AccountLeftSideTabs__button span {
    align-items: flex-start;
    line-height: 1.4;
    font-size: 16px;
    font-weight: 700;
    color: #2b2b2b;
}

.AccountLeftSideTabs__button:disabled span {
    color: #999;
}

.AccountLeftSideTabs__content {
    flex: 1 1 auto;
    max-width: calc(100% - 240px);
    padding: 24px;
    box-sizing: border-box;
}

@media (max-width: 960px) {
    .AccountLeftSideTabs__content {
        max-width: calc(100% - 200px);
    }
}

.AccountLeftSideTabs__contentHeader {
    margin-bottom: 24px;
}
