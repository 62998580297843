.admin-menu-wrapper {
    width: 100%;
    height: 72px;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
}

.brand-logo {
    width: 72px;
}

.logout-button {
    color: var(--theme-text-grey-color);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
}
