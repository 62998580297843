.chat-details {
    flex: 1;

    .group-chat-name {
        padding-bottom: 5px;
        padding-top: 5px;
    }

    & > .last-message {
        margin: 0;
        font-size: 10px;
        color: var(--theme-text-secondary-color);
    }
}

.expanded .chat-details .group-chat-name,
.no-active-chat .chat-details .group-chat-name {
    padding-bottom: 0;
    padding-top: 8px;
}
