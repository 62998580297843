.Button {
    display: inline-block;
    margin: 0;
    padding: 10px;
    border: none;
    background-color: transparent;
    font: inherit;
    font-weight: 700;
    font-size: 16px;
    color: inherit;
    text-transform: uppercase;
    outline: none;
    cursor: pointer;
    appearance: none;
    transition: opacity 0.2s ease-in-out;
    border-radius: 4px;
    line-height: 1;
    &:hover {
        opacity: 0.8;
        background-color: var(--theme-hover-color);
    }

    &:active,
    &:focus {
        opacity: 1;
    }

    &_disabled {
        pointer-events: none;
        opacity: 0.6;
    }

    &_expanded {
        width: 100% !important;
        box-sizing: border-box !important;
    }
}

.Button__icon {
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
}

.Button_noTitle .Button__icon {
    margin-right: 0;
}

.Button__title {
    vertical-align: middle;

    &:last-child {
        margin-left: 8px;
    }
}
