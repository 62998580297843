.RegisterForTheEventLink {
    display: inline-block;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 10px 54px 10px 24px;
    background-color: var(--theme-secondary-color);
    color: var(--theme-white-color);
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    text-decoration: none;
    position: relative;

    div {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--theme-secondary-color);
        width: 46px;
        height: 46px;
        border-radius: 50%;
        border: 7px solid var(--theme-white-color);
        top: 50%;
        transform: translateY(-50%);
        right: -2px;
    }

    div > img {
        transform: rotate(180deg);
    }

    &.isRtl {
        padding: 10px 24px 10px 54px;
        div {
            left: -2px;
            right: auto;
        }
        div > img {
            transform: rotate(0deg);
        }
    }
}
