.events-list {
    padding: 0;
    margin: 0;

    &__item {
        font-size: 16px;
        font-weight: bold;
        color: #777081;
        border: 0;
        padding-bottom: 22px;
        margin: 0;

        &:last-of-type {
            padding: 0;
        }

        &--active {
            color: var(--theme-primary-color);
        }
    }
}
