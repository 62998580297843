.MobileBusinessCardBuilder {
    height: 100%;
}

.MobileBusinessCardBuilder__loader {
}

.MobileBusinessCardBuilder__error {
}

.MobileBusinessCardBuilder__creating {
    height: 100%;
    box-sizing: border-box;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.MobileBusinessCardBuilder__creatingButton {
    flex: 0 0 auto;
    margin-bottom: 32px;
    color: var(--theme-primary-color);
}

.MobileBusinessCardBuilder__creatingContent {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    color: #898989;
    text-align: center;
}

.MobileBusinessCardBuilder__creatingBoxIcon {
    margin-bottom: 48px;
}

.MobileBusinessCardBuilder__creatingMessage {
    margin-bottom: 4px;

    &:last-child {
        margin-bottom: 0;
    }
}
