.on-demand-tab {
    height: 100%;
    background-color: var(--theme-white-color);

    &__header {
        height: 48px;
        background-color: var(--theme-background-default-color);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 14px;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    }
    &__back-title {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.0125em;
        text-align: left;
        margin: 0;
        margin-left: 16px;
    }
    &__list {
        padding: 12px 24px;
        height: calc(calc(var(--vh, 1vh) * 100) - 165px);
        overflow-y: auto;
    }
    &__list-item {
        width: 100%;
        margin-bottom: 25px;
    }
}

.on-demand-tab-list-item {
    text-align: left;
    &__img {
        width: 100%;
        height: 155px;
        cursor: pointer;
        background: var(--theme-border-grey-color);
        border-radius: 4px;
        margin-bottom: 8px;
        min-height: 155px;
        min-width: 100%;
    }

    &__timeslot-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: var(--theme-text-primary-color);
        margin: 0;
        margin-bottom: 8px;
    }
    &__auditorium-title {
        font-weight: 500;
        font-size: 14px;
        font-size: 16px;
        color: var(--theme-text-primary-color);
        margin: 0;
        margin-bottom: 8px;
    }

    &__time {
        font-weight: 500;
        font-size: 14px;
        color: var(--theme-text-primary-color);
        margin: 0;
    }
}
