.video-module {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    background: #000;
    height: 550px;
    width: 600px;

    .video-conference-buttons {
        bottom: 48px;
    }

    .conference-buttons-container {
        cursor: pointer;
        background-color: var(--theme-primary-color);
        width: 100%;
        height: 48px;
        display: flex;
        justify-content: center;
        display: flex;
        align-items: center;

        button {
            font-family: 'Montserrat', sans-serif;
            background-color: transparent;
            border: 0;
            outline: 0;
            font-weight: bold;
            font-size: 16px;
            color: var(--theme-white-color);
            text-transform: uppercase;
            cursor: pointer;

            div {
                display: flex;
                align-items: center;
            }

            svg {
                width: 22px;
                height: 22px;
                margin-right: 8px;

                path {
                    fill: var(--theme-white-color) !important;
                }
            }
        }
    }

    &.-fullScreen {
        position: fixed;
        width: 100vw;
        height: 100vh;
        z-index: 1001;
        left: 0;
        top: 0;

        .video-conference-buttons {
            bottom: 50px;
        }

        .conference-buttons-container {
            bottom: 0;
        }

        .selfVideo {
            z-index: 1111;
            height: 160px;
            width: 200px;
        }
    }
}
