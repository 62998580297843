.full-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;

    &__content {
        background-color: var(--theme-white-color);
        padding: 0;
        border-radius: 0;
        min-width: 300px;

        p {
            padding: 0 10px;
            max-width: 500px;
            text-align: center;
        }
    }

    &__button {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #eb5757;
        display: block;
        margin: 8px 0 4px auto;
    }

    &__title {
        padding: 0 10px;
        text-align: center;
        background-color: var(--theme-primary-color);
        height: 40px;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-align-items: center;
        align-items: center;
        margin: 0 0 30px 0;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.13);
        color: var(--theme-white-color);
        font-weight: 800;
        font-size: 18px;
        text-transform: uppercase;
    }

    &__events-dialog {
        padding: 20px 0 10px 0;
        max-width: 300px;
        margin: auto;

        p {
            text-align: center;
            color: var(--theme-white-color);
            background-color: var(--theme-primary-color);
            padding: 10px;
            margin: 0;
        }

        ul {
            list-style: none;
            padding-top: 15px;
            max-height: 150px;
            overflow: auto;

            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 12px;
                padding-bottom: 10px;
                margin-bottom: 10px;
                border-bottom: 1px solid #ccc;

                &:last-of-type {
                    padding-bottom: 0;
                    margin-bottom: 0;
                    border-bottom: none;
                }

                &:hover {
                    color: var(--theme-primary-color);
                }

                span {
                    padding: 0;
                }
            }
        }
    }
}

.my-events-list {
    padding: 0;
    margin: 0;
}
.my-events-list li {
    cursor: pointer;
}
.my-events-list li.active {
    cursor: initial;
    color: var(--theme-primary-color) !important;
    font-weight: bold;
}
