.Media {
    display: inline-block;
    width: 100%;

    &.isIcon {
        display: flex;
        justify-content: center;
        align-items: center;

        .Media__image {
            height: 92px;
            max-width: 100%;
        }
    }
}

.Media_adaptable {
    height: 100%;
}

.Media__image {
    display: inline-block;
    width: 100%;
    vertical-align: top;
}

.Media_adaptable .Media__image {
    height: 100%;
    object-fit: cover;
}
