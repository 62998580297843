@import '../../../styles/mixins.scss';

.zoom-session-links-module {
    color: var(--theme-white-color);
    display: flex;
    flex-direction: column;
    margin: 0 0 15px 15px;
    width: 520px;

    &__container {
        border-radius: 8px;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
        overflow: hidden;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    &__header {
        display: flex;
        align-items: center;
        height: 48px;
        background-color: var(--theme-background-default-color);
        padding: 0 10px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #2b2b2b;
        border-bottom: 1px solid #cacbd0;

        .d-flex {
            align-items: center;
        }
    }
    &__body {
        background-color: var(--theme-white-color);
        @include xs {
            min-height: 200px;
        }
    }
    &__link_block {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        cursor: pointer;
        color: var(--theme-text-primary-color);
        display: flex;
        align-items: center;
        padding: 0 12px;
        height: 55px;
        justify-content: space-between;
        &:not(:last-child) {
            border-bottom: 1px solid #e6e6e6;
        }

        &.disabled {
            cursor: initial;
        }

        .button {
            font-weight: bold;
            font-size: 16px;
            line-height: 1.2;
            cursor: pointer;
            letter-spacing: 0.0125em;
            text-transform: uppercase;
            color: var(--theme-text-primary-color);
            outline-color: rgba(var(--theme-text-primary-color), 0.2);
            background-color: transparent;
            padding: 10px 15px;
            border: none;
            display: flex;
            align-items: center;
            &:disabled,
            button[disabled] {
                color: #a5a5a5;
                outline-color: rgba(#a5a5a5, 0.2);
                cursor: initial;
            }
            &.-colored {
                color: var(--theme-primary-color);
                outline-color: rgba(var(--theme-primary-color), 0.2);
            }
            &_icon {
                color: currentColor;
                margin-right: 10px;
            }
            &:not(:last-child) {
                margin-right: 15px;
            }
        }
    }
}
