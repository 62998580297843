.booth-video-error-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    font-size: 24px;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);

    p {
        cursor: pointer;
    }

    button {
        background-color: transparent;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: 0;
        cursor: pointer;
        padding: 0;
        font-size: 60px;
    }
}

@media screen and (max-width: 1024px) {
    .exhibitor-booth-mobile .videoWrapper .video-container {
        max-height: calc(calc(var(--vh, 1vh) * 100) - 402px);
    }
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
    .exhibitor-booth-mobile .videoWrapper .video-container {
        max-height: calc(calc(var(--vh, 1vh) * 100) - 112px);
    }
}

@media screen and (max-width: 1024px) {
    .exhibitor-mobile-header {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        background: var(--theme-white-color);

        .booth-logo-container {
            font-size: 0;
            padding: 8px 16px;
        }
        .exhibitor-company {
            max-width: 100%;
            box-sizing: border-box;
            padding: 24px;
            font-size: 24px;
            font-weight: 600;
        }
        .exhibitor-companyInner {
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            overflow: hidden;
        }
        .booth-logo-container img {
            max-width: 100%;
            height: 80px;
            width: auto;
        }
    }
}
