.no-chats-info-container {
    .page-title {
        background-color: transparent !important;
        justify-content: center;

        p {
            color: var(--theme-primary-color) !important;
            padding: 0 !important;
        }
    }
}
