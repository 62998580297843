@import './src/styles/mixins.scss';

.AgendaProgram {
    &__customAddHolder {
        border-bottom: 1px solid #cacbd0;
    }

    &__customAdd {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        box-sizing: border-box;
        margin: 0;
        padding: 24px;
        border: none;
        background-color: transparent;
        font-family: inherit;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        color: #000;
        cursor: pointer;
        outline: none;
        appearance: none;
        transition: opacity 0.2s ease-in-out;

        @include sm {
            padding: 16px;
        }
    }

    &__customAdd:focus {
        opacity: 0.7;
    }

    &__customAdd:hover {
        opacity: 0.7;
    }

    &__customAdd:active {
        opacity: 1;
    }

    &__customAddIcon {
        margin-right: 8px;
    }

    &.isRtl {
        .AgendaProgram__customAddIcon {
            margin-left: 8px;
            margin-right: 0;
        }
    }
}
