.UserPreview {
    display: inline-flex;
    align-items: center;
    pointer-events: none;

    &_interactive {
        pointer-events: auto;
    }

    &.isRtl {
        direction: rtl;

        .UserPreview__media {
            margin-right: 0;
            margin-left: 8px;
        }
    }
}

.UserPreview__el {
    display: inline-flex;
    align-items: center;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    font: inherit;
    cursor: pointer;
    appearance: none;
    outline: none;
    transition: opacity 0.2s ease-in-out;
}

.UserPreview__el:focus {
    opacity: 0.8;
}

.UserPreview__el:hover {
    opacity: 0.8;
}

.UserPreview__el:active {
    opacity: 1;
}

.UserPreview__mediaGroup {
    position: relative;
    display: inline-block;
}

.UserPreview__media {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-right: 8px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #aaa;
    text-transform: uppercase;
}

.UserPreview__activityIndicator {
    position: absolute;
    right: 7px;
    bottom: 1px;
    width: 12px;
    height: 12px;
    border: 2px solid var(--theme-white-color);
    border-radius: 50%;
    background-color: #898989;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.13);
}

.UserPreview_satus-online .UserPreview__activityIndicator {
    background-color: var(--user-online-mark-color);
}

.UserPreview_satus-offline .UserPreview__activityIndicator {
    background-color: var(--user-offline-mark-color);
}

.UserPreview_satus-busy .UserPreview__activityIndicator {
    background-color: var(--user-busy-mark-color);
}

.UserPreview__mediaPlaceholder {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}
