.MobileAgendaMeetingItem {
    position: relative;
    font-weight: 500;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 8px;
        background-color: #5ab453;
    }
}

.MobileAgendaMeetingItem_status {
    &-confirmed::before {
    }

    &-pending::before {
        background-color: #898989;
    }

    &-requested::before {
        background-color: #ffc107;
    }
}

.MobileAgendaMeetingItem__mainRow {
    display: flex;
    align-items: center;
    padding: 12px;
    padding-left: 16px;
}

.MobileAgendaMeetingItem__titleGroup {
    flex: 1 1 auto;
}

.MobileAgendaMeetingItem__title {
    font-weight: 600;
}

.MobileAgendaMeetingItem__description {
}

.MobileAgendaMeetingItem__timeGroup {
    flex: 0 0 auto;
    margin-right: 16px;
    font-weight: 700;
    text-align: right;
}

.MobileAgendaMeetingItem__timeItem {
}

.MobileAgendaMeetingItem__actionGroup {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
}

.MobileAgendaMeetingItem__actionItem {
    margin-right: 8px;

    &:last-child {
        margin-right: 0;
        color: #d62e2f;
    }

    &_hidden {
        pointer-events: none;
        visibility: hidden;
    }
}

.MobileAgendaMeetingItem__metaRow {
    display: flex;
    align-items: center;
    padding: 4px 0;
    background-color: #eaeaea;
    font-size: 12px;
    color: #898989;
}

.MobileAgendaMeetingItem__metaItem {
    flex: 0 0 25%;
    max-width: 25%;
    padding: 0 8px;
    border-right: 1px solid #898989;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;

    &:last-child {
        border-right: none;
    }
}
