.no-exhibitors-wrapper {
    height: 100%;
    &__booth-preview-container {
        width: 100%;
        flex: 1 1;

        height: 80%;
        opacity: 0.33;
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            max-height: 100%;
            object-position: center center;
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
            border-radius: 8px;
        }
    }

    .no-exhibitors-text {
        height: 20%;
        display: flex;
        margin: 0;
        justify-content: center;
        align-items: center;
        font-size: 26px;
        text-transform: uppercase;
        color: var(--theme-secondary-color);
        flex-flow: column;
        span {
            display: block;
            padding: 5px 30px;
            text-align: center;
        }
    }
}

@media all and (max-width: 1024px) {
    .no-exhibitors-wrapper {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
    }
    .no-exhibitors-wrapper p {
        padding-top: 60px;
        color: #898989;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        max-width: 280px;
    }
}
