.edit-section-container {
    max-width: 600px;
    width: 100%;
    position: relative;
    margin: 40px auto;
    padding: 30px;
    background: var(--theme-background-paper-color);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    border: 0.5px solid rgba(137, 137, 137, 0.5);
}

.edit-section-container.event-profile {
    margin-bottom: 80px;
}
.edit-section-container.event-profile ul {
    margin: 0;
}
.edit-section-container.event-profile ul p {
    padding: 0;
    line-height: 25px;
}
.edit-section-container.event-profile .gdpr-links {
    margin-top: 30px;
    margin-bottom: 30px;
}

.user-profile-information > span {
    font-weight: 600;
    font-size: 18px;
    line-height: 14px;
    color: var(--theme-text-primary-color);
}
button.change-password-btn {
    font-family: inherit;
    margin-top: 10px;
    padding: 5px 8px;
    font-size: 12px;
    margin-right: 10px;
}
.user-profile-information p,
.edit-participant-info-container p {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: var(--theme-text-primary-color);
    margin: 0;
    padding-top: 10px;
}
.user-profile-information p > span,
.edit-participant-info-container p > span {
    color: var(--theme-text-secondary-color);
}
.profile-company-name {
    overflow: hidden;
    text-overflow: ellipsis;
}
.upload-text-container {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    color: var(--theme-white-color);
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
}
