.MeetingSetupDialog {
    width: 560px;
    box-sizing: border-box;
    padding: 24px;
    padding-top: 32px;
}

@media (max-width: 640px) {
    .MeetingSetupDialog {
        width: auto;
    }
}

.MeetingSetupDialog__title {
    margin: 0;
    margin-bottom: 24px;
    line-height: 1.2;
    font-size: 24px;
    font-weight: 600;
}

.MeetingSetupDialog__userPreview {
    margin-bottom: 24px;
}

.MeetingSetupDialog__form {
}

.MeetingSetupDialog__notification {
    font-size: 18px;
    color: #898989;
}

// Bad code
.MeetingSetupDialog__form form {
    max-width: none !important;
    padding: 0 !important;
}
