.wall {
    padding-top: 10px;

    [dir='rtl'] & {
        direction: rtl;
        margin-right: 10px;
    }

    &__moderation-alert {
        display: flex;
        align-items: center;
        background-color: var(--theme-second-grey-color);
        border-radius: 4px;
        padding: 12px 8px;
        color: var(--theme-white-color);
        width: 95%;
        margin: 0 auto;
        margin-bottom: 10px;
    }

    &__moderation-alert-icon {
        margin-right: 10px;

        [dir='rtl'] & {
            margin: 0;
            margin-left: 10px;
        }
    }

    &__moderation-alert-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 95.9%;
        text-align: left !important;

        [dir='rtl'] & {
            text-align: right !important;
        }
    }

    @media screen and (max-width: 767px) {
        display: flex;
        justify-content: center;
    }
}

.wall-container {
    .custom-scroll-container {
        height: calc(100vh - 192px);
        position: relative;
    }
}

.ReactVirtualized__Grid__innerScrollContainer {
    min-height: 800px !important;
    max-height: none !important;
}

.wall-list {
    padding: 0 10px;
}

@media screen and (max-width: 1024px) {
    .wall-container {
        position: relative;

        .custom-scroll-container {
            height: calc(calc(var(--vh, 1vh) * 100) - 199px);
        }
    }
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
    .wall-container .sort-container,
    .wall-container > div:last-of-type {
        max-width: 600px;
        margin: 0 auto;
    }
}
