.custom-text-area {
    &__length {
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        position: absolute;
        bottom: 5px;
        right: 10px;

        p {
            margin: 0;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            text-align: right;
            letter-spacing: -0.4px;
            color: var(--theme-secondary-color);
        }
    }
}
