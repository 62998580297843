.networkig-userslist {
    &__user-plate {
        border-bottom: 1px solid #cacbd0;
    }
    &__virtualized-list {
        height: calc(100vh - 170px) !important;
        background-color: var(--theme-background-paper-color);
    }

    &__empty-matches {
        width: 90%;
        margin: 0 auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__empty-matches-icon {
        margin-bottom: 16px;
        width: 66px;
        height: 66px;
    }

    &__empty-matches-info {
        border-radius: 8px;
        border: 1px dashed var(--theme-disabled-color);
        box-sizing: border-box;
        border-radius: 8px;
    }

    &__empty-matches-info-text {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0em;
        margin: 0;
        padding: 20px 12px;
        text-align: center;
        color: var(--theme-second-grey-color);
    }
}

@media screen and (max-width: 768px) {
    .networkig-userslist {
        &__virtualized-list {
            height: calc(100vh - 215px) !important;
        }
    }
}
