.my-listed-events-mobile {
    &__app-bar {
        background: var(--theme-white-color) !important; //TODO: remove "important!" after styleProvider implementation
        &--root {
            box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
            position: relative;
            z-index: 1;
            direction: ltr;
        }
    }

    &__tab {
        color: var(
            --theme-text-secondary-color
        ) !important; //TODO: remove "important!" after styleProvider implementation
        width: calc(100% / 3);
        &--wrapper {
            font-family: 'Montserrat';
            font-weight: 600;
            font-size: 14px;
        }
        &--selected {
            color: var(--theme-primary-color) !important; //TODO: remove "important!" after styleProvider implementation
        }
    }

    &__explore-more {
        padding: 0 !important; //TODO: remove "important!" after styleProvider implementation
    }
}
