@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import './src/styles/mixins.scss';
@import './src/styles/rtlLanguages.scss';

html {
    box-sizing: border-box;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}

.justify-content-between {
    justify-content: space-between !important;
}

.primary-fill-svg,
.primary-fill-svg svg {
    fill: var(--theme-primary-color);
    color: var(--theme-primary-color);
}

.primary-fill-svg.primary-path-svg path {
    stroke: var(--theme-primary-color);
}

.primary-stroke-svg,
.primary-stroke-svg svg {
    stroke: var(--theme-primary-color);
}

.secondary-fill-svg,
.secondary-fill-svg svg {
    fill: var(--theme-secondary-color);
    color: var(--theme-secondary-color);
}

.secondary-stroke-svg,
.secondary-stroke-svg svg {
    stroke: var(--theme-secondary-color);
}

a:focus {
    outline: none !important;
}

.login-page .spinner {
    border-radius: 8px;
}

.d-none {
    display: none !important;
}

.d-flex {
    display: flex !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

input[type='date']::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
}

a {
    text-decoration: none;
}

.upload-image-button {
    margin-top: 16px;
    margin-bottom: 24px;
}
.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 30px;
    object-fit: cover;
    display: flex;
}

.smallContainer {
    max-width: 720px;
    margin: auto;
    position: relative;
}

.position-relative {
    position: relative !important;
}

.h-100 {
    height: 100% !important;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0 1000 transparent inset;
    box-shadow: 0 0 0 1000 transparent inset;
    transition: background-color 5000s ease-in-out 0s;
}

.main-menu-container .actions-container button {
    margin: 0 5px;
}

.program-entry {
    padding: 20px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;

    &.no-speakers .program-container .topic {
        width: 100%;
    }
}

.dropdown-component {
    position: absolute;
    background-color: rgba(239, 237, 237, 0.85);
    /*height: calc(100vh - 300px);*/
    left: 0;
    top: 70px;
    width: 730px;
    overflow: auto;
    z-index: 5;
}

.program-section {
    margin-bottom: 30px;
}

.speakers-container {
    .speaker {
        display: flex;
        align-items: center;
        padding: 10px 0;
    }
}

.speaker > div p:last-of-type {
    font-size: 14px;
    color: grey;
}

.speakers-container .avatar,
.speakers-list .avatar {
    margin-right: 10px;
}

.current-logo-container img {
    max-width: 100%;
    height: auto;
}

.centerOfPage {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
}

.relativeCenter {
    position: relative;
}

.relativeCenter .clickable-element {
    position: absolute;
    transform: translate(-50%, -50%);
    font-size: 0;
    padding: 0;
    margin: 0;
    background: transparent;
    cursor: pointer;
    transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.centerOfPage .clickable-element:hover {
    box-shadow: 0 0 3vh 1px var(--theme-active-color);
    transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.centerOfPage .clickable-element.no-hover {
    cursor: initial;
}

.centerOfPage .clickable-element.no-hover:hover {
    box-shadow: none;
    transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.position-background {
    position: relative;
    position: fixed;
    width: 100%;
    height: 100vh;
    text-align: center;
    font-size: 0;
}

.booth-dialog {
    .booth-dialog-content {
        padding: 0 !important;

        .content-container {
            padding: 10px;
            min-width: 300px;

            p {
                margin: 0 0 10px 0;

                a {
                    font-size: 16px;
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                    color: #2b2b2b;

                    .link {
                        color: var(--theme-secondary-color);
                        font-weight: bold;
                        word-break: break-all;
                    }
                }
            }
        }
    }

    .booth-cancel-button {
        font-family: inherit;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: var(--theme-text-primary-color);
    }
}

.booth-dialog-content {
    #form-dialog-title {
        padding: 0;
        text-align: center;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.13);
        min-width: 400px;
        background-color: var(--theme-primary-color);

        h2 {
            color: var(--theme-white-color);
            font-weight: 800;
            font-size: 18px;
            text-transform: uppercase;
        }
    }
    .subtitle {
        font-weight: 500;
        font-size: 10px;
        font-family: 'Montserrat', sans-serif;
    }
    .title {
        font-weight: 500;
        font-size: 18px;
        font-family: 'Montserrat', sans-serif;
    }
}

.speaker > div p:last-of-type {
    padding-right: 20px;
    display: block;
}

.error-message,
.error-text {
    color: var(--theme-secondary-color) !important;
    font-size: 12px !important;
    font-weight: bold !important;
    margin: 5px 0 !important;
    display: block !important;
}

.logo-container {
    .no-image {
        position: relative;
        height: 100%;
        width: 100%;

        p {
            position: absolute;
            font-size: 17px;
            width: 100%;
            height: 100%;
            margin: 0;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            color: black;
        }
    }

    & + p {
        font-size: 16px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin: 8px 0;
        height: 40px;
        padding: 0 5px;
    }
}

.networking-wrapper {
    font-size: 14px !important;
}

@media screen and (max-width: 1024px) {
    .event-mobile-page {
        height: calc(calc(var(--vh, 1vh) * 100) - 112px);
        overflow: auto;
    }
}
