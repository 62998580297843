.welcomeVideo {
    width: 100vw;
    height: 100vh;
    position: relative;
    background: #000;

    &__media {
        width: 100%;
        height: 100%;
    }

    &__banner {
        width: 100%;
        background-color: var(--theme-primary-color);
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 20px 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .text {
            font-weight: 500;
            font-size: 14px;
            color: var(--theme-white-color);
        }

        .enterPlatformButton {
            background-color: transparent;
            border-radius: 8px 8px 8px 0;
            border: 2px solid var(--theme-white-color);
            padding: 10px 21px;
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.0125em;
            text-transform: uppercase;
            color: var(--theme-white-color);
            cursor: pointer;
        }

        .label-wrapper {
            margin-left: -4px;
        }

        .label-text {
            font-weight: 500;
            font-size: 14px;
            line-height: 96%;
            color: var(--theme-white-color);
            margin-left: 11px;
            font-family: inherit;
        }
    }
}
