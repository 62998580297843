.group-chat-members-container {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
        display: flex;
        align-items: center;
        padding-top: 6.5px;
        padding-bottom: 6.5px;
        text-align: left;

        .remove-member {
            margin-right: 5px;
            color: var(--theme-text-primary-color-light-grey);
        }

        .leave-member {
            margin-right: 0;
            color: var(--theme-text-primary-color-light-grey);
        }
    }

    .chat-member-details {
        flex: 1;
        font-size: 0;
        margin-right: 15px;
    }

    .chat-member-details p {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        margin: 0;
    }
    .chat-member-details span {
        font-weight: 500;
        font-size: 10px;
        color: var(--theme-text-secondary-color);
    }

    &__user-wrapper-separator {
        color: var(--theme-text-primary-color);
        letter-spacing: 0.5px;
        // position: relative;

        &::after {
            position: absolute;
            left: 0;
            content: ' ';
            display: block;
            border-bottom: 1px solid rgba(137, 137, 137, 0.5);
            width: 100%;
        }
    }
}
