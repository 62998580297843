#rooms-dropdown {
    top: initial;
    width: 100%;
    bottom: 56px;
    background-color: var(--theme-white-color);
}
#rooms-dropdown .page-title {
    height: 48px;
    line-height: 48px;
    padding-left: 16px;
    text-align: left;
    background-color: var(--theme-primary-color);
    color: var(--theme-white-color);
}
#rooms-dropdown .page-title h3 {
    font-weight: 600;
    font-size: 16px;
    margin: 0;
    text-transform: uppercase;
}

.rooms-container {
    padding: 0 25px;
    max-height: 40vh;
    overflow: auto;

    &__item {
        display: flex;
        align-items: center;
        padding: 12px 0;
    }

    p {
        margin: 0;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        margin-left: 12px;
        margin-right: 12px;
    }

    a {
        display: flex;
        align-items: center;
    }
}

.building-tab {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px 0;

    &__info {
        display: flex;
    }

    &__icon--active {
        transform: rotate(180deg);
    }
}

.building-subtabs {
    &__item {
        display: flex;
        margin: 20px 0 20px 24px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__text {
        color: var(--theme-text-grey-secondary-color);

        &--active {
            color: var(--theme-primary-color);
        }
    }
}

.room-link {
    &--active {
        svg path {
            fill: var(--theme-secondary-color);
        }
        p {
            color: var(--theme-secondary-color);
        }
    }

    &__text {
        color: var(--theme-text-grey-secondary-color);
    }
}
