@import './src/styles/mixins.scss';

@media screen and (max-width: 1024px) {
    .exhibitors-list-dialog {
        h4 {
            background-color: var(--theme-primary-color);
            color: var(--theme-white-color);
            text-transform: uppercase;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0;
            font-size: 16px;
            line-height: 20px;
            padding: 12px 16px;
            cursor: pointer;
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        ul li {
            display: flex;
            align-items: center;
            padding: 10px 8px;
            font-size: 16px;
            line-height: 20px;
            color: #2b2b2b;
            font-weight: 600;
            border-bottom: 1px solid #ece9e9;
            cursor: pointer;
        }

        ul li.active {
            color: var(--theme-primary-color);
        }

        ul li .logo-container {
            flex: 0 0 auto;
            width: 40px;
            height: 40px;
            margin-right: 12px;
        }

        ul li .logo-container img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        .MuiDialog-container {
            outline: 0;
            position: fixed;
            top: 56px;
            width: 100%;
            @include calculateVHeight(56px);
        }
        .MuiBackdrop-root {
            top: 56px;
        }
    }
}
