.listed-event-card-save-btn {
    font-weight: 600;
    font-family: Montserrat;
    &:not(:last-child) {
        margin-right: 5px;
        &.isRtl {
            margin-left: 5px;
            margin-right: 0;
        }
    }
    color: var(--theme-primary-color) !important;
    &:hover:not(:active) {
        background-color: var(--theme-hover-color) !important;

        color: var(--theme-secondary-color) !important;
        & svg {
            fill: var(--theme-secondary-color) !important;
            color: var(--theme-secondary-color) !important;
        }
    }

    &:active {
        color: var(--theme-white-color) !important;
        background-color: var(--theme-secondary-color) !important;
        & svg {
            fill: var(--theme-white-color) !important;
            color: var(--theme-white-color) !important;
            & .plus {
                fill: var(--theme-secondary-color) !important;
                color: var(--theme-secondary-color) !important;
            }
        }
    }

    &__startIcon {
        &.isRtl {
            margin-right: -4px;
            margin-left: 8px;
        }
    }
}
