@import '../../../styles/mixins.scss';

.zoom_session_dashboard_page {
    position: relative;
    margin: 0 auto;
    height: calc(100vh - 70px);
    background-color: var(--theme-background-default-color);
    overflow: hidden;
    max-width: 100%;
    padding-right: 0;

    @include xs {
        padding-right: 0;
        overflow: auto;
    }
    &.isFullScreen {
        overflow: initial;
    }
    &-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 16px 28px;
        height: 100%;

        @include xs {
            flex-direction: column;
            width: 100%;
            padding: 16px 10px;
            height: auto;
        }
    }
    &-column {
        display: flex;
        flex-direction: column;
        min-width: 452px;

        &:first-child {
            flex-grow: 1;

            .videoWrapper {
                position: relative;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 11;
                background: var(--theme-text-color-black);
                border: 1px solid var(--theme-border-grey-color);
                box-sizing: border-box;
                box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
                border-radius: 6px;
                display: flex;
                align-items: flex-start;

                &.centerAlign {
                    align-items: unset;
                    justify-content: center;
                }

                .waiting-stream-title {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    text-align: center;
                    color: var(--theme-background-paper-color);
                    margin-top: 40px;
                }

                div {
                    width: calc((100vh - 70px) * 1.10804);
                    height: 100%;
                    border-radius: 7px;
                }
            }

            .buttons_group {
                justify-content: center;
            }
        }

        &:not(:last-child) {
            margin-right: 20px;
            min-width: 588px;
            @include xs {
                margin-right: 0;
            }
        }
        @include xs {
            min-width: 0;
            flex-direction: column;
            max-width: 100%;
            width: 100%;
        }
    }
    .module {
        color: var(--theme-white-color);
        display: flex;
        flex-direction: column;
        @include xs {
            margin-bottom: 15px;
        }
        &:not(:last-child) {
            margin-bottom: 10px;
        }
        &:last-child {
            flex-grow: 1;
        }

        &-container {
            border-radius: 8px;
            box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
            overflow: hidden;
            height: 100%;
            display: flex;
            flex-direction: column;
        }
        &-header {
            display: flex;
            align-items: center;
            height: 48px;
            background-color: var(--theme-background-default-color);
            padding: 0 10px;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #2b2b2b;
            border-bottom: 1px solid #cacbd0;

            .d-flex {
                align-items: center;
            }
        }
        &-body {
            background-color: var(--theme-white-color);

            @include xs {
                min-height: 200px;
            }
        }
    }

    .buttons_group {
        display: flex;
        align-items: center;
        margin-top: 40px;

        .tip {
            font-weight: 500;
            font-size: 14px;
            color: var(--theme-secondary-color);
        }
    }
    .button {
        font-weight: bold;
        font-size: 16px;
        line-height: 1.2;
        cursor: pointer;
        letter-spacing: 0.0125em;
        text-transform: uppercase;
        color: var(--theme-text-primary-color);
        outline-color: rgba(var(--theme-text-primary-color), 0.2);
        background-color: transparent;
        padding: 10px 15px;
        border: none;
        display: flex;
        align-items: center;
        &:disabled,
        button[disabled] {
            color: #a5a5a5;
            outline-color: rgba(#a5a5a5, 0.2);
            cursor: inherit;
        }
        &.-colored {
            color: var(--theme-primary-color);
            outline-color: rgba(var(--theme-primary-color), 0.2);
        }
        &_icon {
            color: currentColor;
            margin-right: 10px;
        }
        &:not(:last-child) {
            margin-right: 15px;
        }
    }
}
