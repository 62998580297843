.not-available {
    width: 100%;
    padding: 0 50px;

    &__error-text {
        font-size: 24px;
        line-height: 30px;
        margin: 30px 0;
    }

    &__error-title {
        color: var(--theme-white-color);
        font-weight: bold;
        font-size: 34px;
        margin: 0 0 34px !important;
    }
}

@media all and (max-width: 800px) {
    .not-available {
        height: calc(100vh - 70px);

        &__error-title {
            margin-top: 200px !important;
        }
    }
}
