.exhibitors-slider {
    position: relative;
    margin: auto;
    max-width: 75%;
    padding: 0 60px;

    .slider-title {
        text-align: left;
        color: var(--theme-secondary-color);
        padding-top: 16px;
        padding-bottom: 10px;
        margin: 0;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
    }

    .logo-container {
        height: 127px;
        padding: 0 30px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .slick-dots {
        bottom: -40px;

        li {
            button:before {
                color: #c0c0c0;
                opacity: 1;
                font-size: 8px;
            }

            &.slick-active button:before {
                color: var(--theme-secondary-color);
            }
        }
    }

    .slick-arrow {
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            display: block;
        }

        svg path {
            stroke: #c0c0c0;
        }

        &:hover svg path {
            stroke: #898989;
        }
    }
}

.featured-exhibitors-container {
    padding-bottom: 10px;
    cursor: pointer;
    text-align: center;

    &.active .logo-container__content {
        border: 1px solid var(--theme-secondary-color);
        border-radius: 8px;
    }

    .logo-container {
        padding: 0 16px;

        &__image {
            height: 96px; // TODO: fix (temporary fix)
        }

        &__title {
            font-size: 12px;
            text-align: center;
        }

        &__company {
            font-size: 16px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 4px;
            margin-top: 4px;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            padding: 5px;
            text-overflow: ellipsis;
        }
    }
}

@media all and (max-height: 700px) {
    .exhibitors-slider {
        .logo-container {
            height: 60px;
            padding: 0 20px;
        }

        .featured-exhibitors-container.active img {
            padding-bottom: 5px;
        }

        .slick-dots {
            bottom: -20px;

            li button:before {
                font-size: 6px;
            }
        }

        .slick-arrow {
            height: 30px;

            svg {
                height: 30px;
                width: auto;
            }
        }
    }

    .exhibitors-overlay {
        .exhibitors-list .single-exhibitor {
            width: 25%;

            .logo-container {
                padding: 10px;
                width: 100px;
                height: 70px;
            }
        }
    }
}
