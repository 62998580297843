.notifications-page {
    .date-time {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        color: var(--theme-text-secondary-color);
        margin-left: 20px;
    }

    &.isRtl {
        .notifications-container {
            * {
                text-align: right;
            }

            .date-time {
                margin-right: 20px;
                margin-left: 0;
            }

            .contacts-wrapper .user-name {
                margin: 0;
                margin-left: 4px;
            }
        }
    }
}

.notifications-page.dropdown-component {
    max-width: 323px;
    overflow: initial;
    background-color: var(--theme-background-default-color);
    height: auto;
}
.notifications-page .notifications-container {
    padding: 20px 10px;
    background-color: var(--theme-white-color);
    max-height: 235px;
    overflow: hidden;
}
.notifications-page .notifications-container.active {
    max-height: calc(100vh - 185px);
    padding-bottom: 10px;
    padding-left: 0;
    padding-right: 0;
}
.notifications-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.notifications-list.active {
    height: auto;
    overflow-y: visible;
}
.notifications-list li {
    // font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: var(--theme-text-primary-color);
}
.single-notification-text {
    display: flex;
    cursor: pointer;
    margin-bottom: 15px;
}
.notifications-list li:last-of-type .single-notification-text {
    margin: 0;
}
.contacts-wrapper {
    flex: 1;
    text-align: left;
}
.contacts-wrapper .user-name {
    margin: 0;
    margin-right: 4px;
    color: var(--theme-secondary-color);
}
.contacts-wrapper span:last-of-type {
    margin-left: 3px;
}
.notifications-page .see-all-notifications {
    margin-top: 0;
}
.see-all-notifications p {
    padding: 13px 0;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: var(--theme-secondary-color);
    margin: 0;
    text-align: center !important;
    cursor: pointer;
}
/* Notifications mobile */
.notifications-page.notifications-mobile {
    max-width: initial;
    width: 100%;
    top: 56px;
}
.notifications-mobile .notifications-container.active {
    max-height: calc(100vh - 152px);
}
.notifications-mobile .page-title {
    height: 48px;
    line-height: 48px;
    text-align: left;
    padding-left: 16px;
}
.notifications-mobile .page-title h3 {
    font-weight: 600;
    font-size: 16px;
}
.notifications-mobile .notifications-list li {
    font-size: 14px;
}
.notifications-mobile .notifications-list li .date-time {
    font-size: 12px;
}
