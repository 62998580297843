.auditorium-page {
    height: calc(100vh - 69px);
    position: relative;
    width: 100%;
}

.auditorium-page .slot-title p {
    color: var(--theme-white-color);
    margin: 0;
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    padding-right: 440px;
}

.auditorium-page .slot-title .subtitle {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    margin-top: 8px;
}

.auditorium-page .slot-title .title.hide {
    display: none;
}

.auditorium-page .slot-title:hover .title.hide {
    display: block;
}

.auditorium-page.fullscreen {
    height: 100vh;
    top: 0;
    z-index: 1399 !important;
    padding-bottom: 0;
}

.auditorium-page ul {
    list-style-type: none;
    margin: 0;
    padding: 0 20px;
    position: relative;
}

.auditorium-page ul li:not(.single-comment-container) {
    display: flex;
    align-items: center;
    padding: 5px 0;
}

.auditorium-page ul > span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 94%;
    text-align: right;
}

.slot-title {
    position: absolute;
    left: 0;
    z-index: 1;
    padding: 48px 90px 8px 43px;
    color: var(--theme-white-color);
    background-color: rgba(43, 43, 43, 0.7);
    width: 100%;
    text-align: left;
    top: 0;
    margin: 0;
    font-size: 24px;
}

.next-video {
    position: absolute;
    width: calc((100vh - 70px) * 1.10804);
    height: calc((100vh - 70px) * 0.62516);
    top: calc((-100vh + 70px) * 0.36929);
    left: calc((-100vh + 70px) * 0.55394);
}

.next-video .next-video-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
}

.next-video .next-video-container .timeslot-background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.next-video .next-video-container .timeslot-background-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.next-video .next-video-container .timeslot-background-image .overlay {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1;
}

.next-video p {
    font-weight: 600;
    font-size: 46px;
    color: rgb(53, 62, 64);
    margin-top: 0;
    position: relative;
    z-index: 2;
    margin-bottom: 0;

    .timer {
        direction: ltr;
        font-size: 46px;
    }
}

.next-video p.auditorium-program-ended-text {
    font-size: 24px;
    line-height: 48px;
}

.next-video span {
    font-size: 24px;
}

.next-video .next-video-container.restricted svg {
    display: block;
    margin: 20px auto 0;
    font-size: 70px;
}

.videoWrapper {
    position: absolute;
    width: calc((100vh - 70px) * 1.10804);
    height: calc((100vh - 70px) * 0.62516);
    top: calc((-100vh + 70px) * 0.36929);
    left: calc((-100vh + 70px) * 0.55394);
    display: flex;
    align-items: center;
    justify-content: center;

    .empty-recordings {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    p {
        font-size: 24px;
    }
}

.videoWrapper .video-container {
    width: 100%;
    height: 100%;
    position: relative;

    .records-slider {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translate(0%, -50%);
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        z-index: 11;

        .arrow {
            background: rgba(255, 255, 255, 0.8);
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
            width: 150px;
            height: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            cursor: pointer;

            &.disabled {
                cursor: initial;
                pointer-events: none;
            }

            &:hover {
                box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);

                svg {
                    path {
                        stroke: var(--theme-secondary-color);
                    }
                }
            }

            &.previous {
                transform: translateX(-50%);

                svg {
                    margin-left: 60px;
                }
            }
            &.next {
                transform: translateX(50%);

                svg {
                    margin-right: 60px;
                    transform: rotate(180deg);
                }
            }
        }
    }
}

.videoWrapper .video-actions-container {
    position: absolute;
    bottom: -5.5vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 11;
    height: 50px;
    overflow: hidden;
    direction: ltr;
}

.fullscreen-video .centerOfPage {
    position: static;
}

.videoWrapper.fullscreen {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100vw !important;
    height: calc(var(--vh, 1vh) * 100);
    z-index: 1000 !important;

    & video {
        background: #000;
    }
}

.videoWrapper.fullscreen .video-actions-container {
    position: absolute;
    bottom: 2vh !important;
    width: 50%;
    top: initial !important;
    background-color: rgba(256, 256, 256, 0.65);
    height: 0;
    transition: height 0.2s linear 0.2s;
    border-radius: 25px;

    &.show,
    &:hover {
        height: 50px;
    }
}

.videoWrapper.fullscreen .mute-button {
    margin-left: 0;
}

.video-actions-container button {
    padding: 0;
    background-color: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    cursor: pointer;
    font-size: 30px;
}

.fullscreen .video-actions-container button {
    width: 50px;
    height: 50px;
    padding: 5px;
}

.video-error-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    font-size: 24px;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
}

.video-error-container p {
    cursor: pointer;
}

.video-error-container button {
    background-color: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    cursor: pointer;
    padding: 0;
    font-size: 60px;
}

.auditorium-banner-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

#bannerLeft,
#bannerRight {
    mix-blend-mode: multiply;
}

#bannerLeft {
    width: calc((100vh - 70px) * 0.1776239);
    height: calc((100vh - 70px) * 0.340253);
    top: calc((-100vh + 70px) * 0.0668973);
    left: calc((-100vh + 70px) * 0.9423298);
}

#bannerRight {
    width: calc((100vh - 70px) * 0.1776239);
    height: calc((100vh - 70px) * 0.340253);
    top: calc((-100vh + 70px) * 0.0668973);
    left: calc((100vh - 70px) * 0.9423298);
}

@media screen and (max-width: 1024px) {
    .auditorium-page {
        display: flex;
        flex-flow: column;
    }

    .auditorium-page .centerOfPage {
        flex: 1;
    }

    .auditorium-page .centerOfPage.hide {
        position: absolute;
        z-index: -1;
        transform: translateX(-100%);
    }

    .auditorium-page .relativeCenter {
        height: 100%;
        display: flex;
        flex-flow: column;
    }

    .auditorium-page .page-title {
        color: var(--theme-white-color);
        text-align: left;
        padding: 0 16px;
        font-size: 16px;
    }

    .auditorium-page .tabs-container {
        background-color: var(--theme-background-paper-color);
        min-height: initial;
        height: 48px;
        display: flex;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    }

    .auditorium-page .tab {
        font-size: 14px;
        font-weight: 600;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    .auditorium-page .tab.active {
        font-weight: 600;
        font-size: 14px;
        color: var(--theme-secondary-color);
        border-bottom: 2px solid var(--theme-secondary-color);
    }

    .auditorium-page .slot-title p {
        padding: 0 !important;
        color: #2b2b2b;
    }

    .videoWrapper {
        position: relative;
        width: 100%;
        height: auto;
        top: initial;
        left: initial;
        flex-flow: column;
    }

    .videoWrapper .video-container {
        height: calc(100vw * 9 / 16);
    }

    .videoWrapper.fullscreen .video-container {
        height: 100% !important;
        max-height: 100% !important;
    }

    .videoWrapper .video-actions-container {
        position: initial;
        left: initial;
        bottom: initial;
        top: initial;
        background-color: var(--theme-primary-color);
        height: 50px;
        padding: 16px;
    }

    .videoWrapper.fullscreen .video-actions-container {
        height: auto !important;
    }

    .slot-title {
        position: initial;
        padding: 8px 16px !important;
        background-color: var(--theme-background-default-color);
        left: initial;
        top: initial;
        flex: 1;
    }

    .next-video {
        position: initial;
        width: 100%;
        height: calc(100vw * 9 / 16);
        top: initial;
        left: initial;
    }

    .video-actions-container button {
        color: var(--theme-white-color);
    }

    .fullscreen .video-actions-container button {
        color: var(--theme-white-color);
    }

    .slot-mobile-details {
        display: flex;
        flex-flow: column;
        flex: 1;
    }
}
