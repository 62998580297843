.WelcomeScreensDialog {
    &__content {
        height: 70vh;
        width: 85vw;
    }

    &__image {
        height: 98%;
        max-width: 100%;
    }

    &__actions {
        display: flex;
        justify-content: space-between !important;
        padding: 12px 24px !important;
        background-color: var(--theme-primary-color);
    }

    &__info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: var(--theme-white-color);
    }

    &__button {
        font-weight: 600 !important;
        padding: 8px 24px !important;
        border: 1px solid var(--theme-white-color) !important;
        border-radius: 8px 8px 8px 0px !important;
        background-color: transparent;
        text-transform: uppercase;
        cursor: pointer;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.0125em;

        span {
            color: var(--theme-white-color);
        }
    }
    @media (max-width: 700px) {
        .WelcomeScreensDialog__content {
            width: auto;
            height: auto;
        }
    }
}

.MuiPaper-rounded {
    border-radius: 8px !important;
}
