.matching-dialog-matched-page {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    &__loader-wrapper {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__matches-wrapper {
        height: 100%;
        width: 323px;
        margin: 0 auto;
        box-shadow: 0px 14px 24px rgba(32, 15, 54, 0.25);
        border-radius: 0px 0px 8px 8px;
    }

    &__matches-plate {
        height: 165px;
        border-bottom: 1px solid #cacbd0;
    }

    &__empty-plate {
        border: 1px dashed var(--theme-text-grey-color);
        box-sizing: border-box;
        border-radius: 0px 0px 8px 8px;
        padding: 20px 28px;
        background-color: var(--theme-white-color);
    }

    &__empty-plate-text {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: center;
    }
}
