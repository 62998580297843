.matchmaking-network {
    width: 100%;
    background: var(--theme-background-default-color);
    padding: 6px 14px;
    display: flex;
    justify-content: flex-start;

    &__tooltip-icon-btn {
        padding: 1px !important;
        margin-left: 12px !important;
    }

    &__btn {
        font-size: 14px;
        font-weight: 600;
        border-radius: 6px;
        border: 1px solid transparent;
        height: 36px;
        transition: all 0.3s;

        display: flex;
        align-items: center;
        .matchmaking-network__tooltip-icon-btn {
            & svg {
                fill: var(--theme-white-color) !important;
            }
        }

        &:not(:last-child) {
            margin-right: 12px;
        }

        &--text {
            color: var(--theme-text-color);
            .matchmaking-network__tooltip-icon-btn {
                & svg {
                    fill: var(--theme-primary-color) !important;
                }
            }
        }
        &:hover {
            color: var(--theme-text-color) !important;
            background-color: transparent !important;
            box-shadow: none !important;
            border: 1px solid var(--theme-secondary-color) !important;
            .matchmaking-network__tooltip-icon-btn {
                & svg {
                    fill: var(--theme-primary-color) !important;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .matchmaking-network {
        &__btn {
            &:hover {
                color: var(--theme-white-color) !important;
                background-color: var(--theme-secondary-color) !important;
                box-shadow: inherit !important;
                border-color: transparent !important;
            }

            &--text:hover {
                color: var(--theme-text-color) !important;
                background-color: inherit !important;
            }
        }
    }
}
