.edit-section-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}
.edit-section-header button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: 0;
}

.edit-user-personal-info-container {
    display: flex;
    align-items: center;
}
.edit-user-personal-info-container .avatar {
    width: 150px;
    height: 150px;
    margin-right: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.image-container .remove-avatar {
    color: #eb5757;
    font-family: inherit;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
}
.image-container .remove-avatar:disabled {
    cursor: initial !important;
}

.image-container .select-image:hover + .upload-text-container {
    visibility: visible;
}
