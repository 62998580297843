.matching-dialog-initial-page {
    display: flex;
    align-items: center;
    flex-direction: column;

    &__image-wrapper {
        position: relative;
        height: 145px;
        width: 340px;
        border-radius: 6px;
        margin-bottom: 35px;
    }

    &__image {
        z-index: 3;
        box-shadow: 0px 14px 24px rgba(145, 126, 168, 0.25);
        height: 145px;
        width: 340px;
        border-radius: 6px;
        margin-bottom: 35px;
        position: absolute;
        background: var(--theme-white-color);
        border: 1px solid rgba(145, 126, 168, 0.25);

        left: 0;
    }
    &__img-shadow {
        background: var(--theme-white-color);
        height: 145px;
        width: 340px;
        position: absolute;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
        border-radius: 6px;
        &--1 {
            top: 12px;
            left: 12px;
            z-index: 1;
        }
        &--2 {
            top: 24px;
            left: 24px;
        }
    }
    &__text {
        margin: 0;
        max-width: 620px;
        &:not(:last-child) {
            margin-bottom: 40px;
        }
    }
}
