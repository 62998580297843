@import './src/styles/mixins.scss';

.wallet-popup {
    @include dropdownComponent();

    max-width: 100%;
    width: 680px;
    transform: translateX(-50%);
    max-height: calc(100vh - 80px);
    overflow: initial !important;
    left: 50%;
    background-color: var(--theme-background-default-color);
    box-shadow: -6px 6px 14px rgba(16, 16, 16, 0.14);

    @include md {
        width: 100%;
    }

    @include xs {
        border-radius: 0;
        top: 56px;
    }

    .close-component {
        @include md {
            background: unset;
            border: unset;
            left: 0;
        }
        @include sm {
            background: unset;
            border: unset;
            left: 0;
        }
    }
    .scrollbar {
        height: calc(100vh - 215px) !important;
        @include xs {
            height: calc(100vh - 245px) !important;
        }
    }
    .business-wallet-popup .scrollbar {
        @include xs {
            height: calc(100vh - 208px) !important;
        }
    }
    .wallet-button {
        text-transform: uppercase;
        display: flex;
        font-family: inherit;
        align-items: center;
        font-weight: bold;
        font-size: 16px;
        line-height: 1.2;
        letter-spacing: 0.0125em;
        text-transform: uppercase;
        cursor: pointer;
        background: unset;
        border: unset;
        outline: unset;
        color: var(--theme-text-primary-color);
        fill: currentColor;
        padding: 8px 0;
        &-icon {
            fill: inherit;
            margin-right: 10px;
        }
        &.-colored {
            color: var(--theme-primary-color);
        }
        @include xs {
            font-size: 0px;
        }
    }
    .wallet_search-container {
        height: 45px;
        background-color: var(--theme-background-default-color);
        display: flex;
        align-items: center;
        border-bottom: 1px solid #cacbd0;
    }
    .wallet_search {
        flex: 1 1;
        border: 0;
        padding: 0 16px;
        background-color: inherit;
        outline: 0;
        font-family: inherit;
        color: #777081;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }
    .wallet_user-name {
        padding-right: 60px;
    }

    .wallet-header {
        height: 56px;
        display: flex;
        padding: 0 16px;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #cacbd0;
        background-color: var(--theme-white-color);
        &-titlebox {
            margin-left: 10px;
        }
        &-title {
            font-weight: 600;
            font-size: 16px;
            text-transform: capitalize;
            line-height: 20px;
            color: var(--theme-text-primary-color);
        }
        &-subtitle {
            text-transform: capitalize;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.4px;
            color: #777081;
        }
        .wallet-button {
            margin-right: 25px;
            @include xs {
                margin-right: 10px;
            }
        }
        .d-flex {
            align-items: center;
            &:first-child {
                margin-right: 10px;
            }
        }
    }
    .wallet-container {
        padding: 8px 10px 20px;
    }
    .wallet-inner-padding {
        padding: 0 10px;
    }
}

h3.page-title {
    margin: 50px 0;
}
