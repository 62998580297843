.ListedEventCard {
    display: flex;
    flex-direction: column;
    width: 288px;
    height: 388px;
    background: var(--theme-background-paper-color);
    position: relative;
    border: 1px solid #cacbd0;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 10px;

    &__overlayer {
        position: absolute;
        display: none;
        border-radius: 8px;
        width: 100%;
        top: 0;
        height: 100%;
        background: #2b2b2b;
        opacity: 0.25;
        z-index: 1;
    }
    &__bookmark {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
    }

    &__tooltip {
        position: absolute;
        display: none;
        width: 103px;
        height: 42px;
        text-align: center !important;
        background: var(--theme-background-paper-color);
        padding: 8px;
        border: 1px solid var(--theme-primary-color);
        box-sizing: border-box;
        border-radius: 4px;
        font-weight: 500;
        font-size: 14px;
        color: var(--theme-text-primary-color);
        line-height: 95.9%;
        top: 62px;
        right: -22px;
        z-index: 2;
        &::after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 11px solid transparent;
            border-bottom: 10px solid var(--theme-primary-color);

            top: -11px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__imgWrapper {
        border-radius: 8px 8px 0 0;
        height: 160px;
        overflow: hidden;
        background: var(--theme-light-grey-color);
        cursor: pointer;

        &.isCurrentEvent {
            cursor: initial;
        }
    }

    &__img {
        width: 100%;
    }

    &__title-wrapper {
        max-height: 60px;
    }

    &__title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 20px;
        line-height: 30px;
        font-weight: 500;
        letter-spacing: 0.0015em;
        margin: 0;
        color: var(--theme-text-primary-color);
    }

    &__infoWrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 8px 16px 18px;
        height: 100%;
    }

    &__infoText {
        display: flex;
        margin-bottom: 20px;
    }

    &__date {
        color: var(--theme-text-secondary-color);
        margin: 0;
        font-weight: 500;
        font-size: 14px;
        margin-right: 10px;
        direction: ltr !important;

        &.isRtl {
            direction: rtl !important;
            margin-right: 0px;
            margin-left: 10px;
            &.isMobile {
                height: 12px; //is font-size -2px, it's fix for mobile and "Tajawal" fornt on rtl ;
            }
        }
    }
    &__feautedStatus {
        position: relative;
        font-weight: 500;
        font-size: 14px;
        color: var(--theme-primary-color);
        margin: 0;
        padding-left: 15px;

        &::before {
            content: '';
            display: block;
            position: absolute;
            width: 5px;
            height: 5px;
            background-color: var(--theme-primary-color);
            border-radius: 50%;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
        }

        &.isRtl {
            &.isMobile {
                height: 12px; //is font-size -2px, it's fix for mobile and "Tajawal" fornt on rtl ;
            }
        }
    }

    &__description {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.4px;
        text-align: left;
        max-height: 96px;
        overflow: hidden;
        margin: 0;
        margin-bottom: 10px;
    }

    &__btnWrapper {
        display: flex;
    }
}

@media all and (max-height: 700px) {
    .ListedEventCard {
        width: 328px;
    }
}
.ListedEventCard__bookmark:hover + .ListedEventCard__tooltip {
    display: block;
}
.ListedEventCard__bookmark:hover + .ListedEventCard__tooltip + .ListedEventCard__overlayer {
    display: block;
}
