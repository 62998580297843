.auditoriums-navigation {
    position: absolute;
    z-index: 9;
    height: 0;
    bottom: 25vh;
    display: flex;
    justify-content: space-between;
    left: calc(-50vw - 90px);
    width: calc(100vw + 180px);
}

.auditoriums-navigation.has-booth-wall {
    width: calc(100vw - 143px);
    min-width: calc(75vw + 180px);
}

.auditoriums-navigation .button-container .details {
    width: 180px;
    height: 180px;
    padding: 30px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.auditoriums-navigation .button-container:hover .details {
    background-color: var(--theme-white-color);
}

.auditoriums-navigation .previous .button-container .details {
    transform: rotate(180deg);
}

.auditoriums-navigation .button-container {
    position: relative;
    transform: scale(0.5);
    opacity: 0.5;
}

.auditoriums-navigation .button-container:hover {
    transform: scale(1);
    opacity: 1;
}

.auditoriums-navigation .button-container .auditorium-name {
    font-size: 16px;
    text-transform: uppercase;
    left: 90px;
    position: absolute;
    padding-left: 90px;
    top: 50%;
    height: 140px;
    transform: translateY(-50%);
    justify-content: center;
    align-items: center;
    background: var(--theme-background-default-color);
    box-shadow: 0 14px 24px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    z-index: -1;
    display: none;

    &.isRtl {
        right: 90px;
        left: inherit;
        padding: 0;
        padding-right: 90px;
    }
}

.auditoriums-navigation .button-container:hover .auditorium-name {
    display: flex;
}

.auditoriums-navigation .next .button-container .auditorium-name {
    right: 90px;
    padding-left: 0;
    padding-right: 90px;
    left: initial;

    &.isRtl {
        left: 90px;
        right: inherit;
        padding: 0;
        padding-left: 90px;
    }
}

.auditoriums-navigation .button-container .auditorium-name p {
    margin: 0;
    padding: 0 14px 0 26px;
    min-width: 130px;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    font-weight: 600;
    color: var(--theme-text-primary-color);
}

.auditoriums-navigation .next .button-container .auditorium-name p {
    text-align: right;
    padding: 0 26px 0 14px;
}

@media all and (max-height: 859px) {
    .auditoriums-navigation.has-booth-wall {
        min-width: calc(78vw + 180px);
    }
}
