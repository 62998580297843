.AccountNavigationMenu {
    position: relative;
    z-index: 2;
    display: flex;
    padding: 0 24px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    background-color: var(--theme-white-color);
}

.AccountNavigationMenu__item {
    margin-right: 8px;
}

.AccountNavigationMenu__link {
    position: relative;
    display: inline-block;
    padding: 12px;
    font-size: 16px;
    font-weight: 700;
    color: #000;
    text-decoration: none;
    text-transform: uppercase;
    transition: opacity 0.2s ease-in-out, color 0.2s ease-in-out;
}

@media (max-width: 480px) {
    .AccountNavigationMenu__link {
        font-size: 12px;
    }
}

.AccountNavigationMenu__link:hover {
    opacity: 0.7;
}

.AccountNavigationMenu__link:active {
    opacity: 1;
}

.AccountNavigationMenu__link::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-top: 4px solid currentColor;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.AccountNavigationMenu__link_active {
    pointer-events: none;
    color: var(--theme-primary-color);
}

.AccountNavigationMenu__link_active::before {
    opacity: 1;
}
