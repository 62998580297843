.BusinessCardLogoUploading {
}

.BusinessCardLogoUploading__title {
    margin: 0;
    margin-bottom: 32px;
    line-height: 1.2;
    font-size: 20px;
    font-weight: 500;
    color: #777081;
    text-transform: uppercase;
}

@media (max-width: 960px) {
    .BusinessCardLogoUploading__title {
        margin-bottom: 16px;
    }
}

.BusinessCardLogoUploading__actionGroup {
    display: flex;
    margin-bottom: 16px;
}

.BusinessCardLogoUploading__actionUpload {
    margin-right: 32px;
}

.BusinessCardLogoUploading__actionRemove {
}

.BusinessCardLogoUploading__fileInputLabel {
}

.BusinessCardLogoUploading__fileInput {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
}

.BusinessCardLogoUploading__field {
    line-height: 1.5;
}

.BusinessCardLogoUploading__fieldComment {
    margin-bottom: 24px;
    color: #777081;
}

@media (max-width: 840px) {
    .BusinessCardLogoUploading__fieldComment {
        margin-bottom: 0;
    }
}

.BusinessCardLogoUploading__uploadedInfo {
    display: flex;
    font-weight: 600;
    font-size: 16px;
}

@media (max-width: 960px) {
    .BusinessCardLogoUploading__uploadedInfo {
        margin-bottom: 24px;
    }
}

.BoothMarketplaceImageField__uploadedKey {
    margin-right: 8px;
}

.BoothMarketplaceImageField__uploadedValue {
    max-width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--theme-primary-color);
}

.BusinessCardLogoUploading__preview {
    position: relative;
    height: 280px;
    border-radius: 8px;
    background-color: #cacbd0;
    color: var(--theme-white-color);
    cursor: default;
}

@media (max-width: 1160px) {
    .BusinessCardLogoUploading__preview {
        height: 220px;
    }
}

@media (max-width: 960px) {
    .BusinessCardLogoUploading__preview {
        margin-bottom: 12px;
    }
}

.BusinessCardLogoUploading__previewArrow {
    position: absolute;
    top: calc(50% - 96px);
    right: calc(50% + 96px);
}

.BusinessCardLogoUploading__previewText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 320px;
    text-align: center;
    text-transform: uppercase;
}
