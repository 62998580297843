.BusinessCardColors {
    color: #777081;
}

.BusinessCardColors__titleGroup {
    margin-bottom: 12px;
}

@media (max-width: 960px) {
    .BusinessCardColors__titleGroup {
        margin-bottom: 16px;
    }
}

.BusinessCardColors__title {
    margin: 0;
    line-height: 1.2;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
}
