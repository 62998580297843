.choose-package {
    display: flex;
    flex-flow: column;

    &__title {
        color: var(--theme-text-grey-secondary-color);
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        margin-top: 50px;
        margin-bottom: 16px;
    }
}

.package {
    border-radius: 4px;
    margin-bottom: 8px;
    border: 1px solid #cacbd0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #2b2b2b;
    background-color: var(--theme-white-color);
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;

    &__name-container {
        display: flex;
        align-items: center;
        padding: 5px 7px;
        overflow: hidden;
    }

    &__name {
        text-transform: uppercase;
        padding-left: 7px;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &__price {
        padding: 7px;
        background: var(--theme-background-default-color);
        border-radius: 0 4px 4px 0;
        flex: 0 0 126px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: 1px solid #cacbd0;
        font-weight: 600;
        font-size: 24px;
        line-height: 40px;
    }

    &__description {
        display: block;
        margin: 0;
        background-color: var(--theme-white-color);
        padding: 16px;
        border: 1px solid #cacbd0;
        border-radius: 4px;
        font-size: 16px;
        line-height: 20px;
        font-weight: normal;
        color: #2b2b2b;
        margin-bottom: 8px;
        white-space: pre-line;
    }

    &-selected {
        color: var(--theme-primary-color);
        border: 1px solid var(--theme-primary-color);
        filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2));
        margin-bottom: 0;

        svg {
            color: var(--theme-secondary-color);
        }

        .package__price {
            background-color: var(--theme-secondary-color);
            color: var(--theme-white-color);
            border-left: 1px solid var(--theme-secondary-color);
        }
    }
}

.set-billing-info {
    &__title {
        color: #777081;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        margin-top: 50px;
        margin-bottom: 16px;
        text-transform: uppercase;
    }

    &__field {
    }
}
