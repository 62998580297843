.IconContainer {
    display: inline-block;
    height: 24px !important;
    width: 24px !important;
    padding-right: 0 !important;
    vertical-align: top;

    &_size {
        &-medium {
        }

        &-small {
            height: 18px !important;
            width: 18px !important;
        }

        &-large {
            height: 32px !important;
            width: 32px !important;
        }

        &-xlarge {
            height: 40px !important;
            width: 40px !important;
        }

        &-xxlarge {
            height: 48px !important;
            width: 48px !important;
        }

        &-inline {
            height: 1em !important;
            width: 1em !important;
        }
    }
}
