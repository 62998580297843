.sticky-menu .menu {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1299;
}
.fullscreen-video .menu {
    height: 0;
    overflow: hidden;
    display: none;
}

.header {
    height: 70px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(137, 137, 137, 0.5);
    background-color: var(--theme-white-color);
}

.event-menu-header {
    &__button-holder {
        display: inline-block;
        cursor: pointer;
        text-align: center !important;
        padding-right: 35px;
        line-height: 1;
        color: #898989;

        svg {
            fill: #898989;
        }

        &.active {
            color: var(--theme-primary-color);

            svg {
                fill: var(--theme-primary-color);
            }
        }
    }
}
.header .main-menu-container {
    width: 100%;
    padding-left: 43px;
    position: relative;
}
.left-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
        max-height: 65px;

        img {
            max-width: 118px;
            height: 65px;

            &.customLogo {
                max-height: 65px;
            }
        }
    }
}
.left-header-wrapper #my-events {
    height: 70px;
    padding-left: 30px;
    margin-left: 20px;
    border-left: 1px solid #cacbd0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.left-header-wrapper #my-events p {
    color: var(--theme-secondary-color);
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    padding: 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.left-header-wrapper #my-events p span {
    padding-right: 15px;
    max-width: 390px;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.main-menu-container .actions-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.main-menu-container .main-header-wrapper {
    font-size: 0;

    .active svg {
        fill: var(--theme-primary-color);
        color: var(--theme-primary-color);
    }
}

.main-header-wrapper {
    display: flex;
    align-items: center;

    &.reversed {
        & > div {
            &:first-child {
                padding-right: 0;
            }
            &:last-child {
                padding-right: 35px !important;
            }
        }
    }
}
.main-menu-container .main-header-wrapper #chats {
    position: relative;
}
.main-menu-container .main-header-wrapper #chats > div {
    height: 18px;
    background-color: var(--theme-primary-color);
    border-radius: 50%;
    color: var(--theme-white-color);
    line-height: 18px;
    position: absolute;
    width: 18px;
    left: 20px;
    bottom: 12px;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
}

.main-menu-container .main-header-wrapper > div:last-of-type {
    padding: 0;
}
.main-menu-container .right-header-wrapper > div:last-of-type {
    padding: 0;
    margin-right: 35px;
}
.main-menu-container .actions-container > div p,
.right-header-wrapper > div p {
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    color: var(--theme-text-secondary-color);
    padding-top: 6px;
}
.main-menu-container .actions-container > div .active p,
.right-header-wrapper > div .active p {
    color: var(--theme-primary-color);
}
.right-header-wrapper {
    font-size: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    padding-left: 45px;

    .social-media-share-event {
        position: absolute;
        right: 185px;
        top: 23px;

        @media screen and (max-width: 1024px) {
            right: 90px;
            top: 17px;
        }
    }
    .social-media-share-event.isRtlLanguage {
        position: absolute;
        left: 185px;
        top: 23px;
        right: unset;
        @media screen and (max-width: 1024px) {
            right: unset;
            top: 15px;
            left: 127px;
        }
    }
}
.right-header-wrapper > div:first-child {
    position: relative;
    padding-right: 0;
    margin-right: 35px;
}
.right-header-wrapper > div:first-child > div {
    height: 18px;
    background-color: var(--theme-primary-color);
    border-radius: 50%;
    color: var(--theme-white-color);
    line-height: 18px;
    position: absolute;
    width: 18px;
    right: -12px;
    bottom: -8px;
}
.right-header-wrapper > div:first-child > div span {
    display: inline-block;
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
}

.unread-messages {
    text-align: center;
}

.server-down-info-container {
    position: absolute;
    z-index: 9999 !important;
    background-color: rgba(169, 19, 20, 0.85);
    color: var(--theme-white-color);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px !important;
    padding: 0 16px;

    span {
        display: inline-block;
        font-weight: bold;
        color: var(--theme-white-color);
        padding: 0 10px;
        cursor: pointer;
    }
}

.top-container {
    position: fixed;
}

@media all and (max-width: 1365.9px) {
    .left-header-wrapper #my-events p span {
        max-width: 220px;
    }
}
@media all and (max-width: 1199.9px) {
    .left-header-wrapper #my-events p span {
        max-width: 200px;
    }
}
