.close-dropdown.expand {
    margin-top: 5px;
}
.chat-page {
    &.dropdown-component {
        height: calc(100vh - 70px);
        background-color: var(--theme-white-color);
        overflow: visible;
        width: 25%;
        max-width: 333px;

        &[data-close='true'] {
            display: none;
        }

        &.expanded {
            width: 768px;
            max-width: initial;
        }
    }

    &.edit > div {
        height: 100%;
    }

    .update-chat-name {
        padding: 10px;
    }

    .create-button,
    .update-chat-name {
        color: var(--theme-primary-color);
        margin-right: 30px;
        font-weight: 700;
        &.isRtl {
            margin-right: inherit;
            margin-left: 30px;
        }
        &:disabled {
            color: var(--theme-text-secondary-color);
            cursor: initial;
        }
    }
}
.chats-container {
    height: calc(100% - 49px);
    position: relative;
    display: flex;
    .chats-list-container {
        border-right: 0.5px solid rgba(137, 137, 137, 0.5);
        &.isRtl {
            border-left: 0.5px solid rgba(137, 137, 137, 0.5);
        }
        display: inline-flex;
        flex-flow: column;
        overflow-y: auto;
        max-width: 68px;
        flex: 1;
        background: var(--theme-white-color);

        .last-message {
            font-size: 12px;
            color: var(--theme-text-secondary-color);
            max-width: 120px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-top: 5px;
            display: block;
        }

        .all-chats {
            padding: 4px 0 0 0;
            text-align: left;
            width: 100%;
            overflow-x: hidden !important;

            .avatar {
                position: relative;

                .online-button {
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background-color: var(--theme-primary-color);
                    position: absolute;
                    right: 0;
                    top: 0;
                    color: var(--theme-white-color);
                    font-weight: 500;
                    font-size: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .no-chats {
                color: var(--theme-text-secondary-color);
                text-align: center;
                text-transform: uppercase;
                font-family: 'Montserrat', sans-serif;
                font-weight: bold;
                font-size: 12px;
            }

            .chat-name {
                flex-grow: 1;
                padding-bottom: 5px;
                padding-top: 5px;
            }

            & > div {
                cursor: pointer;
                padding-top: 8px;

                &:first-child {
                    .all-chats__user-wrapper-separator {
                        display: none;
                    }
                }
            }

            &__user-wrapper-separator {
                color: var(--theme-text-primary-color);
                letter-spacing: 0.5px;

                &::after {
                    position: absolute;
                    left: 0;
                    content: ' ';
                    display: block;
                    border-bottom: 1px solid rgba(137, 137, 137, 0.5);
                    width: 100%;
                }
            }
        }
    }

    &.isRtl {
        .user-avatar {
            margin-right: 8px;
        }

        .avatar {
            margin: 8px 8px 8px 16px;
        }

        .chat-name {
            text-align: right;
        }
    }
}

.no-active-chat {
    .chats-container {
        padding-top: 0;

        .chats-list-container {
            max-width: 100%;
            border-right: none;
        }
    }
}

.no-chats {
    .chats-container .chats-list-container {
        flex: 0 !important;
    }

    &.expanded .opened-chat-container {
        max-width: 100%;
    }
}
.expanded .chats-container .chats-list-container {
    max-width: 270px;
}
.chats-list-container[data-number-chats='0'] {
    background-color: var(--theme-background-default-color);
}
.opened-chat-container {
    display: inline-flex;
    flex: 1;
    max-width: 422px;
    background-color: var(--theme-background-default-color);

    .opened-chat-wrapper {
        width: 100%;
    }

    &[data-number-chats='0'] {
        align-items: center;
        justify-content: center;
        position: relative;

        .page-title {
            position: absolute;
            top: 0;
            display: flex;
            width: 100%;
            background-color: var(--theme-background-paper-color);
            left: 0;
            height: 48px;
            align-items: center;
            z-index: 2;
        }

        > div {
            max-width: 229px;
            text-align: center;

            p {
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                color: rgba(137, 137, 137, 0.5);
                margin-top: 0;
            }
        }
    }
}
.opened-chat-container[data-number-chats='0'] .page-title p,
.chat-page .page-title p {
    margin: 0;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    color: var(--theme-white-color);
    padding-left: 20px;
}
.expanded .opened-chat-container {
    max-width: 512px;
    flex-grow: 2;
}
.no-active-chat {
    .opened-chat-container {
        flex: 0;
        display: none;
    }

    &.expanded .opened-chat-container {
        display: inline-flex;
        flex: 1;
    }

    &.no-chats .opened-chat-container {
        flex: 1;
        display: inline-flex;
    }

    &-message {
        padding: 15px;
        margin: 0;
        text-align: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: rgba(137, 137, 137, 0.5);
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.expanded .all-chats .chat-name,
.no-active-chat .all-chats .chat-name {
    text-align: left;
    padding-top: 8px;
}
.expanded .chats-list-container .all-chats > div,
.no-active-chat .chats-list-container .all-chats > div {
    display: flex;
}
.expanded .chats-list-container .all-chats > div:first-of-type,
.no-active-chat .chats-list-container .all-chats > div:first-of-type {
    padding-top: 0;
}

.group-chat-info,
.private-chat-info {
    overflow: hidden;
    flex-grow: 1;
}
.button-wrapper button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
}
.time-ago {
    margin: 0;
    padding-top: 10px;
    color: var(--theme-text-secondary-color);
    display: none;
    font-size: 8px;
}
.expanded .time-ago,
.no-active-chat .time-ago {
    padding-top: 8px;
    display: block;
}
.group-chat-button .avatar,
.private-chat .avatar {
    width: 40px;
    height: 40px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}
.group-chat-name {
    max-width: 110px;
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.group-chat-button {
    .avatar {
        background: linear-gradient(143.44deg, var(--theme-primary-color) 9.77%, var(--theme-secondary-color) 83.11%);

        & > span {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: var(--theme-white-color);
            letter-spacing: 1px;
        }
    }

    .members-count {
        margin: 0;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        padding-top: 3px;
        color: var(--theme-text-secondary-color);
        display: none;
    }
}
.expanded .group-chat-button .avatar,
.no-active-chat .group-chat-button .avatar {
    margin-right: 16px;
    margin-top: 8px;
}
.expanded .chat-settings .group-chat-button .avatar,
.no-active-chat .chat-settings .group-chat-button .avatar {
    margin-top: 0;
    margin-right: 0;
}
.expanded .group-chat-button .members-count,
.no-active-chat .group-chat-button .members-count {
    display: block;
}
.private-chat .avatar {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.expanded .private-chat .avatar,
.no-active-chat .private-chat .avatar {
    margin-right: 16px;
    margin-top: 8px;
}
.single-chat-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.chat-wrapper {
    justify-content: flex-end;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    & > div:first-child {
        flex: 1;
        justify-content: flex-end;
        display: flex;
        flex-direction: column;
        // box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        // background-color: var(--theme-white-color);
        margin-bottom: 10px;
        padding: 0 10px;
    }

    .scrollbar {
        width: auto !important;
    }

    .live-wall {
        width: 100%;
        max-height: initial;
        margin: 0;
        display: flex;
        flex-flow: column;
        height: 100%;
        .scrollbar .box {
            display: flex;
            flex-direction: column;
        }
    }
}
.chat-message-form {
    max-width: 95%;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.single-chat-container .new-comment-container {
    height: initial;
    background: var(--theme-background-paper-color);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    margin-bottom: 23px;
    position: relative;
}
.new-comment-container {
    input {
        height: 24px;
        line-height: 24px;
        display: block;
        flex: 1 1;
        border: none;
        outline: none;
        padding-left: 16px;
        color: var(--theme-text-primary-color);
        font-family: inherit;
        font-size: 12px;

        &::placeholder {
            font-family: inherit;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 24px;
            color: rgba(43, 43, 43, 0.5);
        }
    }

    button {
        font-family: 'Montserrat', sans-serif;
        height: 47px;
        background-color: transparent;
        border: 0;
        outline: 0;
        padding: 0 16px 0 10px;
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        color: var(--theme-primary-color);
        text-transform: uppercase;
        cursor: pointer;

        &:disabled {
            cursor: initial;
            svg path {
                fill: var(--theme-text-grey-color);
            }
        }

        &.send-group-message:disabled svg path {
            fill: var(--theme-primary-color);
            cursor: initial;
        }
    }

    .tooltip p {
        font-size: 16px;
        line-height: 20px;
        text-align: left;
        font-weight: 600;
        max-width: 170px;
        margin: 0;
        color: var(--theme-text-primary-color);
    }
}

.message .user-avatar,
.group-chat-members-container .user-avatar,
.members-list-event .user-avatar,
.info-container .user-avatar {
    width: 35px;
    height: 35px;
    border: 1px solid var(--theme-white-color);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    margin-right: 10px;
}
.message .user-avatar {
    width: 40px;
    height: 40px;
}
.chat-settings,
.chat-page .page-title {
    display: flex;
    width: 100%;
    background-color: var(--theme-background-paper-color);
    left: 0;
    height: 48px;
    align-items: center;
    z-index: 2;
}
.chat-settings {
    border-left: 4px solid var(--theme-primary-color);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 1px 10px rgba(0, 0, 0, 0.08);
    text-align: left;
    display: flex;
    position: relative;

    &-icon {
        margin: 0 10px;
        cursor: pointer;
        &--active {
            path {
                fill: var(--theme-primary-color);
            }
        }
    }

    .user-avatar {
        min-width: 40px;
        height: 40px;
        margin-left: 4px;
        max-width: 40px;
    }

    .group-chat-name {
        line-height: 22px;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 8px;
        color: black;
        font-size: 16px;
        font-weight: 600;
        max-width: 180px;
    }

    .group-chat-button {
        display: flex;
        align-items: center;

        .avatar {
            margin-left: 4px;
        }
    }

    .back-button {
        margin-left: 8px;
        cursor: pointer;
    }

    .members {
        margin-left: 8px;
        font-size: 12px;
    }
}
.expanded .chat-settings .group-chat-name {
    max-width: 500px;
}
.single-chat-container .manage-chat,
.single-chat-container .remove-member,
.single-chat-container .leave-member,
.single-chat-container .see-members,
.members-list-event .add-member,
.members-list-event .remove-member,
.members-list-event .leave-member,
.chat-page .cancel-button,
.chat-page .create-button,
.chat-page .update-chat-name,
.edit-user-information .cancel-button,
.edit-user-information .update-button,
#booth-files-dialog .cancel-button {
    width: 72px;
    background-color: transparent;
    border: 0;
    outline: 0;
    height: 100%;
    cursor: pointer;
}

.chat-page .cancel-button,
.chat-page .create-button,
.chat-page .update-chat-name {
    width: auto;
}

.single-chat-container .see-members[color='see'] {
    background-color: var(--theme-white-color);
    margin-right: 0;
}
.single-chat-container .see-members[color='see'] p,
.single-chat-container .manage-chat[color='see'] p {
    color: var(--theme-primary-color);
    font-weight: bold;
}
.single-chat-container .manage-chat p,
.single-chat-container .see-members p {
    margin: 0;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    color: var(--theme-text-primary-color-light-grey);
}
.single-chat-container .see-members p {
    display: inline-block;
    height: 12px;
}
.button-wrapper {
    height: 100%;
}
.single-chat-container .chats-members-container {
    width: 100%;
    background-color: var(--theme-white-color);
    height: calc(100% - 49px);
}
.chats-members-container .page-title {
    height: 39.5px;
    line-height: 39.5px;
    border-bottom: 0.5px solid rgba(137, 137, 137, 0.5);
    position: relative;
    background-color: var(--theme-white-color);
}
.chats-members-container .page-title h3 {
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: var(--theme-primary-color);
    text-transform: uppercase;
    padding-left: 14px;
}
.chats-members-container .page-title span {
    position: absolute;
    right: 20px;
    top: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: var(--theme-text-secondary-color);
}
.chats-members-container > div:last-child {
    height: 100%;
}
.manage-chat,
.remove-member,
.add-member {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: var(--theme-text-primary-color-light-grey);
}
.members-list-event {
    list-style-type: none;

    li {
        display: flex;
        align-items: center;
        padding: 4px 0;

        .member-control {
            text-transform: uppercase;
            color: var(--theme-text-primary-color-light-grey);
            font-size: 16px;
            font-weight: 500;
            p {
                margin: 0;
            }
        }

        .leave-member {
            color: var(--theme-text-primary-color-light-grey);
        }
    }

    .chat-member-details {
        flex: 1;
        font-size: 0;
        margin-right: 15px;

        p {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            margin: 0;
            padding: 0;
        }

        span {
            font-weight: 500;
            font-size: 10px;
            color: var(--theme-text-secondary-color);
        }
    }
}
.live-wall .empty-space-container {
    flex: 1;
}
.visitors label {
    font-family: inherit;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(137, 137, 137, 0.5) !important;
    height: initial;
}
.register-form-wrapper label + div:after {
    color: rgb(137, 137, 137);
    border-bottom-color: rgb(137, 137, 137);
}
.register-form-wrapper label + div:hover:before {
    border-bottom-color: rgb(137, 137, 137) !important;
}
.cancel-button,
.create-button,
.update-chat-name,
.save-file,
.update-button {
    font-family: inherit;
    font-style: normal;
    font-weight: 600;
    font-size: 18px !important;
    text-transform: uppercase;
    cursor: pointer;
}
.cancel-button {
    color: var(--theme-text-secondary-color);
    margin-right: 18px;
}
.exhibitor-representative-dialog .save-file:disabled,
.edit-user-information .update-button:disabled {
    color: var(--theme-primary-color);
    margin-right: 30px;
    cursor: initial;
}
.close-component {
    left: -40px;

    img {
        transform: rotate(-90deg);
    }
}
@media all and (max-height: 875px) {
    .chat-wrapper {
        .box .live-wall {
            height: 100%;
            min-height: inherit;
        }

        .bottom-container {
            div {
                height: 100%;
            }
        }
    }
}
@media screen and (max-width: 1024px) {
    .chat-page.dropdown-component {
        left: 0 !important;
        max-width: initial;
        width: 100%;
        top: 56px;
        height: calc(calc(var(--vh, 1vh) * 100) - 56px);
    }
    .chats-container {
        height: calc(100% - 105px);

        &--opened {
            height: calc(100% - 150px);
        }
    }
    .chats-container[data-number-chats='0'] {
        padding-top: 0;

        .page-title {
            background-color: var(--theme-background-default-color);

            p {
                color: var(--theme-primary-color);
                font-weight: 600;
            }
        }
    }
    .opened-chat-container {
        background-color: var(--theme-background-paper-color);
    }
    .chat-wrapper {
        & > div:first-child {
            box-shadow: none;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    .single-chat-container .new-comment-container {
        border: 1px solid #cacbd0;
        margin-left: 10px;
        margin-right: 10px;
        box-shadow: none;
        margin-bottom: 16px;
    }

    .single-chat-container .chats-members-container {
        height: calc(calc(var(--vh, 1vh) * 100) - 208px);
    }
    .edit-user-information .cancel-button {
        color: #000000;
        font-size: 16px;
        font-weight: bold;
    }
}
@media screen and (min-width: 767px) and (max-width: 1024px) {
    .chat-page.dropdown-component.expanded {
        width: 100%;
    }
    .expanded {
        .opened-chat-container {
            max-width: initial;
        }
        .single-chat-container .chats-members-container {
            width: 100%;
        }
    }
    .chat-settings > div:first-child {
        position: relative;
    }
}
@media screen and (max-width: 767px) {
    .opened-chat-container {
        max-width: initial;
    }
    .opened-chat-container {
        width: calc(100% - 68px);
    }
    .last-message--opened {
        display: none !important;
    }
}
