.not-found-page {
    width: 100%;
    padding: 0 50px;

    &__error-text {
        font-size: 36px;
        line-height: 30px;
        margin: 30px 0;
    }

    &__link {
        color: var(--theme-white-color);
        font-size: 36px;
        line-height: 26px;
        font-weight: 600;
        background: transparent;
        border: none;
        cursor: pointer;
        outline: none !important;
        padding: 0;

        &:hover {
            text-decoration: underline;
        }
    }
    &__error-title {
        color: var(--theme-white-color);
        font-weight: bold;
        font-size: 42px;
        margin: 0 0 34px !important;
    }
}

@media all and (max-width: 800px) {
    .not-found-page {
        height: calc(100vh - 70px);

        &__error-title {
            margin-top: 200px !important;
        }
    }
}
