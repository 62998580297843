.platform.menu-wrapper .announcement-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.platform.menu-wrapper .announcement-text p {
    color: var(--theme-background-default-color);
    margin: 0;
    font-size: 14px;
}
.platform.menu-wrapper.scrolled .announcement-text p {
    color: var(--theme-text-secondary-color);
}

@media all and (max-width: 1365.9px) {
    .platform.menu-wrapper .announcement-text {
        position: initial;
        top: initial;
        left: initial;
        transform: initial;
    }
}
@media all and (max-width: 1199.9px) {
    .platform.menu-wrapper .announcement-text {
        max-width: 185px;
    }
}
@media all and (max-width: 767.9px) {
    .platform.menu-wrapper .announcement-text {
        display: none;
    }
    .platform.mobile-menu-wrapper .menu-links a.second-link {
        margin-right: 24px;
        z-index: -2;
        position: relative;
        opacity: 0;
        transition: opacity 0.2s;
    }
    .platform.mobile-menu-wrapper.opened .menu-links a.second-link {
        z-index: 1;
        opacity: 1;
        transition: opacity 0.2s;
    }
    .platform.mobile-menu-wrapper.opened .top-links-container {
        height: 50px !important;
    }
    .platform.mobile-menu-wrapper .top-links-container .announcement-text {
        padding-bottom: 30px;
    }
    .platform.mobile-menu-wrapper .top-links-container .menu-links {
        display: none;
    }
}
@media all and (max-width: 374.9px) {
    .platform.mobile-menu-wrapper .menu-links a.second-link {
        display: none !important;
    }
    .platform.mobile-menu-wrapper.opened .top-links-container {
        height: 70px !important;
    }
    .platform.mobile-menu-wrapper .top-links-container .announcement-text {
        padding-bottom: 0;
    }
    .platform.mobile-menu-wrapper .top-links-container .menu-links {
        display: initial;
    }
}
