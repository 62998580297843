.matching-dialog-questions-page {
    max-width: 526px;
    margin: 0 auto;

    &__title {
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: 0.0015em;
        text-align: center !important;
        max-width: 450px;
        margin: 0 auto;
        margin-bottom: 25px;
        margin-top: 35px;
    }

    &__label-wrapper {
        padding: 11px 19px;
        border: 1px solid var(--theme-primary-color);
        border-radius: 3px;
        position: relative;
        display: flex;
        flex-direction: column;
        [dir='rtl'] & {
            direction: rtl;
        }
    }
    &__label-title {
        margin: 0;
        background: var(--theme-white-color);
        position: absolute;
        padding: 0 8px;
        top: -8px;

        [dir='rtl'] & {
            right: 10px;
            left: unset;
        }
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.4px;
        color: var(--theme-primary-color);
        max-width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__form-label {
        font-size: 16px !important;
        font-weight: 500 !important;
        line-height: 24px !important;
        letter-spacing: -0.04em !important;
    }

    &__question-type {
        display: none;
    }
}

@media all and (max-width: 768px) {
    .matching-dialog-questions-page {
        &__title {
            font-size: 16px;
            margin: 0;
            margin-bottom: 16px;
            text-align: left !important;
            [dir='rtl'] & {
                text-align: right !important;
            }
        }

        &__question-type {
            display: block;
            color: var(--theme-second-grey-color);
            margin: 0;
            margin-bottom: 8px;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: -0.4px;
        }

        &__label-wrapper {
        }
        &__label-title {
            margin: 0;
        }
    }
}
