.create-group-chat {
    background-color: var(--theme-background-default-color);
    width: 100%;

    & > div {
        height: 100%;
    }

    .page-title {
        top: 0;
    }

    &.edit {
        left: -4px;
        height: calc(100vh - 170px);
        top: 51px;
        width: 323px;
    }
}

.expanded .create-group-chat.edit {
    width: 710px;
}

.new-chat-wrapper {
    background-color: var(--theme-white-color);
    overflow: hidden;
    height: 100%;
    text-align: left;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    padding-top: 25px;
    padding-bottom: 54px;

    .input-container {
        padding: 0 10px;
        height: 100%;

        & > div {
            margin-top: 0;
            width: 100%;
        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            padding: 0 6px;
        }

        .members-chat-wrapper {
            width: 100%;
            height: 150px;
            padding: 0 10px;

            h5 {
                padding: 0 10px;
            }
        }
    }

    ul {
        height: auto;
        padding: 0 10px;
        margin: 0;
    }

    .m-bottom {
        margin-bottom: 10px !important;
    }

    label,
    input {
        font-family: inherit;
        font-style: normal;
        font-weight: normal;
        height: initial;
    }

    label {
        color: rgba(137, 137, 137, 0.5);
    }

    input {
        color: #000;
    }

    label + div:after {
        color: rgb(137, 137, 137);
        border-bottom-color: rgb(137, 137, 137);
    }

    label + div:hover:before {
        border-bottom-color: rgb(137, 137, 137) !important;
    }

    label.MuiFormLabel-filled,
    label.Mui-focused {
        color: var(--theme-secondary-color) !important;
    }
    label.MuiFormLabel-filled + div fieldset {
        border-color: var(--theme-secondary-color) !important;
    }
    fieldset span {
        padding: 0;
    }
    .Mui-focused fieldset {
        border-color: var(--theme-secondary-color) !important;
        border-width: 1px !important;
    }
    .MuiInputLabel-outlined.MuiInputLabel-marginDense {
        font-family: inherit;
        transform: translate(14px, 20px) scale(1);
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(14px, -6px) scale(0.75);
    }
    .MuiInputBase-fullWidth {
        height: 53px;
    }
}

.chat-page.edit .new-chat-wrapper .members-chat-wrapper {
    padding: 0;
    position: relative;
}

.new-chat-buttons-container,
.new-chat-buttons-container.create-group {
    position: absolute;
    width: 100%;
    bottom: 0;
    margin: 0;
    display: flex;
    justify-content: flex-end;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 1px 10px rgba(0, 0, 0, 0.08);
    padding: 14.5px 0;
}

@media screen and (max-width: 1024px) {
    .create-group-chat {
        height: calc(calc(var(--vh, 1vh) * 100) - 161px);

        & .scroll-wrapper {
            height: auto;
        }

        & > div {
            position: relative;
        }
    }

    .new-chat-buttons-container,
    .new-chat-buttons-container.create-group {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 1px 10px rgba(0, 0, 0, 0.08);
    }

    .cancel-button {
        color: #000000;
        font-size: 16px;
        font-weight: bold;
    }
}
@media screen and (min-width: 767px) and (max-width: 1024px) {
    .expanded .create-group-chat {
        width: 100%;
    }
}
