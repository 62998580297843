.Form {
    max-width: none !important;
    padding: 0 !important;

    &__row {
        margin-bottom: 24px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &.isRtl {
    }
}
