@import './src/styles/mixins.scss';

.wallet_user {
    padding: 8px 16px;
    display: flex;
    background-color: var(--theme-background-paper-color);
    flex-direction: column;
    border-bottom: 1px solid #cacbd0;
    &__participant-avatar {
        $sz: 40px;
        width: $sz;
        min-width: $sz;
        height: $sz;
        border-radius: 50%;
        margin-right: 8px;
        position: relative;
        &.iRtl {
            margin-left: 8px;
        }

        &::after {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            width: 12px;
            height: 12px;
            background-color: #898989;
            border: 2px solid var(--theme-white-color);
            border-radius: 50%;
            box-shadow: 0 1px 6px rgba(0, 0, 0, 0.13);
        }
    }
    &-section {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:not(:last-child) {
            margin-bottom: 17px;
        }
    }
    &-icon {
        margin-right: 10px;
    }
    &-name {
        font-weight: 600;
        font-size: 16px;
        text-transform: capitalize;
    }
    .user__status {
        &_online {
            .wallet_user__participant-avatar {
                &::after {
                    background-color: var(--user-online-mark-color) !important;
                }
            }
        }

        &_offline {
            .wallet_user__participant-avatar {
                &::after {
                    background-color: var(--user-offline-mark-color) !important;
                }
            }
        }

        &_busy {
            .wallet_user__participant-avatar {
                &::after {
                    background-color: var(--user-busy-mark-color) !important;
                }
            }
        }
    }
    .wallet-button {
        &:not(:last-of-type) {
            margin-right: 30px;
            @include xs {
                margin-right: 20px;
            }
        }
    }

    &.isRtl {
        .wallet-button {
            margin-left: 15px;

            &-icon {
                margin-left: 8px;
                margin-right: 0;
            }
        }
    }
}
