.matching-dialog-note-text {
    margin: 0 auto;
    &__text {
        margin: 0 auto;
        max-width: 640px;
        font-style: normal;
        font-size: 16px;
        line-height: 20px;
        color: var(--theme-text-secondary-color);
        &--highlight {
            font-weight: 600;
            color: var(--theme-primary-color);
            text-transform: uppercase;
        }
    }
}
