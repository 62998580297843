.BusinessCardEditing {
    &__creating {
        position: relative;
        margin-top: 200px;
        text-align: center;
    }

    &__creatingBoxIcon {
        margin-bottom: 24px;
    }

    &__creatingArrowIcon {
        position: absolute;
        left: calc(50% + 136px);
        bottom: calc(50% - 16px);
    }
}
@media (max-width: 960px) {
    .BusinessCardEditing__creatingArrowIcon {
        display: none;
    }
}

.BusinessCardEditing__creatingMessage {
    font-size: 16px;
    font-weight: 600;
    color: #898989;

    &_highlighted {
        color: var(--theme-primary-color);
    }
}
