.AuditoriumProgramTimeslot {
    display: flex;
    align-items: center;
    padding: 12px 0;
    font-size: 14px;

    &_notLargeScreen {
        padding: 12px;
    }

    &.isRtl {
        direction: rtl;

        .AuditoriumProgramTimeslot__titleGroup {
            margin: 0 0 0 24px;
        }

        .AuditoriumProgramTimeslot__actionGroup {
            .AuditoriumProgramTimeslot__timeGroup {
                margin: 0 0 0 24px;
            }

            .AuditoriumProgramTimeslot__timeItem {
                direction: ltr;
                text-align: left;
            }

            .AuditoriumProgramTimeslot__action {
                .Button__icon {
                    margin: 0 0 0 8px;
                }
            }
        }
    }
}

.AuditoriumProgramTimeslot__titleGroup {
    flex: 1 1 auto;
    margin-right: 24px;
    overflow: hidden;
}

.AuditoriumProgramTimeslot__speakers {
    color: #2b2b2b;
}

.AuditoriumProgramTimeslot__title {
    font-weight: 700;
    color: var(--theme-secondary-color);
    overflow: hidden;
    text-overflow: ellipsis;
}

.AuditoriumProgramTimeslot__actionGroup {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
}

.AuditoriumProgramTimeslot__timeGroup {
    margin-right: 24px;
}

@media (max-width: 640px) {
    .AuditoriumProgramTimeslot__timeGroup {
        margin-right: 16px;
    }
}

.AuditoriumProgramTimeslot__timeItem {
    font-weight: 700;
    text-align: right;
}

.AuditoriumProgramTimeslot__action {
    color: var(--theme-primary-color);

    &_disabled {
        color: var(--theme-text-primary-color);
    }
}
