.full-page-spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &__logo-container {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        background-color: rgba(256, 256, 256, 0.5);
        padding: 21px 43px;
        z-index: 1;
        font-size: 0;
    }

    &__main-logo {
        max-width: 117px;
        height: 50px;
    }

    &__homepage-overlay {
        height: 100%;
        background-color: rgba(29, 29, 27, 0.5);
        position: absolute;
        width: 100%;
        top: 0;
    }
    &__spinner-container {
        position: absolute;
    }

    &__spinner {
        color: var(--theme-white-color);
    }
}
