.FileField {
    position: relative;
    padding-bottom: 16px;

    &_uploaded {
    }
}

.FileField__input {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
}

.FileField__wrapper {
    display: block !important;
    transition: opacity 0.3s ease-in-out;
    cursor: pointer;
}

.FileField__wrapper:hover {
    opacity: 0.8;
}

.FileField__wrapper:active {
    opacity: 0.8;
}

.FileField__content {
    display: flex;
    align-items: center;
    color: var(--theme-primary-color) !important;
}

.FileField__iconCol {
    margin-right: 12px;
}

.FileField__button {
    display: inline-block !important;
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
    background-color: transparent !important;
    color: inherit !important;
    outline: none !important;
    cursor: pointer !important;
    appearance: none !important;
    transition: opacity 0.2s ease-in-out;

    &:hover {
        opacity: 0.8;
    }

    &:active {
        opacity: 1;
    }
}

.FileField__label {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    [dir='rtl'] & {
        margin-right: 10px;
    }
}

.FileField__error {
    position: absolute;
    left: 0;
    top: calc(100% - 20px);
    font-size: 12px;
    color: var(--theme-secondary-color);
}
