.auditoriums-program-dropdown {
    max-width: 680px;
    width: 100%;
    min-height: 270px;
    overflow: visible;
    background-color: var(--theme-background-default-color);
    height: auto;
    transform: translateX(-50%);
    position: absolute;

    &__page-title {
        font-size: 0;
        height: 40px;
        line-height: 40px;
        padding: 0;
        position: relative;
        background-color: var(--theme-primary-color);
    }
    &.isRtl {
        .navigation-slider {
            direction: ltr;
        }
    }

    .__slider {
        &.hide {
            display: none;
        }
    }

    &__program-entry {
        padding: 0;
        margin: 0;
        border: 0;
    }

    &__program-container {
        margin: 0 auto;
        &--is-empty {
            border-bottom: none;
        }
        &:last-child {
            padding-bottom: 25px;
        }
    }

    &__empty-program {
        min-height: 250px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 30px;
        background-color: var(--theme-background-default-color);
    }

    &__info {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: hsla(0, 0%, 53.7%, 0.5);
        margin-top: 0;
        text-align: center;
    }

    &__program-slider-wrapper {
        padding: 0 40px;
    }

    &__auditorium-name {
        position: static;
        display: flex;
        align-items: center;
        align-content: center;
        height: 52px;
        padding: 0px;
        font-weight: bold;
        font-size: 16px;
        color: var(--theme-text-primary-color);
        text-align: left;
        direction: ltr;
        &.isRtl {
            direction: rtl;
        }
        & > p {
            margin: 0;
        }
    }

    &__timezone {
        width: 100%;
        height: 48px;
        border-bottom: 1px solid var(--theme-border-grey-color);
        display: flex;
        align-items: center;
        justify-content: center;

        h2 {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: var(--theme-text-primary-color);
            margin: 0;
        }
    }
}

.desktop-program-dropdown {
    box-shadow: -6px 6px 14px rgba(16, 16, 16, 0.14);
}

@media screen and (max-width: 700px) {
    .auditoriums-program-dropdown {
        max-width: initial !important;
        min-width: initial !important;
        width: 100% !important;
        transform: translateX(0) !important;
        top: 56px;
        height: calc(calc(var(--vh, 1vh) * 100) - 112px);
        &__program-slider-wrapper {
            padding: 0;
            position: relative;
        }

        &__auditorium-name {
            height: 53px;
            display: flex;
            padding: 0;
            background: #fff;
            position: static;
            width: 100vw;
            bottom: 0;
            z-index: 1;
            & > p {
                padding-left: 40px;
            }
            &.isRtl {
                & > p {
                    padding-right: 40px;
                    padding-left: 0;
                }
            }
        }
        &__page-title {
            height: 50px;
        }
    }
}
