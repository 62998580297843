.privateChat {
    max-width: 370px;
    max-width: 210px;
    padding-right: 20px;
    flex: 1;
}
.expanded .privateChat {
    max-width: 610px;
    max-width: 555px;
}
.private-chat-btn-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.private-chat-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    font-weight: 600;
    color: black;
    font-size: 16px;
    margin-left: 8px;
}
