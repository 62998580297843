.DesktopAgendaProgramItem {
    display: flex;
    align-items: center;
    padding: 16px;
    font-weight: 500;

    &__titleCol {
        flex: 1 1 auto;
        margin-right: 24px;
    }

    &__contextGroup {
        display: flex;
        margin-top: 4px;
        font-size: 12px;
        color: #898989;
    }

    &__eventName {
        margin-right: 8px;
    }

    &__metaCol {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
    }

    &__dateGroup {
        flex: 0 0 auto;
        margin-right: 16px;
        font-size: 12px;
        color: #898989;
    }

    &__timeGroup {
        flex: 0 0 auto;
        margin-right: 24px;
        font-weight: 700;
        text-align: right;
    }

    &__actionGroup {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
    }

    &__actionItem {
        margin-right: 16px;

        &:last-child {
            margin-right: 0;
            color: var(--theme-primary-color);
        }

        &_hidden {
            pointer-events: none;
            visibility: hidden;
        }
    }

    &.isRtl {
        .DesktopAgendaProgramItem__titleCol {
            margin-right: 0;
            margin-left: 24px;
        }

        .DesktopAgendaProgramItem__actionItem:not(:first-child) {
            margin-right: 8px;

            .Button__icon {
                transform: rotate(180deg);
            }
        }
    }
}
