.wall-container {
    background-color: var(--theme-background-default-color);
    flex: 1 1;

    & .post-wrapper {
        position: relative;
        .spinner-wrapper {
            height: calc(100vh - 115px);
        }
        .scrollbar > div {
            @media (max-width: 1024px) {
                margin-right: 0 !important;
            }
        }
    }
}
.wall-container .sort-container {
    display: flex;
    font-size: 13px;

    &.isRtl {
        margin-right: 16px;
    }
}
.wall-container .sort-container span {
    padding: 10px;
}
.wall-container .sort-container .button {
    cursor: pointer;
    text-transform: uppercase;
}
.wall-container .sort-container .button.active {
    color: var(--theme-primary-color);
    font-weight: bold;
}
.add-new-post-container {
    min-height: 43px;
    background-color: var(--theme-white-color);
    display: flex;
    align-items: center;
    padding: 0 10px;
    justify-content: space-between;
}
.add-new-post-container > div:last-of-type {
    font-family: inherit;
    flex: 1;
    margin-right: 25px;
}
#newPost::-webkit-scrollbar {
    width: 4px;
}

/* Track */
#newPost::-webkit-scrollbar-track {
    background: var(--theme-background-default-color);
}

/* Handle */
#newPost::-webkit-scrollbar-thumb {
    background: rgb(137, 137, 137);
}

/* Handle on hover */
#newPost::-webkit-scrollbar-thumb:hover {
    background: #555;
}

#newPost,
.newComment {
    flex: 1;
    border: 0;
    padding: 0 10px;
    outline: 0;
    // font-family: 'Montserrat', sans-serif;
    color: var(--theme-text-primary-color);
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    height: 17px;
    -webkit-appearance: none;
}
#newPost::placeholder,
.newComment::placeholder {
    transform: translate3d(0, 0, 0);
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: var(--theme-text-primary-color);
    opacity: 1;
}
.user-avatar {
    background-size: cover;
    display: inline-block;
    background-repeat: no-repeat;
    border-radius: 50%;
    background-position: center;
    width: 40px;
    height: 40px;
    border: 0.5px solid var(--theme-white-color);
    box-sizing: border-box;
}
.add-new-post {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--theme-primary-color);
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.13);
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    outline: 0;
    line-height: 13px;
}
.add-new-post svg path {
    fill: var(--theme-white-color);
}
.add-new-post:disabled {
    cursor: initial;
    opacity: 0.5;
    background-color: var(--theme-white-color);
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.13);
}
.add-new-post:disabled svg path {
    fill: var(--theme-primary-color);
}
.custom-scroll-container {
    height: calc(100vh - 170px);
}
.custom-scroll-container > div:not(.absolute-spinner) {
    height: auto;
}
.single-post-container {
    padding: 10px 14px 0 14px;
    background-color: var(--theme-white-color);
    margin: 3px 0;
    border: 1px solid #cacbd0;
    box-sizing: border-box;
    border-radius: 4px;
    position: relative;
    text-align: left;
}
.single-post-container.highlight {
    box-shadow: 0 0 5px 3px rgba(102, 36, 209, 0.35);
}
.single-post-container .post-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
.lang-rtl .single-post-container .post-header {
    direction: rtl;
}
.user-name {
    margin: 6px 8px 0 8px;
    flex: 1;

    &__name {
        font-style: normal;
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        text-transform: capitalize;
        color: var(--theme-text-primary-color);
        margin-bottom: 3px;
        overflow-wrap: break-word;
    }
    &__time-ago {
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: var(--theme-text-secondary-color);
    }
}
.user-name p {
    margin: 0;
}
.post-container {
    padding-top: 12px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.04em;
    padding-bottom: 10px;
    color: var(--theme-text-primary-color);
}
.post-container > div {
    word-break: break-word;
    word-wrap: break-word;
}
.comments-button {
    &:disabled {
        cursor: unset;
    }
}

.action-button,
.comments-button,
.like-button,
.delete-button {
    background-color: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    position: relative;
    padding-right: 10px;
}
.comments-button > div,
.like-button > div {
    width: 16px;
    height: 16px;
    position: absolute;
    border: 1px solid var(--theme-secondary-color);
    color: var(--theme-secondary-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: -7px;
    left: 14px;
    background-color: var(--theme-white-color);
}
.comments-button > div span,
.like-button > div span {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
}
.like-button {
    margin-right: 0;
}
.like-button:disabled {
    cursor: initial;
}
.post-comments,
.user-wrapper-separator {
    color: var(--theme-text-primary-color);
    letter-spacing: 0.5px;
}
.post-comments:before,
.comments-list-container:after,
.user-wrapper-separator:after {
    position: absolute;
    left: 0;
    content: ' ';
    display: block;
    border-bottom: 1px solid rgba(137, 137, 137, 0.5);
    width: 100%;
}
.post-comments .comments-list-container {
    padding-top: 13px;
    padding-right: 0;
}
.post-comments .comments-list-container .delete-button {
    padding-right: 0;
}
.new-comment-container {
    height: auto;
    min-height: 43px;
    display: flex;
    align-items: center;
}
.new-comment-container .user-avatar {
    margin-right: 10px;
}
.comment-header p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
}
.comment-header p.time-ago {
    color: var(--theme-text-secondary-color);
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: space-between;
}
.comment-header p span {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    padding: 0;
}
.single-comment-container {
    &__coment-text {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.04em;
    }
}
.single-comment-container .comment-header {
    justify-content: space-between;
    align-items: flex-start;

    width: 100%;
}
.single-comment-container .user-avatar {
    margin-right: 9px;
}
.single-comment-container .comment-container {
    flex: 1;
}
.comment-container {
    &__user-name {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
    }
    .time-ago {
        [dir='rtl'] & {
            margin-right: 5px;
        }
    }
}

.comment-container p:not(.time-ago) {
    word-break: break-word;
    word-wrap: break-word;
    max-width: 250px;
}
.newComment {
    flex: 1;
    border: 0;
    outline: 0;
    padding: 0;
}
.wall[data-empty='true'] {
    position: relative;
    height: 100%;
}
.empty-wall {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: unset !important;
}
.empty-wall p {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: rgba(137, 137, 137, 0.5);
    margin-top: 0;
    text-align: center;
}

@media screen and (max-width: 1024px) {
    .wall {
        padding-top: 0 !important;
    }
    .wall-container .sort-container span {
        font-weight: 500;
        font-size: 16px;
    }
    .wall-container .sort-container span.button {
        font-weight: bold;
    }
    .add-new-post-container {
        position: absolute;
        width: calc(100% - 10px);
        bottom: 10px;
        min-height: 50px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        border: 1px solid #cacbd0;
        border-radius: 4px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 1px 10px rgba(0, 0, 0, 0.08);
        padding-top: 4px;
    }
    .add-new-post-container .MuiInputBase-multiline {
        padding: 0;
    }

    .single-post-container .user-avatar {
        width: 40px;
        height: 40px;
    }
    .user-name p:first-child {
        font-size: 16px;
    }
    .post-container {
        font-size: 16px;
        font-weight: 500;
    }
    .single-post-container .postActions img {
        width: 18px;
        height: 18px;
    }
    .postActions .delete-button img,
    .post-comments .comments-list-container .delete-button img {
        width: 20px;
        height: 20px;
    }
    .comments-button > div,
    .like-button > div {
        bottom: -5px;
    }
    .comment-header p {
        font-size: 16px;
    }
    .comment-header p span {
        font-size: 16px;
    }
    .comment-header p.time-ago {
        font-size: 12px;
    }
    .new-comment-container .user-avatar {
        width: 24px;
        height: 24px;
    }
    .new-comment-container input {
        width: 50%;
        font-size: 16px;
    }
    .new-comment-container input::placeholder {
        color: #898989;
        font-size: 16px;
    }
    .post-container {
        padding-top: 12px;
    }
    .new-comment-container .add-new-comment {
        width: 26px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--theme-white-color);
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.13);
        border: 0;
        border-radius: 50%;
        outline: 0;
        padding: 0;
    }
    .new-comment-container .add-new-comment:disabled {
        cursor: initial;
        opacity: 0.5;
    }
    #newPost::placeholder,
    .newComment::placeholder {
        color: #898989;
        font-size: 16px;
    }

    .custom-scroll-container.networking-wrapper {
        border: 0;
    }
    #newPost,
    .newComment {
        font-size: 16px;
    }
}
