.dropdown-actions-chats {
    position: absolute;
    left: initial;
    right: -40px;
    top: 0;
    flex-flow: column;
    justify-content: space-between;

    .close-dropdown {
        position: initial;
        border-radius: 0 8px 8px 0;
    }

    svg {
        fill: var(--theme-white-color);
        transform: rotate(90deg);
    }
}
