.BottomNavigationButton {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 55px;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    font-family: inherit;
    font-size: 12px;
    color: #777081;
    text-transform: uppercase;
    outline: none;
    appearance: none;
    cursor: pointer;
    transition: color 0.2s ease-in-out;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        border-top: 2px solid transparent;
        transition: border-top 0.1s ease-in-out;
    }

    &_active {
        color: var(--theme-primary-color);

        &::before {
            border-top: 2px solid var(--theme-primary-color);
        }
    }
}

.BottomNavigationButton__icon {
    margin-bottom: 4px;
}

.BottomNavigationButton__title {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}

.BottomNavigationButton_active {
    .BottomNavigationButton__title {
        font-weight: 700;
    }
}
