.auditoriumOverlayPlayer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: transparent;
}

.auditoriumOverlayPlayer.audio-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 60px;
    background-color: rgba(0, 0, 0, 0.65);
    color: var(--theme-white-color);
}
