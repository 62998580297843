.group-chat-settings-container {
    flex: 1 1;
    padding-top: 16px !important;
    justify-content: space-between !important;
    margin: 0 !important;
    position: relative;
    .members-chat-wrapper {
        height: 150px;

        .members-list-event {
            padding: 0;

            &__user-wrapper-separator {
                color: var(--theme-text-primary-color);
                letter-spacing: 0.5px;

                &::after {
                    position: absolute;
                    left: 0;
                    content: ' ';
                    display: block;
                    border-bottom: 1px solid rgba(137, 137, 137, 0.5);
                    width: 100%;
                }
            }
        }
    }

    .edit-group-chat {
        position: absolute;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        bottom: 0;
        padding: 14.5px 0;
        right: 0;
    }
    .inputs-wrapper {
        height: 100%;
        padding-bottom: 54px;
    }

    .input-container {
        margin-top: 0;
        width: 100%;
        height: 100%;
    }

    .MuiInputBase-fullWidth {
        height: 53px;
        width: 100%;
    }

    input {
        height: 12px;
        font-size: 16px;
        font-weight: 500;
    }
}
