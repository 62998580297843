.matchmaking-banner {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: var(--theme-secondary-color);
    padding: 14px 50px 14px 20px;
    width: calc(100% - 320px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--theme-white-color);

    &--skipped {
        background-color: var(--theme-primary-color);
        padding: 28px 32px;
    }

    &--full-width {
        width: 100%;
    }

    &__info {
        display: flex;
        align-items: center;
        align-self: flex-start;
    }
    &__icon-btn {
        color: var(--theme-white-color) !important;
    }

    &__close-icon {
        fill: var(--theme-white-color);
    }
    &__text {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 0em;
        margin: 0;
        text-align: left;
    }
    &__open-dialog-btn {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0.0125em;
        color: var(--theme-white-color) !important;
        border: 1px solid var(--theme-white-color);
        padding: 12px;
        border-radius: 8px 8px 8px 0px;
        background-color: transparent;
        text-transform: uppercase;
        align-self: flex-end;

        &:hover {
            cursor: pointer;
        }
    }
}

@media screen and (max-width: 1024px) {
    .matchmaking-banner {
        top: 50px;
        z-index: unset;
        width: 100%;
        padding: 8px 12px;
        &--skipped {
            padding: 22px 12px;
        }
    }
}

@media screen and (max-width: 768px) {
    .matchmaking-banner {
        flex-direction: column;
    }
}
