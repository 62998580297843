html.lang-rtl {
    direction: rtl;

    body {
        font-family: 'Tajawal', sans-serif !important;
    }

    h2,
    p,
    button,
    div {
        text-align: right;
    }

    .MuiDialog-root {
        direction: ltr;
    }

    .buttons-container {
        justify-content: flex-start;
    }

    .upload-avatar-container {
        direction: rtl;

        & > img {
            margin: 0;
        }

        & > span {
            margin-right: 16px;
        }
    }

    .arabic-rotate {
        transform: rotate(180deg);
    }

    .arabic-row-revers {
        display: flex;
        flex-flow: row-reverse;
    }

    .arabic-justify-end {
        display: flex;
        justify-content: flex-end;
    }

    .register-form-wrapper {
        form {
            text-align: center;
        }

        input,
        label {
            direction: rtl;
        }

        .register-button {
            text-align: center;
        }
    }

    .login-body {
        form {
            text-align: center;
        }

        .login-submit-button {
            text-align: center;
        }
    }

    .login-page .field-container,
    .edit-user-information .field-container,
    .register-form-wrapper .field-container,
    .recover-password-dialog .field-container,
    .change-password-dialog .field-container,
    .create-meeting .single-option-container,
    .field-container {
        img {
            left: 10px;
            right: auto;
        }

        label {
            right: 0;
            left: initial;
            text-indent: 24px;
        }

        legend {
            text-align: right;
        }

        .MuiInputLabel-outlined.MuiInputLabel-shrink {
            transform: translate(8px, -6px) scale(0.75);
        }

        .MuiSelect-iconOutlined {
            left: 7px;
            right: initial;
        }
    }

    .create-meeting .single-option-container .MuiOutlinedInput-input {
        text-align: right;

        &::-webkit-calendar-picker-indicator {
            left: 0;
            position: absolute;
            margin: 0;
            margin-left: 8px;
        }
    }

    .myaccount-container > div div:first-child {
        margin-left: -19px;
        margin-right: 0 !important;
    }

    .myaccount-container > div div:last-child {
        right: auto !important;
        left: 2px !important;
    }

    .recover-password-dialog .field-container .MuiInputLabel-outlined.MuiInputLabel-shrink,
    .change-password-dialog .field-container .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(8px, -6px) scale(0.75);
    }

    .social-btn img {
        left: initial;
        right: 16px;
    }

    // remove previous focus bar due to lib issues
    .homepage .partners-section .top-slider .slick-current .slider-image-container:after {
        display: none;
    }

    .homepage .partners-section .top-slider {
        .slider-image-container {
            &.isActive {
                &:after {
                    content: '';
                    height: 8px;
                    width: 100%;
                    bottom: 0;
                    left: 0;
                    border-radius: 0 0 8px 8px;
                    background-color: var(--theme-secondary-color);
                    position: absolute;
                }
            }
        }
    }

    .exhibitors-list-container .scrollbar > div {
        margin-left: -19px !important;
        margin-right: 0 !important;
    }

    .side-menu-container {
        direction: ltr;
    }

    .auditorium-page.dropdown-component,
    .exhibitors-slider .slider-container,
    .auditoriums-popup {
        .slick-slider div {
            transition: none;
        }

        .slick-track {
            direction: ltr;
        }

        .slick-list {
            direction: ltr;

            .slick-slide {
                float: left;
            }
        }
    }

    .header .main-menu-container {
        padding-right: 43px;
    }

    .main-menu-container .main-header-wrapper #back img {
        transform: rotate(180deg);
    }

    .login-page .email-wrapper img,
    .login-page .password-wrapper img {
        left: 10px;
        right: auto;
    }

    #my-events #account-drop {
        right: 0;
    }

    .top-container .close-component {
        left: initial;
        right: -40px;
        border-radius: 0 8px 8px 0;
    }

    body.isIOS .scrollbar > div {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .scrollbar > div {
        margin-left: 0 !important;
        margin-right: -19px !important;
    }

    .business-wallet-popup .scrollbar > div {
        margin-right: 0 !important;
    }

    .participant-avatar {
        margin-left: 16px;
    }

    .wallet-popup .wallet_user-name {
        padding-left: 60px;
        padding-right: 0;
    }

    .my-account-component.dropdown-component {
        left: 0;
        right: initial;
    }

    .notifications-page.dropdown-component {
        left: 112px !important;
        right: initial;
    }

    .program-entry .program-container,
    .event-actions-container.swipeable-drawer .drawer-buttons-container {
        flex-flow: row-reverse;
    }

    .participant-button.open-chat,
    .user-details .accept-request,
    .showfloor-page .all-exhibitors-button .text span {
        right: initial;
        left: 0;
    }

    .showfloor-page .all-exhibitors-button {
        right: unset;
        left: 60px;
    }

    .user-details .reject-request {
        right: initial;
        left: 95px;
    }

    .meetings.dropdown-component .meeting-item .status-border {
        right: 0;
        left: initial;
    }

    .expanded .message .avatar-wrapper {
        order: 3;
    }

    .expanded .message .time-wrapper {
        order: -1;
    }

    .expanded .message.me .avatar-wrapper {
        order: 1;
    }

    .expanded .message.me .time-wrapper {
        order: 3;
    }

    .expanded .message.me .avatar-wrapper .user-avatar {
        margin-inline-end: 10px;
        margin-inline-start: 0;
    }

    .expanded .message .messages-wrappper {
        text-align: left;
        margin-inline-start: 14px;
        margin-inline-end: 0;

        p:last-child {
            text-align: left;
        }
    }

    .expanded .message.me .messages-wrappper {
        text-align: right;
        margin-inline-start: 0;
        margin-inline-end: 14px;

        p:last-child {
            text-align: right;
        }
    }

    .expanded .message .user-avatar {
        margin-inline-end: 0;
        margin-inline-start: 10px;
    }

    .single-post-container .postActions {
        right: initial;
        left: 0;
    }

    .networking-container .participant-avatar {
        order: 1;
        margin-inline-end: 0;
        margin-inline-start: 16px;
    }

    .networking-container .user-container .user-details {
        flex-flow: row-reverse;

        .user-name p {
            text-align: right;
        }
    }

    .chat-page {
        .chat-messages-list {
            padding: 0;
        }

        .scrollbar > div {
            margin-left: 0 !important;
            margin-right: auto !important;
        }
    }

    .participant-button.request-sent,
    .participant-button.connect-button,
    .participant-button.add-button,
    .new-meeting-button,
    .chatsPage .chat-page.edit,
    .left-header-wrapper #my-events span svg {
        left: 0;
        right: initial;
    }

    .left-header-wrapper #my-events {
        padding-left: 0;
        margin-left: 0;
        padding-right: 30px;
        margin-right: 20px;
        border-left: none;
        border-right: 1px solid #cacbd0;
    }

    .left-header-wrapper #my-events span {
        padding-left: 15px;
    }

    // .manage-booth-style label:before {
    //     left: 10px;
    //     right: initial;
    // }

    // .manage-booth-style label[data-checked='true']:after {
    //     left: 20.5px;
    //     right: initial;
    // }

    .organizer-navigation-container,
    .organizer-dashboard-page,
    .exhibitor-booth-view-page .booth-container,
    .showfloor-page,
    .login-page,
    .register-participant-step-1,
    .register-participant-step-2,
    .showfloor-page .booth,
    .auditoriums-popup {
        direction: ltr;
    }

    .exhibitor-booth-view-page .booth-visitors-container,
    .exhibitor-booth-view-page .booth-statistics-container,
    .exhibitors-slider .slider-title,
    .exhibitors-overlay {
        direction: rtl;
    }

    .exhibitors-slider .slider-title {
        text-align: right;
    }

    .friends-list {
        padding-right: 0;
    }

    .input-container {
        text-align: right;
    }
    .search {
        text-align: right;
    }
    .partici .showfloor-page {
        display: flex;
        flex-direction: row-reverse;
    }

    .booth-title-container {
        max-width: 1146px;
        margin-left: 0;
        margin-right: auto;
    }

    .login-page .email-wrapper,
    .login-page .password-wrapper {
        label {
            direction: rtl;
            text-indent: 24px;
        }

        input {
            direction: rtl;
        }
    }

    .login-page .email-wrapper .icon,
    .login-page .password-wrapper .icon {
        right: unset;
        left: 10px;
    }

    .auditoriums-navigation .button svg {
        transform: rotate(180deg);
    }

    .booth-wall-container .booth-wall-header .side-buttons-inner {
        flex-flow: row;
    }

    .booth-wall-container .booth-wall-header .side-button-container {
        margin-inline-start: 0;
        margin-inline-end: 20px;
        flex-flow: row-reverse;

        span {
            padding-inline-end: 0;
            padding-inline-start: 15px;
        }
    }

    .gdpr-container {
        direction: rtl;
    }

    .new-comment-container input {
        padding-right: 16px;
        padding-left: 0;
    }

    .auditorium-page .program-wrapper {
        padding-right: 0;
        padding-left: 10px;
    }

    .auditorium-page.dropdown-component .auditorium-name {
        text-align: right;
        padding-right: 10px;
        padding-left: 0;
    }

    .exhibitor-dashboard-page,
    .search-here {
        .MuiInputLabel-formControl {
            left: initial;
            right: 15px;
        }

        .MuiInputLabel-shrink {
            transform-origin: top right;
        }

        .booth-management-container .left-container {
            margin-right: 0;
            margin-left: 12px;

            .edit-label span {
                margin-left: 0;
                margin-right: 6px;
            }
        }

        .social-links-container {
            .link-container button {
                margin-left: 0;
                text-align: left;
            }

            .Button__title {
                margin-right: 8px;
            }

            .Button__icon {
                margin-right: 0;
            }
        }

        .BoothMarketplace {
            &__addProductButtonIcon {
                margin-left: 8px;
                margin-right: 0;
            }
        }
    }

    .exhibitor-representative-dialog .search-here {
        display: flex;
        justify-content: flex-start;
    }

    .register-participant-step-1 .error-text {
        text-align: right;
    }

    .main-menu-container .main-header-wrapper > div {
        flex: initial;
    }

    .main-menu-container .main-header-wrapper #chats span > div {
        left: initial;
        right: 50px;

        @media screen and (min-width: 1025px) and (max-width: 1299px) {
            right: 35px;
        }
    }

    .menu-bottom-wrapper .main-menu-container .main-header-wrapper #chats span > div {
        right: 20px;
    }

    .recover-password-dialog .MuiFormHelperText-root,
    .change-password-dialog .MuiFormHelperText-root {
        text-align: start;
    }

    #info-tab .user-container {
        display: flex;
        flex-flow: row-reverse;

        .user-details {
            flex-flow: row;
        }

        .participant-avatar {
            margin-inline-start: 0;
            margin-inline-end: 10px;
        }
    }

    .auditoriums-navigation-mobile .button.previous {
        transform: rotate(0);
    }

    .auditoriums-navigation-mobile .button {
        transform: rotate(180deg);
    }

    .auditorium-page.dropdown-component .page-title .arrow {
        &.next {
            left: 0;
            right: initial;

            svg {
                transform: rotate(0);
            }
        }

        &.prev {
            svg {
                transform: rotate(180deg);
            }
        }
    }

    .create-meeting-form .hours-container {
        flex-flow: row-reverse;
    }

    .back-to-live-button {
        left: calc(-100vw + 353px);
    }

    .on-demand-video-page.has-live-button {
        .slot-title {
            padding-right: 366px;
            padding-left: 240px;
        }
    }

    .auditorium-breadcrumbs {
        padding-right: 366px !important;
    }

    .slot-title {
        padding-right: 0;

        .title {
            text-align: right;
            padding-right: 366px;
        }
    }

    #my-account-mobile .edit-participant-info-container button {
        left: 16px;
        right: auto;
    }

    .footer-container .links-container .social-buttons-wrapper ul li {
        padding: 0 40px 0 0;

        &:first-child {
            padding-right: 0;
        }
    }

    @media screen and (max-width: 1024px) {
        .networking-container .user-container .user-details .social-links-container {
            margin-left: auto;
            margin-right: -50px;
        }
    }

    .videoWrapper.fullscreen {
        right: 0 !important;
    }
}
