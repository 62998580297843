.edit-user-information .update-button {
    color: var(--theme-primary-color);
}
.edit-user-information .form-dialog-title h2,
.edit-user-information label {
    font-family: inherit;
}
.edit-user-information form {
    padding-left: 0;
    padding-right: 0;
    max-width: 450px;
    width: 90%;
}
.edit-user-information form > div {
    margin-bottom: 20px;
}
.edit-user-information fieldset {
    background-color: transparent;
}
.edit-user-information input {
    color: var(--theme-text-secondary-color);
}
.edit-user-information .buttons-actions {
    padding-bottom: 10px;
}
.edit-user-information .buttons-actions .update-button {
    font-family: inherit;
    margin-right: 20px;
    padding-right: 0;
    &:disabled {
        color: #505050;
    }
}
