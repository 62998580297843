.info-tab {
    &__wrapper {
        position: relative;
    }

    .user-details {
        & > div:first-child {
            flex: 1;
        }

        & > div:last-of-type {
            width: 63px;
            min-height: 58px;
        }

        .user-name {
            margin: 0;

            p:last-child {
                max-width: initial;
            }
        }

        .social-button {
            display: inline-block;
            line-height: 1;
            margin-right: 6px;
        }
    }
}

@media screen and (max-width: 1024px) {
    .info-tab {
        & > div:last-of-type {
            min-height: initial;
        }

        &__scroll-container {
            padding: 0;
            height: calc(calc(var(--vh, 1vh) * 100) - 160px);
            background-color: var(--theme-background-default-color);
        }

        .representative-list {
            margin: 0;
            list-style-type: none;
            padding: 10px 0 0;

            li {
                background-color: var(--theme-background-paper-color);
                .connection,
                .user-name,
                .container-avatar {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    height: 100%;
                }
            }
        }

        .user-container {
            border-bottom: 1px solid rgba(137, 137, 137, 0.5);
            padding: 10px 15px;
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 75px;
        }

        .participant-avatar {
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            width: 40px;
            border-radius: 50%;
            margin: 0 15px;
        }

        .user-details {
            padding: 0;
            border: 0;
            display: flex;
            flex-direction: row;
            width: calc(100% - 75px);
            justify-content: space-between;
            text-align: left;

            .user-name-top {
                font-size: 16px;
                font-weight: 600;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .user-name p:last-child {
                margin-bottom: 0;
                font-size: 12px;
                font-weight: 500;
                color: var(--theme-text-grey-color);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .info-tab .user-details .user-name p:last-child {
        max-width: 150px;
    }
}
