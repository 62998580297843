@import './src/styles/mixins.scss';

.card {
    width: 100%;
    padding-bottom: 57%;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    margin-bottom: 15px;
    overflow: hidden;
    position: relative;
    &-container {
        background: var(--theme-white-color);
        display: flex;
        width: 100%;
        height: 100%;
        position: absolute;
    }
    &-section-divider {
        background-color: #000;
        width: 5%;
        height: 100%;
    }
    &-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 4%;
        overflow: hidden;
        width: 50%;
        @include xs {
            padding: 4% 2%;
        }
    }
    &-title-divider {
        margin-top: 15px;
        width: 100%;
        height: 2px;
        background-color: #000;
        @include xs {
            margin-top: 10px;
            height: 1px;
        }
    }
    &-title {
        font-weight: bold;
        font-size: 27px;
        text-transform: uppercase;
        color: #000000;
        position: relative;
        @include xs {
            font-size: 18px;
        }
    }
    &-subtitle {
        margin-top: 15px;
        font-size: 16px;
        text-transform: uppercase;
        color: #000000;
        @include xs {
            margin-top: 8px;
            font-size: 12px;
        }
    }
    &-logo {
        display: block;
        margin: 0 auto 25px;
        width: 100%;
    }
    &_contact {
        display: flex;
        font-weight: 600;
        max-width: 260px;
        font-size: 16px;
        color: var(--theme-white-color);
        word-break: break-word;
        align-items: center;
        a {
            color: inherit;
        }
        @include xs {
            font-weight: 400;
            font-size: 12px;
            line-height: 1.8;
        }
        &-icon {
            margin-right: 25px;
            @include xs {
                height: 0.7em;
                weight: 0.7em;
                margin-right: 8px;
            }
        }
    }
}
