.matching-dialog-reject-page {
    &__text {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: 0.0015em;
        text-align: left;
        max-width: 620px;
        margin: 0 auto;
        &:not(:last-child) {
            margin-bottom: 40px;
        }
    }
}

@media all and (max-width: 768px) {
    .matching-dialog-reject-page {
        &__text {
            font-size: 18px;
            font-weight: 400;
        }
    }
}
