.booth-video-actions-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    width: auto;
    padding: 0 10px;

    .fullscreen-button {
        width: 25px;
        height: 25px;
        background-color: transparent;
        border: 0;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;

        align-items: center;
        -webkit-justify-content: center;
        justify-content: center;
        outline: 0;
        cursor: pointer;
        padding: 0;

        img {
            max-width: 100%;
            height: auto;
        }
    }
}

.booth-video-container {
    position: relative;
    width: 500px;
    height: 281px;
    padding: 0 10px;
}

// TODO: refactor
.fullscreen .booth-video-dialog-container .booth-video-container {
    width: 100%;
    height: calc(100vh - 125px);
    padding: 0;
}

.booth-video-dialog-container #form-dialog-title {
    padding-top: 0;
}
