.booth-preview-container {
    width: 100%;
    flex: 1 1;

    &.no-exhibitors {
        height: 80%;
        opacity: 0.33;
    }

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        max-height: 100%;
        object-position: center center;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
    }
}

@media all and (max-height: 859px) {
    .booth-preview-container {
        height: calc(100vh - 150px);
    }
}
