.ChatButton {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 38px;
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
    font: inherit !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    outline: none !important;
    cursor: pointer !important;
    appearance: none !important;
    transition: opacity 0.3s ease-in-out;
    text-transform: uppercase;
    color: var(--theme-white-color);
    background: transparent;

    &.isRtl {
        margin: 0 0 0 10px !important;
    }

    &:hover {
        opacity: 0.8;
    }

    &:active {
        opacity: 1;
    }

    &_disabled {
        pointer-events: none;
        opacity: 0.6;
    }

    &__title {
        margin-top: -2px;
        position: absolute;
        top: 12px;
        padding: 0 10px;
        line-height: 0.9;
    }
}
