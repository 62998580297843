.AccountGrid {
    display: flex;
    flex-direction: column;
    min-height: 100%;

    &__menu {
        flex: 0 0 auto;
        z-index: 4;
        position: fixed;
        width: 100%;
    }
    &__pagesScene {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        padding-top: 47px;
        &.hideMenu {
            padding-top: 0;
        }
    }
}
