@import '../../../styles/mixins.scss';

.session_dashboard_page {
    position: relative;
    margin: 0 auto;
    height: 100vh;
    background-color: var(--theme-background-default-color);
    overflow: hidden;
    max-width: 100%;

    @include xs {
        padding-right: 0;
        overflow: auto;
    }
    &.isFullScreen {
        overflow: initial;
    }
    &-container {
        max-width: 1430px;
        display: flex;
        width: calc(100% - 323px);
        padding: 16px 28px;
        height: calc(100% - 70px);

        @include xs {
            flex-direction: column;
            width: 100%;
            padding: 16px 10px;
            height: auto;
        }
    }
    &-column {
        display: flex;
        flex-direction: column;
        min-width: 452px;
        &:not(:last-child) {
            margin-right: 20px;
            min-width: 588px;
            @include xs {
                margin-right: 0;
            }
        }
        @include xs {
            min-width: 0;
            flex-direction: column;
            max-width: 100%;
            width: 100%;
        }
    }
    .module {
        color: var(--theme-white-color);
        display: flex;
        flex-direction: column;
        @include xs {
            margin-bottom: 15px;
        }
        &:not(:last-child) {
            margin-bottom: 10px;
        }
        &:last-child {
            flex-grow: 1;
        }

        &-container {
            border-radius: 8px;
            box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
            overflow: hidden;
            height: 100%;
            display: flex;
            flex-direction: column;
        }
        &-header {
            display: flex;
            align-items: center;
            height: 48px;
            background-color: var(--theme-background-default-color);
            padding: 0 10px;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #2b2b2b;
            border-bottom: 1px solid #cacbd0;

            .d-flex {
                align-items: center;
            }
        }
        &-body {
            background-color: var(--theme-white-color);
            // height: 100%;
            @include xs {
                min-height: 200px;
            }
        }
        &-video_container {
            position: relative;
            height: 342px;
            overflow: hidden;
            background-color: #000;
        }
        &-link_block {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            cursor: pointer;
            color: var(--theme-text-primary-color);
            display: flex;
            align-items: center;
            padding: 0 12px;
            height: 55px;
            justify-content: space-between;
            &:not(:last-child) {
                border-bottom: 1px solid #e6e6e6;
            }
        }
    }

    .buttons_group {
        display: flex;
        align-items: center;
        margin-top: 40px;

        .tip {
            font-weight: 500;
            font-size: 14px;
            color: var(--theme-secondary-color);
        }
    }
    .button {
        font-weight: bold;
        font-size: 16px;
        line-height: 1.2;
        cursor: pointer;
        letter-spacing: 0.0125em;
        text-transform: uppercase;
        color: var(--theme-text-primary-color);
        outline-color: rgba(var(--theme-text-primary-color), 0.2);
        background-color: transparent;
        padding: 10px 15px;
        border: none;
        display: flex;
        align-items: center;
        &.-colored {
            color: var(--theme-primary-color);
            outline-color: rgba(var(--theme-primary-color), 0.2);
        }
        &_icon {
            color: currentColor;
            margin-right: 10px;
        }
        &:not(:last-child) {
            margin-right: 15px;
        }
    }
    .screen_is_recrding {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border: 4px solid var(--theme-primary-color);
        pointer-events: none;
        z-index: 999;
    }
    .time-to-end-popup {
        position: fixed;
        width: 100%;
        display: flex;
        left: 0;
        bottom: 0;
        z-index: 110;
        font-weight: 500;
        font-size: 14px;
        line-height: 95.9%;
        display: flex;
        align-items: center;
        color: var(--theme-white-color);
        align-items: center;
        justify-content: space-between;
        padding: 0 32px;
        height: 72px;
        background: linear-gradient(180deg, #a8261c 0%, #bd2d21 100%);
        button {
            border: 1px solid --theme-white-color;
            box-sizing: border-box;
            border-radius: 8px 8px 0px 8px;
            font-weight: bold;
            background-color: transparent;
            padding: 10px 65px;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            text-align: center;
            cursor: pointer;
            letter-spacing: 0.0125em;
            text-transform: uppercase;

            /* white */

            color: var(--theme-white-color);
        }
    }

    &__snackbar {
        .MuiSnackbarContent-message {
            width: 100%;
            text-align: center;
        }
    }

    .booth-wall-container {
        .booth-wall-header {
            &.withPadding {
                padding-left: 20px;
            }
        }
    }
}

.checkUserMediaMobile {
    align-items: flex-start !important;
}
