.cancel-button {
    background-color: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    font-weight: bold;
    font-size: 12px;
    padding: 0;
    margin-left: 30px;
}
