.video-conference-container {
    position: absolute;
    top: 48px;
    z-index: 50;
    width: calc(100% - 68px);
    height: 252px;
    background: #000;

    .video-conference-buttons {
        background-color: rgba(#000, 0.5);
        bottom: 0;
        padding: 5px 0;
    }

    &.fullscreen {
        position: fixed;
        left: 0;
        top: 70px;
        width: 100%;
        height: calc(100vh - 118px);
    }

    &.hidden {
        display: none;
    }

    & > div,
    #otEmbedContainer {
        width: 100%;
        height: 100%;
    }

    .conference-buttons-container {
        position: absolute;
        cursor: pointer;
        top: 100%;
        background-color: var(--theme-primary-color);
        width: 100%;
        height: 48px;
        display: flex;
        justify-content: center;
        z-index: 111;

        div {
            display: flex;
            align-items: center;

            span {
                font-size: 16px;
            }
        }

        button {
            font-family: 'Montserrat', sans-serif;
            background-color: transparent;
            border: 0;
            outline: 0;
            font-weight: bold;
            font-size: 14px;
            color: var(--theme-white-color);
            text-transform: uppercase;
            cursor: pointer;

            svg {
                width: 24px;
                height: 24px;
                margin-right: 8px;
            }

            &.toggle-fullscreen svg path {
                fill: var(--theme-white-color) !important;
            }
        }
    }

    iframe {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        max-width: 100%;
        border: 0;
    }
}

.expanded .video-conference-container {
    width: calc(100% - 270px);
}

@media screen and (max-width: 767px) {
    .video-conference-container {
        top: 0;
    }
}

@media screen and (max-width: 1024px) {
    .video-conference-container.fullscreen {
        top: 56px;
        height: calc(100% - 160px) !important;
    }
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
    .expanded .video-conference-container {
        height: 300px;
    }
}
