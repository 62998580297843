.gdpr-container {
    max-width: 1224px;
    margin: 0 auto;
    padding: 60px 30px;
    color: var(--theme-text-primary-color);
}
.gdpr-page {
    &.isSca {
        direction: rtl;
    }
    h1 {
        margin-top: 0;
    }
    h2 {
        margin-top: 40px;
    }
    p {
        margin-top: 10px;
        margin-bottom: 0;
    }
    a {
        color: var(--theme-secondary-color);
        word-break: break-word;
    }
}

@media all and (max-width: 767.9px) {
    .gdpr-container {
        padding: 30px 30px 0;
    }
    .gdpr-page {
        h1 {
            margin-top: 0;
            font-size: 24px;
        }
        h2 {
            margin-top: 30px;
            font-size: 20px;
        }
    }
}
