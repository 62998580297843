.MyAccountDropdownLink {
}

.MyAccountDropdownLink_notDesktop {
}

.MyAccountDropdownLink__element {
    display: inline-flex;
    align-items: center;
    width: 100%;
    color: #000;
    transition: opacity 0.2s ease-in-out;
}

.MyAccountDropdownLink_notDesktop .MyAccountDropdownLink__element {
    flex-direction: row-reverse;
}

.MyAccountDropdownLink__element:hover {
    opacity: 0.8;
}

.MyAccountDropdownLink__element:active {
    opacity: 1;
}

.MyAccountDropdownLink__icon {
    flex: 0 0 25% !important;
    display: inline-block;
}

.MyAccountDropdownLink_notDesktop .MyAccountDropdownLink__icon {
    flex: 0 0 auto !important;
    width: auto !important;
    padding-right: 0 !important;
}

.MyAccountDropdownLink__title {
    flex: 1 1 auto;
    font-size: 12px;
    text-transform: none !important;
}

.MyAccountDropdownLink_notDesktop .MyAccountDropdownLink__title {
    font-size: 16px;
    font-weight: 600;
}

/* Compelled code */
.MyAccountDropdownLink a {
    width: 100% !important;
    display: inline-flex;
    padding-top: 0 !important;
    font-weight: inherit !important;
    color: inherit !important;
}
