@import 'styles/mixins.scss';

.session-dashboard-block {
    color: var(--theme-white-color);
    display: flex;
    flex-direction: column;
    margin: 0 0 15px 15px;
    width: 520px;

    &.fullWidth {
        width: 100%;
        margin-left: 0;
    }

    &__container {
        border-radius: 8px;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
        overflow: hidden;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    &__header {
        display: flex;
        align-items: center;
        height: 48px;
        background-color: var(--theme-background-default-color);
        padding: 0 10px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #2b2b2b;
        border-bottom: 1px solid var(--theme-border-grey-color);

        .d-flex {
            align-items: center;
        }
    }
    &__body {
        background-color: var(--theme-white-color);
        @include xs {
            min-height: 200px;
        }
    }

    &.bordered {
        .session-dashboard-block__container {
            border-radius: 6px;
        }

        .session-dashboard-block__header {
            border: 1px solid var(--theme-border-grey-color);
            box-sizing: border-box;
            border-radius: 6px 6px 0px 0px;
        }
    }
}
