.MobileAgendaProgramItem {
    font-weight: 500;
}

.MobileAgendaProgramItem__mainRow {
    display: flex;
    align-items: center;
    padding: 8px 12px;
}

.MobileAgendaProgramItem__title {
    flex: 1 1 auto;
}

.MobileAgendaProgramItem__timeGroup {
    flex: 0 0 auto;
    margin-right: 16px;
    font-weight: 700;
    text-align: right;
}

.MobileAgendaProgramItem__timeItem {
}

.MobileAgendaProgramItem__actionGroup {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
}

.MobileAgendaProgramItem__actionItem {
    margin-right: 8px;

    &:last-child {
        margin-right: 0;
        color: var(--theme-primary-color);
    }

    &_hidden {
        pointer-events: none;
        visibility: hidden;
    }
}

.MobileAgendaProgramItem__metaRow {
    display: flex;
    align-items: center;
    padding: 4px 0;
    background-color: #eaeaea;
    font-size: 12px;
    color: #898989;
}

.MobileAgendaProgramItem__metaItem {
    flex: 0 0 25%;
    max-width: 25%;
    padding: 0 8px;
    border-right: 1px solid #898989;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;

    &:last-child {
        border-right: none;
    }
}
