.language-container {
    font-size: 14px;
    background-color: var(--theme-background-default-color);
    flex: 1 1;
    padding: 10px;
    text-align: left;
    .title {
        margin: 0;
        color: #2b2b2b;
        font-size: 16px;
        line-height: 20px;
        padding: 0 16px;
        white-space: pre-line;
        color: var(--theme-primary-color);
        font-weight: 600;
        text-transform: uppercase;
        padding: 24px 16px 8px;
    }
    .language-list {
        list-style-type: none;
        margin: 0;
        position: relative;
        padding: 0;
        font-size: 16px;
        line-height: 20px;
        width: 100%;
    }
    .language-list-item {
        padding: 0px 16px;
    }
}
