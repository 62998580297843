//Media
$xs-width: 475px;
$sm-width: 768px;
$md-width: 992px;
$lg-width: 1200px;

// desk 1440
// tabl 768px
// mobile 475px

@mixin xs {
    @media (max-width: #{$xs-width}) {
        @content;
    }
}

@mixin sm {
    @media (max-width: #{$sm-width}) {
        @content;
    }
}

@mixin md {
    @media (min-width: #{$sm-width}) and (max-width: #{$md-width - 1px}) {
        @content;
    }
}

@mixin lg {
    @media (min-width: #{$md-width}) and (max-width: #{$lg-width - 1px}) {
        @content;
    }
}
@mixin xl {
    @media (min-width: #{$lg-width}) {
        @content;
    }
}

@mixin clickableElement($hover: true) {
    position: absolute;
    transform: translate(-50%, -50%);
    padding: 0;
    margin: 0;
    background: transparent;
    transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    cursor: pointer;
    @if $hover {
        &:hover {
            box-shadow: 0 0 3vh 1px var(--theme-active-color);
            transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }
    } @else {
        cursor: initial;
        &:hover {
            box-shadow: none;
            transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }
    }
}

@mixin snackbar {
    div {
        margin-left: auto;
        margin-right: auto;
    }
}

@mixin dropdownComponent {
    position: absolute;
    background-color: rgba(239, 237, 237, 0.85);
    top: 70px;
    width: 730px;
    overflow: auto;
    z-index: 5;
}

@mixin calculateVHeight($value: 0px, $important: false) {
    @if $important {
        height: calc(calc(var(--vh, 1vh) * 100) - #{$value}) !important;
    } @else {
        height: calc(calc(var(--vh, 1vh) * 100) - #{$value});
    }
}
