/* Color variables */
body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;
}
html {
    box-sizing: border-box;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}
.top-container {
    position: relative;
    z-index: 999;
    width: 100%;
}
.purple-background {
    background-color: var(--theme-secondary-color);
}
.purple-color {
    color: var(--theme-secondary-color);
}
.primary-background {
    background-color: var(--theme-primary-color);
}

@media screen and (max-width: 1024px) {
    .App.event-page {
        display: flex;
        justify-content: normal;
        align-items: normal;
        flex: initial;
        flex-direction: column;
        height: 100% !important;
        max-height: 100% !important;
        width: 100vw !important;
        max-width: 100vw !important;
        position: fixed !important;
        top: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
    }
    .position-background {
        position: relative;
        overflow: initial;
        font-size: 16px;
        overflow: auto;
    }
    .mobile-page-container {
        position: initial;
        overflow: auto;
        padding-bottom: 56px;
    }
    .centerOfPage {
        position: initial;
        top: initial;
        left: initial;
        width: auto;
        height: auto;
    }
    .relativeCenter {
        position: static;
    }
}
