.auditorium-archive-page {
    position: relative;
}

.auditorium-archive-page .smallContainer {
    max-width: 1160px;

    &.fullscreen {
        position: static;
    }
}

.auditorium-archive-page .auditoriums-archive-container {
    height: calc(100vh - 190px);

    .scrollbar {
        .box {
            margin-right: 0 !important;
        }
    }
}

.auditorium-archive-page .wall-and-networking-container.close {
    position: fixed;
}

.auditorium-archive-page .wall-and-networking-container.close + .program-dashboard {
    overflow: visible;
    height: auto;
}

.auditorium-archive-page .program-dashboard {
    background-color: var(--theme-background-default-color);
    overflow: auto;
    height: calc(100vh - 70px);
}

.auditorium-archive-page .program-section {
    padding-top: 48px;
    margin-bottom: 0;
}

.auditorium-archive-page .program-section .page-title {
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    padding: 0 18px;
}

.auditorium-archive-page {
    .program-section .page-title .name {
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        color: var(--theme-text-secondary-color);
        padding-right: 26px;
        text-transform: uppercase;
    }

    &.isRtl {
        .program-section .page-title .name {
            padding-left: 26px;
            padding-right: 0;
        }
    }
}

.auditorium-archive-page .program-section .page-title input {
    background: var(--theme-background-paper-color);
    border: 1px solid #cacbd0;
    border-radius: 8px;
    padding: 8px 60px 8px 16px;
    color: rgba(43, 43, 43, 0.5);
    outline: none;
    font-size: 14px;
    line-height: 30px;
    width: 400px;
    font-family: inherit;
}

.auditorium-archive-page .program-section .page-title .search-container svg {
    position: absolute;
    right: 16px;
    top: 11px;
    cursor: pointer;
}

.auditorium-archive-page .program-dashboard .program-entry {
    padding: 0 8px 32px;
    border-bottom: none;
}

.auditorium-archive-page .single-auditorium-archive-container {
    margin-bottom: 32px;
    border-bottom: 1px solid rgba(202, 203, 208, 0.5);
}

.auditorium-archive-page .single-auditorium-archive-container:last-of-type {
    margin-bottom: 0;
}

.auditorium-archive-page .single-auditorium-archive-container h4 {
    margin-top: 0;
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    color: var(--theme-secondary-color);
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    padding-left: 8px;
    text-transform: capitalize;
}

.auditorium-archive-page .single-auditorium-archive-container h4 .break,
.auditorium-archive-page .single-auditorium-archive-container h4 .dates {
    font-size: 16px;
    line-height: 20px;
    color: var(--theme-text-secondary-color);
}

.auditorium-archive-page .single-auditorium-archive-container h4 .break {
    padding: 0 16px;
}

.auditorium-archive-page .image-preview-video {
    position: relative;
    height: 160px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border: 0.5px solid var(--theme-white-color);
    border-radius: 8px;
}

.auditorium-archive-page {
    .video-preview-container {
        width: 270px;
        height: 160px;
        max-width: 100%;
    }
}

.auditorium-archive-page .play-button {
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: 12%;
}

.auditorium-archive-page .video-duration {
    position: absolute;
    bottom: 8px;
    right: 0;
    color: var(--theme-white-color);
    background-color: var(--theme-secondary-color);
    width: 56px;
    line-height: 18px;
    text-align: center;
    font-size: 14px;
}

.auditorium-archive-page .image-preview-video img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.auditorium-archive-page .video-details-container .speakers-container {
    padding-top: 10px;
}

.auditorium-archive-page .video-details-container .speakers-container .speaker {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.04em;
    color: var(--theme-secondary-color);
    padding: 0;
    display: inline;
}

.auditorium-archive-page .video-details-container .program-date-hash .date {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.04em;
    color: var(--theme-text-secondary-color);
}

.auditorium-archive-page .video-details-container .program-date-hash .hashtag {
    padding-left: 5px;
    letter-spacing: -0.04em;
    color: var(--theme-secondary-color);
    word-break: break-word;
}

.auditorium-archive-page .video-details-container a {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--theme-text-primary-color);
    letter-spacing: -0.04em;
    word-break: break-word;
    display: block;
    padding: 5px 0;
}

.program-entry .program-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    padding-bottom: 10px;
    border-bottom: 0.5px solid #cacbd0;
    margin-bottom: 10px;
}

.program-entry .program-container.is-empty {
    border-bottom: none;
}

.program-entry .program-container.is-empty .empty-program {
    width: 100%;
}

.program-entry:last-child .program-container {
    padding-bottom: 25px;
}

.program-container .topic {
    color: var(--theme-secondary-color);
    word-break: break-word;
    font-weight: bold;
}

.program-container .topic.is-link {
    cursor: pointer;
}

@media screen and (max-width: 1024px) {
    .auditorium-archive-page .program-section .page-title .search-container {
        margin-top: 16px;
        width: 100%;
        max-width: 500px;
    }

    .auditorium-archive-page .program-section .page-title input {
        width: 100%;
    }

    .auditorium-archive-page .program-section .page-title {
        padding: 0 16px;
        display: flex;
        flex-flow: column;
    }
}
