.matchmaking-dialog {
    &__paper-width-sm {
        max-width: 780px !important;
        border-radius: 6px 6px 0px 0px;
    }
    &__title-wrapper {
        height: 120px;
        box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.07), 0px 0px 7px rgba(0, 0, 0, 0.12), 0px 5px 12px rgba(0, 0, 0, 0.2);
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        color: var(--theme-text-primary-color);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
    }
    &__title-close-btn {
        position: absolute !important;
        top: 10px;
        right: 10px;
    }

    &__title-text-wrapper {
        width: 100%;
    }

    &__title {
        margin: 0 auto;
        max-width: 500px;
        text-align: center;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 5px;
    }

    &__content-wrapper {
        height: 450px;
        width: 780px;
        padding: 24px !important;
    }

    &__pagination-ul {
        justify-content: center;
    }

    &__pagination-root {
        margin-bottom: 5px;
    }

    &__page-item {
        display: block;
        color: var(--theme-primary-color);
        height: 16px;
        width: 16px;

        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: -0.4px;
        text-align: center !important;
        margin: 0 2px;

        border-radius: 50%;
        &--selected {
            background: var(--theme-primary-color);
            color: var(--theme-white-color);
        }
    }

    &__actions-wrapper {
        height: 100px;
        border-top: 1px solid #cacbd0;
        display: flex;
    }

    &__action-tab {
        display: flex;
        padding: 0 70px;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    &__action-tab-btn {
        &:not(:last-child) {
            margin-right: 24px;
        }

        font-size: 14px !important;
        font-weight: 600 !important;
        letter-spacing: 0em !important;
        height: 54px;
        width: 180px;
        border-radius: 6px !important;
    }

    &__question-info-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__question-type {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.4px;
        text-align: center;
    }
}

@media all and (max-width: 768px) {
    .matchmaking-dialog {
        &__paper-width-sm {
            border-radius: 0 !important;
        }
        &__title-wrapper {
            height: 80px;
            box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
            font-size: 16px;
            position: unset;
        }

        &__title-close-btn {
            top: 50%;
            transform: translateY(-50%);

            left: -20px;
            right: unset;
        }

        &__title-text-wrapper {
            position: relative;
            width: 100%;
        }

        &__title {
            max-width: 90%;
        }

        &__content-wrapper {
            height: 100%;
            width: 100%;
            padding: 24px 16px !important;
        }

        &__actions-wrapper {
            height: 76px;
        }

        &__action-tab {
            padding: 0 16px;
        }

        &__action-tab-btn {
            &:not(:last-child) {
                margin-right: 16px;
            }

            height: 44px;
            width: 150px;
        }

        &__question-type {
            display: none;
        }
    }
}
