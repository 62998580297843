@import '../../../../../../../../../styles/mixins.scss';

.business-wallet {
    display: flex;
    height: 48px;
    justify-content: center;
    background: var(--theme-background-paper-color);
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);

    @include xs {
        font-size: 12px;
    }

    &__tab {
        cursor: pointer;
        padding: 0 16px;
        height: 100%;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        color: #898989;
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        transition: color 0.2s ease-out;
        position: relative;

        &-count {
            position: absolute;
            right: -5px;
            top: 3px;
            background-color: var(--theme-primary-color);
            border-radius: 100%;
            align-items: center;
            font-size: 12px;
            color: var(--theme-white-color);
            $sz: 20px;
            width: $sz;
            height: $sz;
            display: flex;
            justify-content: center;

            @include xs {
                $sz: 18px;
                width: $sz;
                height: $sz;
            }
        }

        &:after {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            opacity: 0;
            transition: opacity 0.2s ease-out;
            background-color: var(--theme-secondary-color);
            content: '';
        }

        &.is-active {
            color: var(--theme-secondary-color);

            &:after {
                opacity: 1;
            }
        }
    }
}
