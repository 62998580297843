@media screen and (max-width: 1024px) {
    .exhibitors-overlay-mobile h4 {
        background-color: var(--theme-primary-color);
        color: var(--theme-white-color);
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        font-size: 16px;
        line-height: 20px;
        padding: 12px 16px;
        cursor: pointer;
    }
}
