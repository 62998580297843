.homepage-container {
    max-width: 1224px;
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 30px;
    &.social {
        display: flex;
        justify-content: space-around;
        flex-wrap: nowrap;

        & > div {
            padding: 5px 0;
        }
    }
}

.homepage {
    .top-section {
        position: relative;
        color: var(--theme-white-color);

        h1 {
            letter-spacing: -0.015em;
            font-weight: 500;
            font-size: 90px;
            line-height: 90%;
            overflow-wrap: break-word;
        }

        .background-container {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .overlay {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: rgba(29, 29, 27, 0.7);
            }
        }

        .homepage-container {
            padding-top: 170px;
            padding-bottom: 50px;
        }

        .event-details-container {
            display: flex;
            justify-content: space-between;

            .main-details {
                flex: 1;
                .details-information {
                    max-width: 700px;
                    p {
                        margin: 20px 0;
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 36px;
                        color: var(--theme-background-default-color);
                    }
                }
                .organizer-name {
                    white-space: pre;
                    color: var(--theme-primary-color);
                }
            }
        }

        .extra-details {
            border: 1px solid --theme-white-color;
            border-radius: 8px;
            padding: 20px;
            width: 350px;
            p {
                margin: 0;
            }
            p.label {
                color: #cacbd0;
                font-weight: 600;
                font-size: 24px;
                line-height: 36px;
                margin-bottom: 10px;
            }
            p.information {
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
            }
        }
        .single-detail-container {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .program-section {
        margin-bottom: 100px;
        .tabs-header {
            background: var(--theme-secondary-color);
            box-shadow: 0 -1px 4px rgba(31, 37, 86, 0.25);
            margin-bottom: 50px;
            min-height: 42px;
        }
        .tabs-header.secondary-colors {
            background: var(--theme-primary-color);
        }
        .tabs-name-container {
            display: flex;
        }

        .tab-name {
            padding: 11px 0;
            text-align: center;
            min-width: 200px;
            position: relative;
            cursor: pointer;
            span {
                color: var(--theme-white-color);
                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                letter-spacing: 0.0125em;
            }
        }
        .tab-name.active {
            background-color: var(--theme-white-color);
            &:before {
                content: '';
                position: absolute;
                height: 8px;
                width: 100%;
                bottom: 100%;
                left: 0;
                background-color: var(--theme-white-color);
                border-radius: 8px 8px 0 0;
            }
            &:after {
                content: '';
                position: absolute;
                height: 5px;
                width: 100%;
                top: 100%;
                left: 0;
                background-color: var(--theme-white-color);
            }
            span {
                color: var(--theme-secondary-color);
            }
        }
        .secondary-colors {
            .tab-name.active {
                span {
                    color: var(--theme-primary-color);
                }
            }
        }
        .tabs-content {
            .event-description-container {
                display: flex;
                justify-content: space-between;
                .description {
                    flex: 1;
                    padding-right: 10%;
                    &.isRtl {
                        padding-right: 0;
                        padding-left: 10%;
                    }
                    p {
                        max-width: 700px;
                        text-align: justify;
                        margin: 0;
                        font-size: 18px;
                        line-height: 27px;
                        color: var(--theme-text-primary-color);
                        overflow-wrap: break-word;
                    }
                }
                .video-container {
                    width: 290px;
                    height: 200px;
                    position: relative;
                    margin-bottom: 40px;
                    cursor: pointer;
                    img.background {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border: 1px solid --theme-white-color;
                        border-radius: 8px;
                        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
                        box-sizing: border-box;
                        padding: 1px;
                    }
                    .video-overlay {
                        display: flex;
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        justify-content: center;
                        align-items: center;
                        svg {
                            width: 30px;
                            height: auto;
                        }
                    }
                }
                .random-banner {
                    width: 290px;
                    height: 200px;
                    position: relative;
                    a {
                        display: block;
                        width: 100%;
                        height: 100%;
                        font-size: 0;
                    }
                    img.background {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        border: 1px solid --theme-white-color;
                        border-radius: 8px;
                        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
                        box-sizing: border-box;
                        padding: 1px;
                    }
                }
            }
            .single-auditorium-program {
                margin-bottom: 50px;
                &:last-child {
                    margin-bottom: 0;
                }
            }

            .auditorium-name {
                color: var(--theme-secondary-color);
                margin-top: 0;
                margin-bottom: 40px;

                .title {
                    font-weight: bold;
                    font-size: 42px;
                    margin: 0;
                }

                .timezone {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                }
            }

            .single-day-program-wrapper {
                display: flex;
                margin-bottom: 100px;
            }

            .program-date {
                font-weight: bold;
                margin: 0;
                color: var(--theme-secondary-color);
                width: 200px;
                flex: 0 0 200px;
                padding-right: 24px;
                .current-date {
                    display: flex;
                    align-items: center;
                    .first {
                        font-size: 42px;
                        line-height: 42px;
                    }
                    .second {
                        display: flex;
                        flex-flow: column;
                        padding-left: 8px;
                        font-size: 16px;
                        line-height: 21px;
                        text-transform: uppercase;
                        font-weight: 600;
                    }
                }
                .buildings-list {
                    display: flex;
                    flex-flow: column;
                    align-items: center;
                    margin-top: 40px;
                    .name {
                        margin: 0;
                        padding: 16px 0;
                        color: #2b2b2b;
                        font-size: 16px;
                        line-height: 20px;
                        text-transform: uppercase;
                        cursor: pointer;
                        &.active {
                            background: var(--theme-primary-color);
                            box-shadow: 0px -1px 4px rgba(31, 37, 86, 0.25);
                            color: var(--theme-white-color);
                            border-radius: 8px;
                            width: 100%;
                            text-align: center;
                        }
                    }
                }
            }

            .auditoriums-single-day-program-wrapper {
                flex: 1;
                overflow: hidden;
                .single-building-program {
                    display: none;
                    &.active {
                        display: block;
                    }
                }
            }

            .program-list-container {
                border: none;
                border-radius: 0;
                padding: 0;
                margin: 0;
                list-style: none;
                columns: 2;
                column-gap: 24px;
            }

            .program-entry {
                page-break-inside: avoid;
                position: relative;
                .program-time {
                    color: var(--theme-text-primary-color);
                    font-size: 24px;
                    line-height: 36px;
                }
                &:last-child {
                    padding-bottom: 0;
                }
            }

            .go-to-tab-button {
                max-width: 194px;
                background: var(--theme-primary-color);
                box-shadow: 0 1px 6px rgba(0, 0, 0, 0.13);
                border-radius: 8px;
                height: 38px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: var(--theme-white-color);
                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.0125em;
                margin-top: 30px;
                cursor: pointer;
                transition: all 0.12s ease-in-out;
                outline: none;
                border: 1px solid transparent;
                &:hover {
                    box-shadow: 0px 14px 24px rgba(32, 15, 54, 0.25);
                    transition: all 0.12s ease-in-out;
                }
                &:focus {
                    background-color: var(--theme-white-color);
                    transition: all 0.12s ease-in-out;
                    border: 1px solid var(--theme-primary-color);
                    color: var(--theme-primary-color);
                }
            }
            .go-to-tab-button.secondary-color {
                background: var(--theme-secondary-color);
                &:focus {
                    background-color: var(--theme-white-color);
                    transition: all 0.12s ease-in-out;
                    border: 1px solid var(--theme-secondary-color);
                    color: var(--theme-secondary-color);
                }
            }

            .building-name {
                display: none;
            }
        }
    }

    .partners-section {
        margin-bottom: 100px;
        .tabs-header {
            background: var(--theme-secondary-color);
            box-shadow: 0 -1px 4px rgba(31, 37, 86, 0.25);
            margin-bottom: 40px;
        }
        .tabs-header.secondary-colors {
            background: var(--theme-primary-color);
        }
        .tabs-name-container {
            display: flex;
        }
        .tab-name {
            padding: 11px 0;
            text-align: center;
            min-width: 200px;
            position: relative;
            cursor: pointer;
            span {
                color: var(--theme-white-color);
                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                letter-spacing: 0.0125em;
            }
        }
        .tab-name.active {
            background-color: var(--theme-white-color);
            &:before {
                content: '';
                position: absolute;
                height: 8px;
                width: calc(100% + 4px);
                top: -8px;
                left: -2px;
                background-color: var(--theme-white-color);
                border-top: 2px solid var(--theme-secondary-color);
                border-left: 2px solid var(--theme-secondary-color);
                border-right: 2px solid var(--theme-secondary-color);
                border-radius: 8px 8px 0 0;
            }
            &:after {
                content: '';
                position: absolute;
                height: 5px;
                width: 100%;
                top: 100%;
                left: 0;
                background-color: var(--theme-white-color);
            }
            span {
                color: var(--theme-secondary-color);
            }
        }
        .secondary-colors {
            .tab-name.active {
                span {
                    color: var(--theme-primary-color);
                }
                &:before {
                    border-top: 2px solid var(--theme-primary-color);
                    border-left: 2px solid var(--theme-primary-color);
                    border-right: 2px solid var(--theme-primary-color);
                }
            }
        }
        .top-slider {
            position: relative;
            overflow: hidden;
            .arrow {
                background: var(--theme-background-paper-color);
                box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 150px;
                height: 150px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                cursor: pointer;
            }
            .arrow.previous {
                left: -75px;
                svg {
                    margin-left: 60px;
                }
            }
            .arrow.next {
                right: -75px;
                svg {
                    margin-right: 60px;
                    transform: rotate(180deg);
                }
            }
            .slick-current {
                .slider-image-container {
                    &:after {
                        content: '';
                        height: 8px;
                        width: 100%;
                        bottom: 0;
                        left: 0;
                        border-radius: 0 0 8px 8px;
                        background-color: var(--theme-secondary-color);
                        position: absolute;
                    }
                }
            }
        }
        .top-slider-container {
            .slick-list {
                padding: 10px 0;
            }
            .slider-image-container {
                box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
                border-radius: 8px;
                padding: 16px;
                width: auto;
                height: auto;
                max-width: initial;
                margin: 0 12px;
                position: relative;
                img {
                    width: 100%;
                    height: 116px;
                    margin: 0;
                }
                p {
                    width: 100%;
                    height: 116px;
                    margin: 0;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-break: break-word;
                }
            }
        }
        .exhibitors {
            .top-slider {
                .slick-current {
                    .slider-image-container {
                        &:after {
                            background-color: var(--theme-primary-color);
                        }
                    }
                }
            }
            .bottom-slider-container {
                .partner-details-container {
                    .logo {
                        border-left-color: var(--theme-primary-color);
                    }
                }
            }
        }
        .bottom-slider-container {
            margin-top: 40px;
            .slick-list {
                transition: height 0.3s ease-in-out;
            }
            .partner-details-container {
                display: flex;
                .main-details {
                    flex: 1;
                    padding-right: 100px;
                    display: flex;
                    align-items: center;
                    .info {
                        max-width: 700px;
                        width: 100%;
                    }
                    p {
                        margin: 0;
                        font-size: 18px;
                        text-align: justify;
                        line-height: 27px;
                        letter-spacing: 0.005em;
                        color: var(--theme-text-primary-color);
                        margin-bottom: 58px;
                    }
                    .contact {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        h2 {
                            display: block;
                            font-weight: normal;
                            max-width: 512px;
                            cursor: pointer;
                        }
                        .links-container {
                            display: flex;
                            align-items: flex-end;
                            justify-content: flex-end;
                            .webpage-link {
                                font-size: 24px;
                                line-height: 36px;
                                text-align: right;
                                padding-left: 50px;
                                max-width: 350px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                            a {
                                margin-left: 20px;
                                color: var(--theme-text-primary-color);
                                &:first-child {
                                    margin-left: 0;
                                }
                            }
                        }
                    }
                    h2 {
                        margin: 0;
                        font-size: 34px;
                        line-height: 44px;
                        color: var(--theme-text-primary-color);
                        display: none;
                    }
                }
                .logo {
                    position: static;
                    width: 290px;
                    display: flex;
                    align-items: center;
                    padding: 0 75px;
                    border-left: 2px solid var(--theme-secondary-color);
                    .partner-logo-container {
                        width: auto;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                            object-fit: contain;
                        }
                    }
                }
            }
        }
    }

    .previous-events-section {
        background-color: var(--theme-background-default-color);
        padding: 50px 0 100px;
        h3 {
            font-weight: bold;
            font-size: 42px;
            line-height: 42px;
            margin: 0;
            color: var(--theme-text-primary-color);
        }
        .links-container {
            display: flex;
            flex-flow: column;
            margin-top: 40px;
            .link-container {
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            a {
                font-weight: 600;
                font-size: 24px;
                line-height: 36px;
                color: var(--theme-secondary-color);
            }
        }
    }

    .partner-details-container {
        &.isRtl {
            direction: rtl;

            .logo {
                border-left: 0 !important;
                border-right: 2px solid var(--theme-secondary-color);
            }

            .main-details .contact .links-container a {
                margin: 0 20px 0 0 !important;
            }
        }
    }
}

@media all and (max-width: 1199.9px) {
    .homepage-container {
        max-width: 992px;

        &.social {
            flex-direction: column;
            align-items: center;
        }

        .top-section {
            h1 {
                font-size: 70px;
            }
            .event-details-container {
                flex-flow: column;
                .main-details {
                    padding-right: 0;
                    p {
                        margin-bottom: 30px;
                    }
                }
            }
        }
        .program-section {
            .tabs-content {
                .event-description-container {
                    flex-flow: column;
                    .event-description-container-title {
                        font-weight: bold;
                        font-size: 42px;
                        line-height: 42px;
                        color: var(--theme-secondary-color);
                        margin-top: 0;
                        margin-bottom: 20px;
                    }
                    .description {
                        padding-right: 0;
                    }
                    .media-container {
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        margin-top: 50px;
                        margin-bottom: 100px;
                    }
                    .video-container {
                        margin-bottom: 0;
                        width: 270px;
                        height: 190px;
                    }
                    .random-banner {
                        width: 270px;
                        height: 190px;
                    }
                }
                .program-wrapper {
                    .event-description-container-title {
                        font-weight: bold;
                        font-size: 42px;
                        line-height: 42px;
                        color: #2b2b2b;
                        margin-top: 0;
                        margin-bottom: 20px;
                    }
                }
                .auditorium-name {
                    font-size: 24px;
                }
                .program-list-container {
                    columns: 1;
                }
                .program-date {
                    width: 120px;
                }
            }
        }
        .partners-section {
            .bottom-slider-container {
                .partner-details-container {
                    flex-flow: column;
                    .main-details {
                        padding-right: 0;
                        .contact {
                            > a {
                                display: none;
                            }
                            h2 {
                                margin-bottom: 0;
                            }
                            .links-container {
                                justify-content: flex-start;
                                .webpage-link {
                                    padding-left: 40px;
                                }
                                .webpage-link.no-social-links {
                                    margin-left: 0;
                                    max-width: 600px;
                                }
                            }
                        }
                        h2 {
                            display: inline-block;
                            margin-bottom: 20px;
                        }
                        p {
                            margin-bottom: 20px;
                        }
                    }
                    .logo {
                        border-left: none;
                        padding-left: 0;
                        align-self: center;
                        display: none;
                    }
                }
            }
        }
    }
}

@media all and (max-width: 767.9px) {
    .outside-event-header .menu-wrapper {
        justify-content: center;
        padding: 10px 16px;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
        .menu-links {
            display: none;
        }
    }

    .homepage {
        .top-section {
            h1 {
                font-size: 50px;
            }
            .homepage-container {
                padding-top: 120px;
                padding-bottom: 80px;
            }
            .event-details-container {
                .main-details {
                    .organizer-name {
                        display: block;
                        margin-top: 10px;
                        overflow-wrap: break-word;
                        white-space: inherit;
                    }
                }
            }
            .extra-details {
                width: auto;
                max-width: 248px;
            }
        }
        .partners-section {
            .tab-name {
                min-width: 144px;
            }
            .top-slider {
                .arrow {
                    display: none;
                }
                padding: 0 18px;
            }
        }
        .program-section {
            .tabs-content {
                .event-description-container {
                    .media-container {
                        flex-flow: column;
                    }
                    .video-container {
                        margin-bottom: 40px;
                    }
                }
                .program-wrapper {
                    .event-description-container-title {
                        margin-bottom: 20px;
                    }
                }
                .single-day-program-wrapper {
                    flex-flow: column;
                    margin-bottom: 70px;
                }
                .program-date {
                    width: auto;
                    flex-flow: row;
                    flex: 0 0 auto;
                    .current-date {
                        flex-flow: row;
                        width: 100%;
                        margin-bottom: 24px;
                        align-items: center;
                    }
                    .buildings-list {
                        display: none;
                    }
                }
                .building-name {
                    background: var(--theme-primary-color);
                    box-shadow: 0px -1px 4px rgba(31, 37, 86, 0.25);
                    color: var(--theme-white-color);
                    border-radius: 8px;
                    width: 100%;
                    text-align: center;
                    margin: 0;
                    padding: 8px 16px;
                    min-height: 52px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    max-width: 232px;
                    align-self: flex-end;
                    font-size: 16px;
                    line-height: 20px;
                }
                .auditoriums-single-day-program-wrapper {
                    .single-building-program {
                        display: flex !important;
                        flex-flow: column;
                        margin-bottom: 30px;
                    }
                }
            }
        }
        .booth-video-container {
            width: auto;
            height: 220px;
        }
        .booth-dialog-content {
            #form-dialog-title {
                min-width: auto;
            }
        }
    }
}

@media (hover: hover) {
    .homepage {
        .top-section {
            .extra-details {
                &:hover {
                    border: 2px solid var(--theme-primary-color);
                    padding: 19px;
                    background: rgba(43, 43, 43, 0.5);
                    p.label {
                        color: var(--theme-primary-color);
                    }
                }
            }
        }
        .program-section {
            .tabs-content {
                .event-description-container {
                    .video-container {
                        &:hover {
                            img.background {
                                border: 2px solid var(--theme-secondary-color);
                                padding: 0;
                            }
                            svg {
                                path {
                                    stroke: var(--theme-secondary-color);
                                }
                            }
                        }
                    }
                    .random-banner {
                        &:hover {
                            img.background {
                                border: 2px solid var(--theme-secondary-color);
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }
        .partners-section {
            .top-slider-container {
                .slider-image-container {
                    &:hover {
                        padding: 14px;
                        border: 2px solid var(--theme-secondary-color);
                        box-sizing: border-box;
                        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
                    }
                }
                .slick-current {
                    .slider-image-container {
                        &:hover {
                            border-bottom: none;
                            padding-bottom: 16px;
                        }
                    }
                }
            }
            .exhibitors {
                .top-slider-container {
                    .slider-image-container {
                        &:hover {
                            border-color: var(--theme-primary-color);
                        }
                    }
                }
                .top-slider {
                    .arrow {
                        &:hover {
                            svg {
                                path {
                                    stroke: var(--theme-primary-color);
                                }
                            }
                        }
                    }
                }
                .bottom-slider-container {
                    .partner-details-container {
                        .main-details {
                            h2 {
                                &:hover {
                                    color: var(--theme-primary-color);
                                    text-decoration: underline;
                                }
                            }
                            h2.no-hover {
                                &:hover {
                                    color: var(--theme-text-primary-color);
                                    text-decoration: none;
                                }
                            }
                            .contact {
                                .links-container {
                                    a {
                                        &:hover {
                                            svg {
                                                path {
                                                    fill: var(--theme-primary-color);
                                                }
                                            }
                                            color: var(--theme-primary-color);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .top-slider {
                .arrow {
                    &:hover {
                        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
                        svg {
                            path {
                                stroke: var(--theme-secondary-color);
                            }
                        }
                    }
                }
            }
            .bottom-slider-container {
                .partner-details-container {
                    .main-details {
                        h2 {
                            &:hover {
                                color: var(--theme-secondary-color);
                                text-decoration: underline;
                                padding-bottom: 0;
                            }
                        }
                        h2.no-hover {
                            &:hover {
                                color: var(--theme-text-primary-color);
                                text-decoration: none;
                                padding-bottom: 0;
                                cursor: initial;
                            }
                        }
                        .contact {
                            .links-container {
                                a {
                                    &:hover {
                                        color: var(--theme-secondary-color);
                                        svg {
                                            path {
                                                fill: var(--theme-secondary-color);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .previous-events-section {
            .links-container {
                a {
                    &:hover {
                        color: var(--theme-primary-color);
                    }
                }
            }
        }
    }
}
