.isRtl {
    .create-chat > div {
        padding-right: 32px;
        padding-left: 0;
    }

    .close-active-chat-button {
        transform: rotate(180deg);
    }
}

.create-chat {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5px solid rgba(137, 137, 137, 0.5);
    background-color: var(--theme-white-color);

    & > div {
        height: 48px;
    }
}

.create-chat img {
    margin-left: 16px;
    cursor: pointer;
}

.create-chat img.mobile {
    display: none;
}

.create-chat img + div {
    padding-left: 5px;
}

.create-chat > p,
.create-chat .dropdown-title {
    font-weight: bold;
    font-size: 16px;
    color: #000;
    margin: 0;
    text-transform: uppercase;
}

.no-active-chat .create-chat .dropdown-title span {
    padding-left: 0;
}

.create-chat .dropdown-title {
    display: flex;
    align-items: center;
}

.no-active-chat .create-chat .dropdown-title img {
    margin: 0;
    width: 0;
    transition: width 0.2s;
}

.create-chat .dropdown-title img {
    width: 24px;
    transition: width 0.2s;
    margin: 0;
}

.create-chat > div {
    display: flex;
    justify-content: space-between;
    flex-flow: row;
    width: 100%;
    align-items: center;
    padding-left: 32px;
}

.create-chat button {
    padding: 0;
    background-color: transparent;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    height: 48px;
    line-height: 48px;
    outline: 0;
    cursor: pointer;
    width: 86px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    & > .button-text {
        position: absolute;
        top: 12px;
        padding: 0 10px;
        line-height: 0.9;
    }
}

.create-chat button:disabled {
    cursor: initial;
}

.create-chat button p {
    margin: 0;
    color: var(--theme-white-color);
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: -0.08em;
}

@media screen and (max-width: 1024px) {
    .create-chat .dropdown-title {
        color: var(--theme-white-color);
    }

    .create-chat {
        background-color: var(--theme-primary-color);
    }

    .create-chat > div p {
        color: var(--theme-white-color);
        font-weight: 600;
    }

    .create-chat img {
        display: none;
    }

    .create-chat img.mobile {
        display: block;
    }
}
