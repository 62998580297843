.delete-account-dialog {
    &__text {
        &.isRtl {
            font-family: 'Tajawal', sans-serif;
        }
    }
    &__buttons {
        &.isRtl {
            button {
                font-family: 'Tajawal', sans-serif;
            }
        }
    }
}
