#my-account-mobile {
    background-color: var(--theme-background-default-color);
    position: relative;
    flex: 1;
    padding-bottom: 56px;
}
#my-account-mobile .tabs-container {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    background-color: var(--theme-background-paper-color);
}
#my-account-mobile .tabs-container > div {
    display: flex;
}
#my-account-mobile .tabs-container .tab {
    width: 50%;
    text-align: center;
    padding: 8px 0;
    cursor: pointer;
}
#my-account-mobile .tabs-container .tab.active {
    border-bottom: 2px solid var(--theme-secondary-color);
}
#my-account-mobile .tabs-container h3 {
    font-size: 14px;
    text-transform: uppercase;
    padding: 0;
}
#my-account-mobile .tabs-container .tab.active h3 {
    color: var(--theme-secondary-color);
}
#my-account-mobile .edit-section-container {
    box-shadow: none;
    max-width: initial;
    border: 0;
    border-radius: 0;
    margin: 2px 0 0;
    padding: 0;
    background-color: transparent;
}
.profile-list {
    background-color: var(--theme-background-paper-color);
}
.profile-list > div {
    display: flex;
    border-bottom: 1px solid #cacbd0;
    padding: 7px 16px;
    justify-content: space-between;
    align-items: center;
}
.profile-list > div:first-of-type {
    align-items: flex-start;
    padding-left: 10px;
    padding-right: 10px;
}
.profile-list .user-details {
    flex: 1;
    margin-bottom: 10px;
    max-width: 75%;
}
.profile-list .avatar,
.edit-user-information .form-wrapper .avatar {
    margin-right: 12px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.profile-list h3 {
    font-size: 16px;
    color: #2b2b2b;
}
.profile-list .user-details p {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
}
.profile-list .user-details p span {
    color: #777081;
}
.profile-list button {
    background-color: transparent;
    border: 0;
    outline: 0;
}
.profile-list button img {
    width: 18px;
    margin-right: 0;
}
.profile-list h4 {
    text-transform: uppercase;
    margin: 0;
    padding: 13px 0;
    font-weight: 600;
}

.my-account-mobile__user-name {
    max-width: 75%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#my-account-mobile .gdpr-links {
    margin-top: 32px;
    padding: 0 16px;
}
#my-account-mobile .gdpr-links p {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: #000000;
}
#my-account-mobile .gdpr-links ul {
    padding-top: 10px;
}
#my-account-mobile .gdpr-links a {
    font-size: 16px;
    font-weight: 600;
    color: var(--theme-secondary-color);
    text-decoration: underline;
}
.dialog-mobile #form-dialog-title h2 {
    font-weight: 600;
    color: rgba(0, 0, 0, 0.87);
    text-transform: initial;
}
.dialog-mobile p {
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.6);
    font-family: 'Montserrat', sans-serif;
}
.dialog-mobile button {
    font-size: 16px;
    font-weight: bold;
    color: #2b2b2b;
}
.dialog-mobile button.MuiButton-containedPrimary,
.dialog-mobile button.MuiButton-containedSecondary {
    background-color: transparent;
    box-shadow: none;
    color: var(--theme-primary-color);
}
#my-account-mobile .edit-user-information {
    height: calc(100% - 162px);
    background-color: var(--theme-background-paper-color);
    width: 100%;
    position: absolute;
}
#my-account-mobile .edit-user-information > div:first-of-type {
    position: relative;
    background-color: var(--theme-white-color);
    display: flex;
}
#my-account-mobile .edit-user-information .content-wrapper {
    padding-top: 68px;
    padding-bottom: 50px;
    flex: 1;
    overflow-y: auto;
}
#my-account-mobile .edit-user-information .page-title {
    background: var(--theme-primary-color);
    color: var(--theme-white-color);
    padding: 15px 16px;
    position: absolute;
    top: 0;
    z-index: 2;
    width: 100%;
}
#my-account-mobile .edit-user-information .page-title p {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
}
#my-account-mobile .edit-user-information .buttons-actions {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    background-color: var(--theme-background-paper-color);
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 2;
}
#my-account-mobile .edit-user-information .form-wrapper {
    margin: 12px 10px;
    padding: 10px;
    background-color: var(--theme-background-paper-color);
}
.edit-user-information .form-wrapper .image-container {
    display: flex;
    padding-top: 16px;
}
.form-wrapper .image-container .avatar {
    width: 80px;
    height: 80px;
}
.edit-user-information .form-wrapper .upload-new-image {
    flex: 1;
    justify-content: space-around;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
}
.edit-user-information .form-wrapper .upload-new-image .select-image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
    width: 100%;
    z-index: 2;
}
.edit-user-information .form-wrapper .upload-container {
    position: relative;
}
.upload-new-image span,
.upload-new-image button span {
    font-size: 16px;
    font-weight: bold;
    color: #2b2b2b;
    text-transform: uppercase;
}
.upload-new-image button {
    background: transparent;
    border: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    outline: 0;
}
.upload-new-image button:disabled span {
    color: #898989;
}
#my-account-mobile .edit-user-information input {
    color: #2b2b2b !important;
}
#my-account-mobile .edit-user-information label.Mui-focused {
    color: var(--theme-secondary-color);
}
#my-account-mobile .edit-user-information div.Mui-focused fieldset {
    border-color: var(--theme-secondary-color);
    border-width: 1px;
}
#my-account-mobile .edit-user-information div fieldset {
    border-color: rgba(0, 0, 0, 0.23);
}
#my-account-mobile .edit-participant-info-container {
    background-color: var(--theme-background-paper-color);
    padding: 20px 16px;
    position: relative;
}
#my-account-mobile .edit-participant-info-container button {
    background: transparent;
    border: 0;
    outline: 0;
    position: absolute;
    right: 16px;
}
#my-account-mobile ul {
    padding-right: 40px;
}
#my-account-mobile ul p {
    font-weight: 500;
}
#my-account-mobile .change-password-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 16px;
    background-color: var(--theme-background-paper-color);
    border-bottom: 1px solid rgba(137, 137, 137, 0.5);
    border-top: 1px solid rgba(137, 137, 137, 0.5);
}
#my-account-mobile .change-password-btn h4 {
    margin: 0;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
}
#my-account-mobile .edit-user-information form > div:last-of-type {
    margin-bottom: 0;
}
#my-account-mobile .edit-user-information.small-padding-top .content-wrapper {
    padding-top: 50px;
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
    #my-account-mobile .tabs-container > div {
        justify-content: center;
    }
    #my-account-mobile .tabs-container .tab {
        width: auto;
        padding-left: 16px;
        padding-right: 16px;
    }
    #my-account-mobile .edit-user-information .form-wrapper {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
    }
    .profile-list > div:first-of-type {
        cursor: initial;
    }
    .profile-list > div,
    .profile-list button {
        cursor: pointer;
    }
}

.image-container .remove-avatar {
    color: #eb5757;
    font-family: inherit;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
}
.image-container .remove-avatar:disabled {
    cursor: initial !important;
}

.image-container .select-image:hover + .upload-text-container {
    visibility: visible;
}
