.server-down-info-container {
    position: absolute;
    z-index: 9999 !important;
    background-color: rgba(169, 19, 20, 0.85);
    color: var(--theme-white-color);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px !important;
    padding: 0 16px;

    span {
        display: inline-block;
        font-weight: bold;
        color: var(--theme-white-color);
        padding: 0 10px;
        cursor: pointer;
    }
}

.position-background.overlay-grey:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(43, 43, 43, 0.25);
    z-index: 10;
}

.top-container {
    position: fixed;
}

#my-account-mobile.overlay-grey:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(43, 43, 43, 0.25);
    z-index: 10;
}

#header-mobile {
    height: 56px;
}
#header-mobile .main-menu-container {
    padding: 0;

    .right-header-wrapper {
        & > div {
            margin-right: 16px;
            &:first-child {
                margin-right: 32px;
            }
        }
        &.isRtl {
            & > div {
                margin-left: 16px;
                &:first-child {
                    margin-right: 16px;
                }
            }
        }
    }
}
#header-mobile .left-header-wrapper {
    flex: 1;
}

#header-mobile #my-events {
    height: 56px;
    margin: 0 0 0 20px;
    padding: 0 28px 0 16px;
    border: 0;
    flex: 1;
    justify-content: flex-start;
    .isRtl {
        padding: 0 16px 0 28px;
    }
}
#header-mobile #my-events p {
    flex: 1;
    justify-content: flex-start;
    color: #2b2b2b;
    font-weight: 800;
    padding: 8px 0;
}
#header-mobile #my-events p span {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    text-align: left;
    white-space: initial;
}

#menu-bottom {
    position: fixed;
    bottom: 0;
    z-index: 9999;
    height: 56px;
    width: 100%;
    background-color: var(--theme-background-paper-color);
    border-top: 1px solid #cacbd0;
    display: flex;
}
#menu-bottom .menu-bottom-wrapper {
    width: 100%;
}
#menu-bottom .main-menu-container {
    height: 100%;
}
#menu-bottom .main-menu-container .main-header-wrapper > div {
    padding: 3px 0 0;
    display: flex;
    cursor: pointer;
    text-align: center;
    line-height: 1;
    flex-flow: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    width: calc(100vw / 5);
}
#menu-bottom .main-menu-container .main-header-wrapper > div.active {
    border-bottom: 2px solid var(--theme-primary-color);
}
#menu-bottom .main-menu-container .main-header-wrapper > div.active p {
    color: var(--theme-primary-color);
    font-weight: bold;
}
#menu-bottom .menu-bottom-wrapper p {
    margin: 0;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: -0.4px;
    color: #777081;
    padding-top: 6px;
    text-align: center;
}

#menu-bottom .menu-bottom-wrapper > div > div {
    display: flex;
    align-items: center;
    height: 100%;
}
@media screen and (min-width: 767px) and (max-width: 1024px) {
    #menu-bottom .menu-bottom-wrapper > div > div {
        justify-content: center;
    }
    #menu-bottom .main-menu-container .main-header-wrapper > div {
        width: auto;
        padding: 0 14px;
    }
    .main-menu-container .main-header-wrapper #chats > div {
        right: 15px;
        left: initial;
        bottom: 18px;
    }
}
@media screen and (max-width: 766px) {
    .main-menu-container .main-header-wrapper #chats > div {
        bottom: 18px;
        left: 50%;
    }
}
