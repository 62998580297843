.AccountLeftSideTabs {
    display: flex;
    height: 100%;
    padding-right: 24px;

    &__switchGroup {
        flex: 0 0 240px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-right: 1px solid #cacbd0;
        border-left: 1px solid #cacbd0;
    }

    &__switchList {
        flex: 0 0 auto;
    }

    &__switchComment {
        flex: 0 0 auto;
        padding: 16px;
        padding-bottom: 24px;
        line-height: 1.5;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: -0.4px;
        color: #898989;
    }

    &__button {
        padding-left: 32px !important;
        opacity: 1 !important;
        text-align: left !important;

        span {
            align-items: flex-start;
            font-size: 16px;
            font-weight: 700;
            color: #2b2b2b;
        }

        &:disabled span {
            color: #999;
        }
    }

    &__content {
        flex: 1 1 auto;
        max-width: calc(100% - 240px);
        padding: 24px;
        box-sizing: border-box;
    }

    &__contentHeader {
        margin-bottom: 24px;
    }

    &.isRtl {
        padding-right: 0;
    }
}

@media (max-width: 960px) {
    .AccountLeftSideTabs {
        padding-right: 0;
    }
}

@media (max-width: 960px) {
    .AccountLeftSideTabs__switchGroup {
        flex: 0 0 200px;
    }
}

@media (max-width: 960px) {
    .AccountLeftSideTabs__button {
        padding-left: 16px !important;
    }
}

@media (max-width: 960px) {
    .AccountLeftSideTabs__content {
        max-width: calc(100% - 200px);
    }
}
