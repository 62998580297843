.programAuditoriumArrow {
    position: absolute;
    top: 0;
    cursor: pointer;
    background: #cacbd0;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);

    width: 30px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--next {
        right: -40px;
        border-radius: 60px 0 0 60px;
    }

    &--prev {
        left: -40px;
        border-radius: 0 60px 60px 0;
    }

    &__arrowIcon {
        width: 15px;
        & > path {
            stroke: var(--theme-white-color);
        }
        &--next {
            transform: rotate(180deg);
        }
    }
}

@media screen and (max-width: 700px) {
    .programAuditoriumArrow {
        height: 52px;
        z-index: 9999999;
        &:hover {
            background-color: var(--theme-secondary-color);
        }
        &--next {
            right: 0;
            border-radius: 52px 0 0 52px;
        }

        &--prev {
            left: 0;
            border-radius: 0 52px 52px 0;
        }
    }
}
