.ScheduleBottomMenuBottomSheet {
}

.ScheduleBottomMenuBottomSheet__item {
}

.ScheduleBottomMenuBottomSheet__button {
    display: inline-flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 12px 24px;
    border: none;
    background-color: transparent;
    font-size: 14px;
    font-weight: 600;
    color: #777081;
    text-transform: uppercase;
    cursor: pointer;
    outline: none;
    appearance: none;
}

.ScheduleBottomMenuBottomSheet__buttonIcon {
    margin-right: 16px;
}

.ScheduleBottomMenuBottomSheet__buttonTitle {
}
