.auditoriums-navigation-mobile {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    svg {
        width: 12px;
        height: auto;

        path {
            stroke: var(--theme-white-color);
        }
    }

    .button {
        height: 52px;
        width: 26px;
        background: #898989;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
        border-bottom-left-radius: 52px;
        border-top-left-radius: 52px;
        font-size: 0;

        &.previous {
            transform: rotate(180deg);
        }
    }

    .auditorium-title-container {
        flex: 1;
        padding: 0 12px;

        p {
            margin: 0;
            display: flex;
            justify-content: space-between;
            color: #777081;
            font-size: 14px;
            font-weight: 500;

            &.name {
                font-size: 16px;
                line-height: 1.25;
                font-weight: 600;
                color: #2b2b2b;
            }
        }
    }
}
