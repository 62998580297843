.contacts-page {
    overflow: initial;
    max-width: 323px;

    // TODO: refactor mobile styles
    &.contacts-mobile {
        top: 56px;
        width: 100%;
        max-width: initial;
        height: calc(100vh - 112px);

        .page-title {
            height: 48px;
            line-height: 48px;
            padding-left: 16px;
            text-align: left;

            h3 {
                font-weight: 600;
                font-size: 16px;
            }
        }

        .received-requests-title {
            display: none;
        }

        .custom-scroll-container {
            height: calc(100vh - 160px);

            & > div {
                height: auto;
            }
        }
    }

    .custom-scroll-container {
        height: calc(100vh - 500px);

        &.networking-wrapper {
            border-top: 0;
            padding-top: 0;
            height: auto;
            box-shadow: -6px 6px 14px rgba(16, 16, 16, 0.14);
        }

        .scrollbar {
            height: 100%;
            min-height: 87px;

            .box {
                padding-bottom: 0 !important;
            }
        }

        .received-request-container {
            height: auto;
            border-bottom: 1px solid rgba(137, 137, 137, 0.5);
            text-align: left;

            &:last-of-type {
                border: 0;
            }
        }
    }

    .received-request-container {
        .user-details {
            & > div:first-child {
                display: inline-flex;
                flex-direction: column;
                min-height: 76px;
            }

            .user-name p {
                font-weight: 600;
                font-size: 12px;
                text-transform: capitalize;
                line-height: 14px;
                color: var(--theme-text-primary-color);
            }
        }

        & > div {
            .user-details {
                padding-bottom: 16px;
            }

            &:last-of-type .user-details {
                border: 0;
            }
        }
    }

    .contacts-fixed-members,
    .already-contacts-wrapper {
        height: 485px;
        background-color: var(--theme-background-default-color);
        box-shadow: -6px 6px 14px rgba(16, 16, 16, 0.14);
        border-top: 1px solid rgba(137, 137, 137, 0.5);
        padding: 0 10px;
    }

    .already-contacts-wrapper {
        padding-left: 0;
        padding-right: 0;

        .box > div:last-of-type .user-details {
            border: 0;
            padding: 0;
        }

        .friends-list {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .user-container {
        .user-name p {
            max-width: 100%;

            &:last-child {
                margin-bottom: 26px;
                margin-right: 10px;
            }
        }

        .user-details {
            padding-bottom: 5px;

            & > div:first-child {
                font-size: 0;
                text-align: left;
            }

            .social-button {
                font-size: 0;
            }
        }
    }

    .search-user-container {
        display: none;
    }

    .friends-list {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            padding-top: 0;

            &:last-child .user-details {
                border: 0;
            }
        }
    }

    .buttons-container {
        min-height: 70px;
    }
}

@media screen and (max-width: 1024px) {
    .contacts-page.contacts-mobile .custom-scroll-container {
        height: calc(calc(var(--vh, 1vh) * 100) - 160px);
    }
}
