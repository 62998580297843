.hot_news_popup {
    $wallButtonWidth: 40px;
    $maxWallWidth: 363px - $wallButtonWidth;
    direction: ltr;
    position: fixed;
    bottom: 0;
    right: 0;
    height: 58px + 24px;
    z-index: 1000;
    color: var(--theme-white-color);
    font-size: 14px;
    box-sizing: content-box;
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    svg {
        fill: var(--theme-white-color);
        min-width: 24px;
    }

    &.-is-expanded {
        left: 0;
        right: unset;
        width: 100%;
        border-radius: 0;
    }

    &.-side-opened {
        right: $maxWallWidth;
        width: calc(100% - 323px);

        .expander {
            max-width: $maxWallWidth;
        }
    }

    .expander {
        display: flex;
        width: 0;
        max-width: 0;
    }
    .non_expanded_container {
        border-radius: 8px 0px 0px 8px;
        background: var(--theme-secondary-color);
        display: flex;
        height: 100%;
        align-items: center;
        width: 44px;
        cursor: pointer;
        .text {
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.0125em;
            text-transform: uppercase;
            color: var(--theme-white-color);
            transform-origin: 30% 100%;
            transform: rotate(-90deg) translateZ(0);
        }
    }
    .expanded_container {
        background: var(--theme-secondary-color);
        display: flex;
        width: 75%;
        flex-grow: 1;
        height: 100%;
        align-items: center;
        justify-content: center;
        padding: 0 24px;
        box-sizing: border-box;

        .news_logo {
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.0125em;
            text-transform: uppercase;
            color: var(--theme-white-color);
            flex-direction: column;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                width: 1px;
                height: 80%;
                top: 10%;
                right: -10px;
                background-color: var(--theme-white-color);
            }
            svg {
                min-width: 16px;
                min-height: 20px;
                margin-bottom: 5px;
            }
        }
        .text-area {
            flex-grow: 1;
            padding: 0 24px;
            text-align: left;
            font-weight: 500;
            font-size: 14px;
            display: flex;
            align-items: center;
        }
        button {
            border: 1px solid var(--theme-white-color);
            background-color: unset;
            box-sizing: border-box;
            cursor: pointer;
            border-radius: 8px 8px 0px 8px;
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.0125em;
            text-transform: uppercase;
            justify-content: space-between;
            padding: 12px 8px;
            color: var(--theme-white-color);
        }
    }
}
