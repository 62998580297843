.networking-wrapper .received-requests-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--theme-text-secondary-color);
    margin: 0;
    text-align: left;
    padding-left: 10px;
    padding-top: 10px;
}
.contacts-page .page-title,
.wallet-popup .page-title,
.auditorium-page .page-title,
.agenda-page .page-title,
.notifications-page .page-title,
.my-account-component .page-title {
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: var(--theme-primary-color);
}
.contacts-page .page-title h3,
.wallet-popup .page-title h3,
.auditorium-page .page-title h3,
.agenda-page .page-title h3,
.notifications-page .page-title h3,
.my-account-component .page-title h3 {
    margin: 0;
    font-weight: 800;
    font-size: 18px;
    text-transform: uppercase;
    color: var(--theme-white-color);
}
.user-details .accept-request,
.user-details .reject-request {
    border: 0;
    background-color: transparent;
    outline: 0;
    cursor: pointer;
    position: absolute;
    right: 0;
    margin: 0;
    font-size: 0;
}
.user-details .accept-request {
    bottom: 16px;
    right: 0;
    padding: 0;
    display: flex;
    align-items: center;
}
.user-details .accept-request span {
    font-family: 'Montserrat', sans-serif;
    font-size: 8px;
    color: var(--theme-secondary-color);
    text-transform: uppercase;
    font-weight: 600;
    margin-left: 10px;
}
.user-details .reject-request {
    right: 95px;
    font-size: 0;
    padding: 0;
    bottom: 16px;
    display: flex;
    align-items: center;
}
.user-details .reject-request span {
    font-family: 'Montserrat', sans-serif;
    display: block;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    margin-left: 10px;
}
.empty-contacts,
.empty-notifications,
.empty-program {
    min-height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 30px;
    background-color: var(--theme-background-default-color);
}
.empty-program__icon {
    height: 48px;
    width: 48px;
}
.empty-contacts p,
.empty-notifications p,
.booth-visitors-container[data-empty='true'] .empty-visitors p,
.empty-program p {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: rgba(137, 137, 137, 0.5);
    margin-top: 0;
    text-align: center;
}
.received-requests-wrapper {
    background-color: var(--theme-white-color);
}
.received-requests-wrapper[data-height='true'] {
    height: 333px;
}
.dropdown-component .program-dropdown-day h3 {
    display: flex;
    flex-flow: column;
}
.dropdown-component .program-dropdown-day h3 span.day-position {
    font-size: 18px;
    line-height: 22px;

    &.isRtl {
        direction: rtl;
    }
}
.dropdown-component .program-dropdown-day h3 span.date {
    font-size: 12px;
    line-height: 11px;
    color: var(--theme-background-default-color);
}
/* Contacts page - mobile */
.contacts-mobile .user-container {
    padding-left: 16px;
    padding-right: 16px;
}
.contacts-mobile .already-contacts-wrapper .user-container {
    padding-left: 6px;
    padding-right: 6px;
}
.contacts-mobile .participant-avatar {
    width: 40px;
    height: 40px;
}
.contacts-mobile .already-contacts-wrapper .user-details > div:last-of-type {
    min-height: 41px;
}
.contacts-mobile.dropdown-component
    .already-contacts-wrapper
    .user-details
    .connection.no-action
    .participant-button.open-chat {
    top: 0;
}

.contacts-mobile .user-container .user-details .user-name p {
    font-size: 16px;
}
.contacts-mobile .user-details .reject-request span {
    font-size: 16px;
}
.contacts-mobile .user-details .accept-request span {
    font-size: 16px;
}
.contacts-mobile .user-details .reject-request {
    right: 110px;
}
@media screen and (max-width: 1024px) {
    .dropdown-component .program-dropdown-day h3 {
        text-align: center;
    }
}
@media screen and (min-width: 767px) and (max-width: 1024px) {
    .contacts-mobile .already-contacts-wrapper .box > div:last-of-type .user-details {
        padding-bottom: 5px;
    }
}
