.BusinessCardEditingForm {
    padding: 16px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    background-color: var(--theme-white-color);
    color: #898989;

    label {
        font-family: inherit;
    }
}

/* Compelled code */
.BusinessCardEditingForm form {
    max-width: none !important;
    padding: 0 !important;
}
