.AgendaMeetings {
}

.AgendaMeetings__empty {
    padding: 24px;
    text-align: center;
}

.AgendaMeetings__emptyMessage {
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 16px;
    color: #bfbfbf;
}

.AgendaMeetings__emptyIcon {
    color: var(--theme-primary-color);
}
