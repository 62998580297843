.cookie-banner {
    position: fixed;
    z-index: 999999;
    bottom: 0;
    width: 100%;
    color: var(--theme-white-color);
    display: flex;
    background: linear-gradient(135deg, var(--theme-secondary-color) 0%, var(--theme-primary-color) 100%);

    &__container {
        width: 100%;
        margin: auto;
        padding: 16px 43px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__text {
        margin: 0;
        max-width: 1200px;
    }

    &__link {
        font-weight: bold;
        color: var(--theme-text-color);
    }

    &__accept-button {
        padding: 5px 10px;
        cursor: pointer;
        margin-left: 50px;
        border: 2px solid var(--theme-white-color);
        border-radius: 8px;
        font-size: 18px;
        font-weight: 600;
        color: var(--theme-white-color);
        text-transform: uppercase;
        padding-left: 60px;
        padding-right: 60px;
        padding-top: 8px;
        padding-bottom: 8px;
    }
}

@media all and (max-width: 767.9px) {
    .cookie-banner {
        top: 0;
        bottom: initial !important;

        &__container {
            flex-flow: column;
            padding: 20px 30px !important;
        }

        &__accept-button {
            width: 100%;
            margin: 0;
            padding: 8px 0 !important;
            text-align: center;
            margin-top: 20px;
            margin-left: auto !important;
            margin-right: auto !important;
            max-width: 375px;
        }
    }
}
