.AuditoriumProgramTimeslotList {
    height: 320px;
    margin-bottom: 12px;

    &_notLargeScreen {
        height: auto;
        margin: 0;
        overflow: auto;
    }
}

.AuditoriumProgramTimeslotList__element {
    padding-right: 16px;
}

.AuditoriumProgramTimeslotList_notLargeScreen .AuditoriumProgramTimeslotList__element {
    padding-right: 0;
}

.AuditoriumProgramTimeslotList__item {
    padding: 12px 0;
    border-bottom: 1px solid #cacbd0;

    &:last-child {
        border-bottom: none;
    }
}

.AuditoriumProgramTimeslotList_notLargeScreen .AuditoriumProgramTimeslotList__item {
    padding: 12px;
}
