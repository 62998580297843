.back-to-live-btn {
    background: var(--theme-background-paper-color);
    color: var(--theme-secondary-color);
    border: 1px solid var(--theme-secondary-color);
    box-sizing: border-box;
    border-radius: 8px 8px 0px 8px;
    padding: 10px 16px;
    display: flex;
    cursor: pointer;
    position: absolute;
    top: 25px;
    left: -250px;

    [dir='rtl'] & {
        left: calc(345px - 100vw);
    }

    &__text {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.0125em;
        text-transform: uppercase;
        margin-left: 8px;
        [dir='rtl'] & {
            margin-left: 0;
            margin-right: 8px;
        }
    }
}
