.participants-page {
    & > div {
        position: relative;
    }
    .friends-list {
        padding: 0;
        margin: 0;
        li {
            list-style-type: none;
            padding: 13px 0 0 0;
            & > div {
                text-align: left;
            }
            &:first-child {
                padding-top: 0;
            }
            &:last-of-type {
                padding-bottom: 13px;
                .user-details {
                    border-bottom: 0;
                }
            }
        }
    }
}

.participants-header__filter {
    font-size: 14px;
    width: 100%;
}

.networking-wrapper .scrollbar > div:nth-child(3) {
    margin-bottom: 0;
}

.search-user-container {
    background-color: var(--theme-white-color);
    display: flex;
    align-items: center;
    & > div {
        width: 100%;
    }

    .filters-list {
        width: 100%;
        top: 55px !important;
        right: 0;
        height: calc(100vh - 170px);
    }
}

.custom-scroll-container.networking-wrapper {
    background-color: var(--theme-white-color);
    border-top: 1px solid rgba(137, 137, 137, 0.5);
    padding: 0;
}
.custom-scroll-container.networking-wrapper div,
.scrollbar,
.scrollbar div {
    outline: none !important;
}

@media screen and (max-width: 768px) {
    .participants-header__filter {
        width: 100%;
    }

    .search-user-container {
        .filters-list {
            height: calc(var(--vh, 1vh) * 100 - 215px);
        }
    }
}
