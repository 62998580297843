@import './mixins';

.header-spacing-container {
    padding-top: 70px;
    max-width: 100%;
    flex: 1;
}
.w-100 {
    width: 100%;
}
.h-100 {
    height: 100%;
}
.d-flex {
    display: flex;
}
.justify-content-center {
    justify-content: center;
}
.align-items-center {
    align-items: center;
}
.p-relative {
    position: relative;
}
.uppercase {
    text-transform: uppercase;
}
.p-absolute {
    position: absolute;
}
.full-background-container {
    min-height: 100vh;
}
.img-cover {
    object-fit: cover;
}
.full-background-overlay {
    background-color: rgba(29, 29, 27, 0.7);
}
.full-background-img,
.full-background-overlay {
    z-index: -1;
}
.register-participant-step-1 .form-wrapper,
.register-participant-step-2 .form-wrapper {
    justify-content: flex-end;
}

.register-participant-step-1 .form-container,
.register-participant-step-2 .form-container {
    width: 100%;
    justify-content: flex-end;
    margin: 30px;
}
.register-participant-step-2 .form-container {
    flex-direction: column;
    max-width: 100%;
    width: 576px;
}
.register-participant-step-1 .form-container .left-form {
    padding: 0 8px;
    width: 360px;
    background-color: var(--theme-white-color);
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    display: flex;
    align-items: flex-end;
    background: unset;

    &.isSca {
        .sca,
        span {
            color: #fff;
        }
    }
}

.register-participant-step-2 .form-container .left-form .register-header {
    padding: 24px;
    margin: 0;
}
.register-participant-step-2 .form-container .left-form {
    width: 100%;
    padding: 0;
    background: var(--theme-background-paper-color);
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px 8px 0 0;
}
.register-participant-step-2 .form-container .right-form {
    background: rgba(#f5f5f5, 0.7);
    padding: 20px;
    position: relative;
}

.register-participant-step-1 .form-container .right-form {
    border-radius: 8px;
    max-width: 100%;
    width: 567px;
    text-align: center;
    background: rgba(#f5f5f5, 0.7);
    position: relative;
}
.register-participant-step-1 .form-container .right-form .form-header {
    padding: 20px;
    background-color: var(--theme-white-color);
    border-radius: 8px 8px 0 0;
    text-align: left;
}
.register-participant-step-1 .form-container .right-form .form-body {
    padding: 20px;
}
.register-participant-step-1 .form-container .right-form .form-title {
    font-weight: 600;
    font-size: 34px;
    color: var(--theme-secondary-color);
    text-align: center;
    line-height: 40px;
    margin: 0 0 32px 0;
}
.register-participant-step-2 .form-container .right-form {
    border-radius: 0 0 8px 8px;
}
.register-participant-step-1 .form-container h1,
.register-participant-step-2 .form-container h1 {
    font-size: 40px;
    color: var(--theme-secondary-color);
    font-weight: 800;
    line-height: 1;
    margin-bottom: 32px;
    margin-top: 0;
}
.register-participant-step-1 .form-container .left-form p,
.register-participant-step-2 .form-container .left-form p {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--theme-text-secondary-color);
}
.register-participant-step-1 .or-text {
    display: none;
}

.register-participant-step-1 .form-container .right-form form,
.register-participant-step-2 .form-container .right-form form {
    padding: 0;
    max-width: 100%;
}
.login-page .field-container,
.register-participant-step-1 .form-container .right-form form .field-container,
.register-participant-step-2 .form-container .right-form form .field-container {
    margin-bottom: 20px;
    margin-top: 0;
    border-radius: 8px;
    background-color: var(--theme-white-color);
}
.field-container.hidden label,
.field-container.hidden > div {
    display: none !important;
}
.registration-radio-group {
    background-color: var(--theme-white-color);
    border-radius: 8px;
    margin-top: 8px;
    border: 0.5px solid #898989;
}
.register-participant-step-1 .form-container .right-form form .field-container fieldset,
.login-page .field-container fieldset,
.register-participant-step-2 .form-container .right-form form .field-container fieldset {
    border-radius: 8px;
    border: 0.5px solid #898989;
}
.register-participant-step-1 .form-container .right-form form label.file p {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    text-align: left;
    line-height: 20px;
    letter-spacing: 0.0125em;
    margin-left: 5px;
    text-transform: uppercase;
    &.isRtl {
        margin: 0;
        margin-right: 5px;
    }
}
.register-participant-step-1 .form-container .right-form .agree-terms span {
    text-align: left;
    font-weight: 500;
    color: var(--theme-text-primary-color);
    &.isRtl {
        padding: 0;
        padding-right: 10px;
        text-align: right;
    }
}
.register-participant-step-1 .form-container .right-form .allready-have-account {
    font-style: normal;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
}
.register-participant-step-1 .form-container .right-form .allready-have-account a {
    color: var(--theme-primary-color);
    margin-left: 8px;
    font-weight: 600;
    &.isRtl {
        margin-left: 0;
        margin-right: 8px;
    }
}
.register-participant-step-1 .form-container .right-form form .field-container p,
.login-page .field-container p,
.register-participant-step-2 .form-container .right-form form .field-container p {
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: left;
    margin-left: 0;
    position: absolute;
    bottom: -20px;
    left: 15px;
    [dir='rtl'] & {
        left: unset;
        right: 15px;
        margin-right: 0;
    }
}
.register-participant-step-1 {
    .error-text {
        text-align: left;
    }
}
.login-container {
    width: 100%;
    max-width: 1224px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    justify-content: space-between;
    justify-content: flex-end;
}
.login-page {
    .form-wrapper {
        justify-content: flex-end;
        position: relative;
        .form-logo {
            width: 300px;
            height: 120px;
            position: absolute;
            top: 0;
            left: 10vw;
            display: none;
            justify-content: center;
            align-items: center;
            img {
                max-width: 100%;
                max-height: 100%;
            }
            @include sm {
                position: relative;
                left: 0;
                margin-top: 40px;
            }
            @include xs {
                display: none;
            }
        }
        @include sm {
            flex-direction: column;
        }
    }

    .field-container {
        background-color: var(--theme-white-color);
    }
}
.field-container {
    .MuiFilledInput-root {
        border-radius: 4px;
    }
    .MuiFilledInput-root,
    .MuiFilledInput-root:hover,
    .MuiFilledInput-root.Mui-focused {
        background-color: var(--theme-white-color);
    }
    .MuiFilledInput-underline:before,
    .MuiFilledInput-underline:after {
        display: none;
    }
}

.login-page .left-column {
    flex: 1;
    font-size: 24px;
    line-height: 30px;
    font-weight: 300;
    max-width: 310px;
    padding-right: 8px;
    color: var(--theme-white-color);
    align-items: flex-end;
    display: flex;
    box-sizing: border-box;
}
.login-page .left-column h1 {
    margin-bottom: 30px;
}
.login-page .left-column p {
    font-size: 24px;
    line-height: 30px;
    font-weight: 300;
    margin: 0;
}
.login-page .form-container {
    width: 576px;
    max-width: 100%;
    position: relative;
    text-align: center;
}

.register-participant-step-2 {
    .form-container {
        .right-form {
            .invitation-code-information {
                font-weight: 600;
                font-size: 12px;
                line-height: 20px;
                color: var(--theme-text-secondary-color) !important;
                margin-top: 10px;
                margin-bottom: 16px;
                min-height: 40px;
            }

            .invitation-code-wrapper {
                position: relative;

                .error-message {
                    position: absolute;
                    bottom: -15px;
                }
            }

            .buttons-wrapper {
                padding-bottom: 0;
                margin-bottom: 0;
            }

            button {
                width: 100%;
                font-size: 16px;
                text-align: center;
            }
        }
    }
}

.choose-package {
    display: flex;
    flex-flow: column;

    .package-description {
        margin: 0;
        background-color: var(--theme-white-color);
        padding: 16px;
        border: 1px solid #cacbd0;
        border-radius: 4px;
        font-size: 16px;
        line-height: 20px;
        font-weight: normal;
        color: #2b2b2b;
        margin-bottom: 8px;
        white-space: pre-line;
    }

    .title {
        color: #777081;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        margin-top: 50px;
        margin-bottom: 16px;
    }

    .package {
        border-radius: 4px;
        margin-bottom: 8px;
        border: 1px solid #cacbd0;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #2b2b2b;
        background-color: var(--theme-white-color);
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;

        .name-container {
            display: flex;
            align-items: center;
            padding: 5px 7px;
            overflow: hidden;
        }

        .name {
            text-transform: uppercase;
            padding-left: 7px;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .price {
            padding: 7px;
            background: var(--theme-background-default-color);
            border-radius: 0 4px 4px 0;
            flex: 0 0 126px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-left: 1px solid #cacbd0;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
        }

        .description {
            display: block;
        }

        &.selected {
            color: var(--theme-primary-color);
            border: 1px solid var(--theme-primary-color);
            filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2));
            margin-bottom: 0;

            svg {
                color: var(--theme-secondary-color);
            }

            .price {
                background-color: var(--theme-secondary-color);
                color: var(--theme-white-color);
                border-left: 1px solid var(--theme-secondary-color);
            }
        }
    }
}
.set-billing-info .title {
    color: #777081;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-top: 50px;
    margin-bottom: 16px;
    text-transform: uppercase;
}

@media all and (max-width: 1199.9px) {
    .register-participant-step-1 .form-wrapper,
    .register-participant-step-2 .form-wrapper {
        justify-content: center;
        padding: 0 15px;
    }
    .register-participant-step-1 .form-container {
        flex-flow: column;
        max-width: 500px;
        max-width: 100%;
        flex-direction: column-reverse;
        align-items: center;
    }

    .register-participant-step-2 .form-container {
        flex-flow: column;
        max-width: 500px;
        max-width: 100%;
    }
    .register-participant-step-2 .form-container .left-form,
    .register-participant-step-1 .form-container .left-form {
        width: 100%;
        box-sizing: border-box;
        border-radius: 8px 8px 0 0;
    }
    .register-participant-step-1 .form-container .left-form > div,
    .register-participant-step-2 .form-container .left-form > div {
        max-width: 350px;
        margin: auto;
    }
    .register-participant-step-1 .form-container h1,
    .register-participant-step-2 .form-container h1 {
        margin-top: 30px;
    }
    .register-participant-step-1 .form-container .right-form,
    .register-participant-step-2 .form-container .right-form {
        border-radius: 0 0 8px 8px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .register-participant-step-1 .form-container .right-form {
        border-radius: 8px 8px;
        padding: 0;
    }

    .login-page h1 {
        font-size: 40px;
    }
    .login-page .left-column p {
        font-size: 20px;
        line-height: 24px;
    }
}

@media all and (max-width: 767.9px) {
    .header-spacing-container {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    .login-container {
        flex-flow: column;
        align-items: center;
        margin-top: 30px;
        margin-bottom: 30px;
        padding-left: 15px;
        padding-right: 15px;
        flex-direction: column-reverse;
    }
    .login-page .left-column {
        width: 100%;
        max-width: 380px;
        padding: 0;
        margin-top: 32px;
        margin-bottom: 60px;
    }
    .login-page .left-column img {
        display: none;
    }
    .booth-dialog-content #form-dialog-title {
        min-width: initial !important;
    }

    .login-page-spinner-container .homepage-overlay,
    .register-participant-step-1-spinner-container .homepage-overlay {
        position: absolute !important;
    }
}
