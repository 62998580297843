.single-post-container {
    &.isRtl {
        text-align: right;

        .user-name {
            margin-right: 8px;
        }
    }

    &:first-child {
        margin-top: 0;
    }
}

.postActions {
    display: flex;

    .dotIcon {
        color: #777081;
    }
}

.comment-header {
    display: flex;
    align-items: center;

    span {
        padding-right: 5px;
    }

    .avatar {
        width: 20px;
        height: 20px;
        margin-right: 15px;
    }
}

.comments-list-container {
    list-style: none;
    padding-left: 0;
    margin: 0;

    li {
        margin-bottom: 15px;
    }
}

.post-comments p {
    margin: 0;
}

.post-header {
    display: flex;
    align-items: center;
}

.wallIcon {
    width: 16px;
    height: 16px;

    &.pin {
        margin: 0 8px 0 -3px;
    }

    &.delete {
        margin-right: 10px;
    }
}

.lang-rtl {
    .wallIcon {
        &.pin {
            margin: 0 -3px 0 8px;
        }

        &.delete {
            margin-left: 10px;
            margin-right: 0;
        }
    }
}

.isPinned {
    border: 1px solid var(--theme-primary-color) !important;
}

.menuWrapper {
    width: 80px;

    .menuItem {
        padding: 7px 10px;
        font-family: inherit;
        font-weight: bold;
        font-size: 10px;
        text-transform: uppercase;

        &:hover {
            background: var(--theme-hover-color);
        }

        &:active {
            background: var(--theme-primary-color);
            color: var(--theme-white-color);

            svg {
                path {
                    fill: var(--theme-white-color);
                }
            }
        }
    }
}

.absolute-spinner {
    position: absolute;
    bottom: 50px;
    right: 0;
    left: 0;
    z-index: 99999;
}
