.tooltip-container {
    display: none;
    position: absolute;
    right: 15px;
    bottom: 100%;
    background-color: var(--theme-background-default-color);
    padding: 8px 16px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    border: 1px solid #cacbd0;

    .tooltip {
        position: relative;
    }

    .triangle {
        width: 10px;
        height: 7px;
        position: absolute;
        bottom: -15px;
        right: -8px;
    }
}

.new-comment-container button.video-conference-button:hover + .tooltip-container {
    display: block;
}

.lang-rtl {
    .new-comment-container .tooltip-container {
        left: 15px;
        right: initial;
        .triangle {
            left: -8px;
            right: initial;
        }
    }

    .meeting-button-wrapper .tooltip-container {
        left: 12px;
        right: initial;
    }
}
