.videoWrapper {
    .placeholder-container {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }

    .spinner {
        z-index: 0;
    }

    p {
        font-size: 24px;
    }
}

.video-container {
    &.isFullScreen {
        .video {
            height: 100vh !important;
        }
    }
}

@media screen and (max-width: 1024px) {
    .videoWrapper {
        .placeholder-сontainer {
            height: calc(100vw * 9 / 16);
        }
    }
}
