.share-container {
    width: 310px;
    height: 130px;
    padding: 15px;
    position: relative;

    &__close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 7px;
        cursor: pointer;
    }

    &__title {
        font-weight: 600;
        font-size: 17px;
        color: var(--theme-text-primary-color);
        margin: 10px 0;
    }

    &__socials-buttons-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        &__social-button {
            margin: 10px 15px 0 0;
            cursor: pointer;

            &__social-button-text {
                font-size: 10px;
                color: var(--theme-text-primary-color);
                font-weight: 500;
            }

            &__social-button-icon {
                width: 32px;
                height: 32px;
            }
        }
    }
}
