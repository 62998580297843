.UserPreviewPlateInteraction {
    display: inline-block !important;
    box-sizing: border-box;
    margin: 0 !important;
    padding: 8px;
    border: 1px solid #cacbd0;
    border-radius: 6px;
    background-color: transparent !important;
    font: inherit !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 1.1 !important;
    color: inherit !important;
    text-align: left;
    text-transform: uppercase !important;
    outline: none !important;
    cursor: pointer !important;
    appearance: none !important;
    transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out, color 0.3s ease-in-out;

    &:hover {
        border: 1px solid var(--theme-primary-color);
        background-color: var(--theme-primary-color) !important;
        color: var(--theme-white-color) !important;
    }

    &:disabled {
        pointer-events: none;
        color: #898989 !important;
        background-color: transparent !important;
    }

    &_variant {
        &-default {
        }

        &-primary {
            border: 1px solid var(--theme-primary-color);
        }

        &-accent {
            border: 1px solid var(--theme-primary-color);
        }
    }
}
