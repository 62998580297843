.BottomMenuBottomSheet {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 56px; // Bottom menu height
    background-color: var(--theme-white-color);
}

.BottomMenuBottomSheet__title {
    padding: 16px;
    background-color: var(--theme-primary-color);
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--theme-white-color);
}

.BottomMenuBottomSheet__content {
}
