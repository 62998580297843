.my-account-page {
    height: 100vh;
    box-sizing: border-box;
    padding-top: 69px;
}

.my-account-page h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: var(--theme-text-secondary-color);
    margin: 0;
    padding-right: 30px;
}

.my-account-page ul {
    list-style: none;
    padding-left: 0;
}
.my-account-page ul li p span {
    text-transform: capitalize;
}
.my-account-page .edit-user-personal-info-container .image-container {
    position: relative;
    margin-right: 30px;

    &.isRtl {
        margin: 0 0 0 30px;
    }
}
.my-account-page .edit-user-personal-info-container .image-container .select-image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
    z-index: 2;
}
.my-account-page .edit-user-personal-info-container .image-container .image-error {
    position: absolute;
    bottom: initial;
    top: -40px;
    width: 300px;
    left: 0;
    color: #eb5757;
    font-size: 12px;
    font-weight: bold;
}
.my-account-page .edit-user-personal-info-container .image-container button {
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
    background-color: transparent;
    border: 0;
    padding: 0;
    outline: 0;
    cursor: pointer;
}

.my-account-page .gdpr-links {
    margin-top: 50px;
}
.my-account-page .gdpr-links p {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    color: var(--theme-text-primary-color);
}
.my-account-page .gdpr-links ul {
    margin: 0;
}
.my-account-page .gdpr-links a {
    color: var(--theme-secondary-color);
    font-size: 14px;
    line-height: 25px;
}
.my-account-page .select-language-container {
    width: 100%;
    margin-top: 50px;
}
.my-account-page .select-language-container span {
    padding-right: 20px;
}

.profile-image-container {
    flex-basis: 25%;
}
.profile-image-container > img {
    margin-right: 30px;
}

/* Mobile -start */
@media screen and (max-width: 1024px) {
    .my-account-page {
        padding-top: 56px;
    }
}

// Rtl Language
.my-account-page {
    &.isRtl {
        h3 {
            padding: 0 0 0 30px;
        }
        .select-language-container span {
            padding: 0 0 0 20px;
        }
        .gdpr-links ul {
            padding: 0;
        }
    }
}

.my-account-mobile .page-title {
    height: 48px;
    line-height: 48px;
    text-align: left;
    padding-left: 16px;
}
.my-account-mobile .page-title h3 {
    font-weight: 600;
    font-size: 16px;
}
.my-account-mobile .myaccount-container {
    padding: 0;
}
.my-account-mobile .profile-image-container {
    flex-basis: auto;
}
.my-account-mobile .profile-image-container > img {
    margin-right: 16px;
}
.my-account-mobile .row > div:last-of-type {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.my-account-mobile.dropdown-component .row a {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    padding: 0;
    width: 50%;
    display: flex;
    justify-content: flex-end;
}
.my-account-mobile.dropdown-component .row span {
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.my-account-mobile.dropdown-component .myaccount-container > .row {
    padding: 10px 16px;
    min-height: 60px;
    margin: 0;
}
.my-account-mobile .myaccount-container .row:last-of-type div:last-of-type p {
    font-weight: 600;
    font-size: 16px;
    margin: 0;
}
.my-account-mobile .myaccount-container .row:last-of-type .profile-image-container img {
    margin: 0;
}
