.chat-name p {
    max-width: 110px;
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
}

.private-chat-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    font-weight: 600;
    color: black;
    font-size: 16px;
    margin-left: 8px;
}
